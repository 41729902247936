import React from 'react'

class ClientesModal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      clientes: [],
      clientesResultantes: [],
      itemSelect: 0,
      key: '',
    }
  }

  cerrarModal = () =>{
    this.props.cerrarModalClientes();
  }

  componentDidMount(){
    this.setState({clientes: this.props.clientes, clientesResultantes: this.props.clientes})
  }


  itemSeleccionado(item){
    this.props.agregarCliente(item);
    this.cerrarModal();
  }

  buscarCliente = (event) =>{
    let key = event.target.value || '';
    key.trim();
    if(key == '') return this.setState({clientesResultantes: this.state.clientes});
    console.log(key);
    let telefonoString = '';
    const clientesFiltrados = this.state.clientes?.filter(cliente =>{
      telefonoString = cliente.telefono1?.toString();
      telefonoString = telefonoString.substring(0, key.length);
      console.log({telefonoString});
      return key == telefonoString;


    })
    console.log({clientesFiltrados})
    this.setState({clientesResultantes: clientesFiltrados});


  }


  render(){
    return (
      <>
      <div className='fixed inset-0 z-50 flex flex-wrap justify-center'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            
            <div className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white overflow-auto'>
              <div className='w-full p-2'>
                <div className='w-5/12 mt-2'>
                    <label>Buscar: </label>
                    <input 
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.key}
                      onChange={ event => {this.buscarCliente(event); this.setState({key: event.target.values})} }/>
                </div>
                <div className='flex flex-row gap-1'>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Telefono</h4>
                </div>
                {
                  this.state.clientesResultantes.map((item, index) =>{
                    return (
                      <div key={item.id_usuario + index} className='text-black flex flex-row gap-1 w-full mt-5 cursor-pointer hover:bg-gray-500 rounded-2xl bg-primary_2' onClick={ () => this.itemSeleccionado(item) }>
                        <p className={`w-full text-center mt-1 p-2 bg-transparent`} >
                          {item.telefono1}
                        </p>
                      </div>
                    )
                  })
                }
                
              </div>
            </div>

            <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
        </div>
      </>
      
    );  
  }
  
}

export default ClientesModal;
