import axios from 'axios';
import { APIURL } from './Host';


class AprovisionamientoService{


    ListarAprovisionamiento(){
        return axios.post(`${APIURL}/aprovisionamientos`);
    }

    AprovisionamientoById(idAprovisionamiento){
        return axios.post(`${APIURL}/aprovisionamiento/${idAprovisionamiento}`);
    }

    InsertarAprovisionamiento(aprovisionamiento){
        const data = {
            "usuario_id_proveedor": aprovisionamiento.usuario_id_proveedor,
            "usuario_id_receptor": aprovisionamiento.usuario_id_receptor,
            "saldo": aprovisionamiento.saldo,
            "register_by": aprovisionamiento.register_by,
            "status": aprovisionamiento.status,
        }
        return axios.post(`${APIURL}/aprovisionamiento/insert`, data);
    }

    ActualizarAprovisionamiento(aprovisionamiento){
        const data = {
            "usuario_id_proveedor": aprovisionamiento.usuario_id_proveedor,
            "usuario_id_receptor": aprovisionamiento.usuario_id_receptor,
            "saldo": aprovisionamiento.saldo,
            "register_by": aprovisionamiento.register_by,
            "lu_by": aprovisionamiento.lu_by,
            "status": aprovisionamiento.status,
            "id_aprovisionamiento": aprovisionamiento.id_aprovisionamiento,
        }
        return axios.post(`${APIURL}/aprovisionamiento/update`, data);
    }

    ActualizarStatusAprovisionamiento(aprovisionamiento){
        const data = {
            "status": aprovisionamiento.status,
            "id_aprovisionamiento": aprovisionamiento.id_aprovisionamiento
        }
        return axios.post(`${APIURL}/aprovisionamiento/update/status`, data);
    }

}

export default new AprovisionamientoService();