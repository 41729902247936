import axios from 'axios';
import { APIURL } from './Host';


class VentasService{


    ListarVentas(){
        return axios.post(`${APIURL}/ventas`);
    }

    ListarTiposPago(){
        return axios.post(`${APIURL}/tiposPago`);
    }

    VentaById(idVenta){
        return axios.post(`${APIURL}/venta/${idVenta}`);
    }

    InsertarVenta(venta){
        const data = {
            "folio": venta.folio,
            "cliente_id": venta.cliente_id,
            "sucursal_id": venta.sucursal_id,
            "tipo_pago_id": venta.tipo_pago_id,
            "en_espera": venta.en_espera,
            "total": venta.total,
            "referencia": venta.referencia,
            "register_by": venta.register_by,
            "status": venta.status,
        }
        return axios.post(`${APIURL}/venta/insert`, data);
    }

    ActualizarVenta(venta){
        const data = {
            "folio": venta.folio,
            "cliente_id": venta.cliente_id,
            "sucursal_id": venta.sucursal_id,
            "tipo_pago_id": venta.tipo_pago_id,
            "en_espera": venta.en_espera,
            "total": venta.total,
            "referencia": venta.referencia,
            "register_by": venta.register_by,
            "lu_by": venta.lu_by,
            "status": venta.status,
            "id_venta": venta.id_venta,
        }
        return axios.post(`${APIURL}/venta/update`, data);
    }

    ActualizarStatusVenta(venta){
        const data = {
            "status": venta.status,
            "id_venta": venta.id_venta
        }
        return axios.post(`${APIURL}/venta/update/status`, data);
    }

}

export default new VentasService();