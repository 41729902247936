import React from 'react'
import ClientesService from 'services/ClientesService';
import VentasService from 'services/VentasService';

class ModalVenta extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        itemsSeleccionados: this.props.itemsSeleccionados,
        total: this.props.total,
        usuario: this.props.usuario,
        tiposPago: [],
        tipoPagoId: '',
        tipoPago: {},
        clientes: [],
        clienteId: 0,
        referenciaVenta: '',
        cambio: '',
    }
  }

  componentDidMount(){
    console.log(this.state.usuario)
    this.getTipospago();
    this.getClientes();
  }
  cerrarModal = () =>{
    this.props.cerrarModal();
  }

  getTipospago(){
    VentasService.ListarTiposPago().then(response =>{
      let tipoPago = response?.data.find(tipoPago =>{
        return tipoPago.descripcion == "EFECTIVO";
      })

      this.setState({tiposPago: response.data, tipoPago, tipoPagoId: tipoPago.id_tipo_pago});

    }).catch(err =>{
      console.log("Error en getTipospago ", err);
    })
  }

  getClientes(){
    ClientesService.ListarClientes().then(response =>{
      this.setState({clientes: response.data});
      console.log({clientes: response.data})
    }).catch(err =>{
      console.log("Error en getClientes, ", err);
    })
  }

  cobrarVenta = (event) =>{
    event.preventDefault();
    this.props.cobrarVenta(this.state.clienteId, this.state.tipoPagoId, this.state.referenciaVenta);
  }

  onChangeTipoPago = (idTipoPago) =>{
    let tipoPago = this.state.tiposPago.find(tipoPago =>{
      return tipoPago.id_tipo_pago == idTipoPago;
    })

    this.setState({tipoPagoId: idTipoPago, tipoPago, referenciaVenta: ''});
  }

  onChangeEfectivo = (efectivo) =>{

    let cambio = parseFloat(efectivo) - parseFloat(this.state.total);
    this.setState({referenciaVenta: efectivo, cambio})

  }




  render(){
    return (
      <>
      <div className='fixed inset-0 z-50 flex flex-wrap justify-center items-center'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            
            <form onSubmit={event => this.cobrarVenta(event)} className='w-10/12 z-100 relative flex flex-col justify-center items-center bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white'>
              <div className='w-full flex justify-evenly'>
                <div>
                    <label className='mr-1'>Usuario: </label>
                    {/* TODO CAMBIAR EL VALUE */}
                    <input type="text" disabled value={this.state.usuario?.nombre}  className={`rounded-lg p-2`}/>
                </div>
                <div>
                    <label className='mr-1'>Fecha: </label>
                    {/* TODO CAMBIAR EL VALUE */}
                    <input type="date" value={"2008-10-10"}  className={`rounded-lg p-2 text-black`}/>
                </div>
                <div>
                    <label className='mr-1'>Folio: </label>
                    {/* TODO CAMBIAR EL VALUE */}
                    <input value={this.props.folio}  className={`rounded-lg p-2 text-black`} disabled/>
                </div>
              </div>

              <div className='w-full flex justify-evenly mt-3'>
                <div>
                  <label className='mr-1'>Tipo de pago: </label>
                  <select className='rounded-lg p-2 text-black' value={this.state.tipoPagoId} onChange={(event) => this.onChangeTipoPago(event.target.value) } require>
                      {
                        this.state.tiposPago.map(tipoPago =>{
                          return(<option className='text-black' key={tipoPago.id_tipo_pago} value={ tipoPago.id_tipo_pago}>{tipoPago.descripcion}</option>)
                        })
                      }
                  </select>
                </div>
  	            <div>
                  <label className='mr-1'>Cliente: </label>
                  <select className='rounded-lg p-2 text-black' value={this.state.clienteId} onChange={(event) => this.setState({clienteId: event.target.value}) } require >
                      <option className='text-black' value={0}>Publico General</option>
                      {
                        this.state.clientes.map(cliente =>{
                          return(<option className='text-black' key={cliente.id_cliente} value={cliente.id_cliente}>{`${cliente.usuario?.nombre} ${cliente.usuario?.app_paterno}`}</option>)
                        })
                      }
                  </select>
                </div>
              </div>

              <div className='w-full flex justify-evenly mt-5'>
                
                <div className='flex flex-col items-center w-full'>
                    <label className='mr-1 text-4xl'>Total: </label>
                    {/* TODO CAMBIAR EL VALUE */}
                    <input type="text" disabled value={`$${this.state.total}`}  className={`rounded-lg p-2 bg-black text-center w-6/12 text-7xl`}/>
                </div>
                
              </div>

              {
                this.state.tipoPago?.descripcion == "EFECTIVO" && (
                  <div className='w-full flex justify-evenly mt-7'>
                    <div className='w-5/12'>
                        <label className='mr-1'>Recibido: </label>
                        {/* TODO CAMBIAR EL VALUE */}
                        <input type="number" value={this.state.referenciaVenta} onChange={(event) => this.onChangeEfectivo(event.target.value)}  className={`rounded-lg p-2 text-center w-8/12 text-7xl text-green-600`} required/>
                    </div>
                    <div className='w-5/12'>
                        <label className='mr-1'>Cambio: </label>
                        {/* TODO CAMBIAR EL VALUE */}
                        <input type="number" disabled value={this.state.cambio}  className={`rounded-lg p-2 text-center w-8/12 text-7xl ${this.state.total <= this.state.referenciaVenta? "text-blue-700": "text-red-700"}`}/>
                    </div>
                  </div> 
                )
              }

              {
                this.state.tipoPago?.descripcion == "TARJETA DE DEBITO" && (
                  <div className='w-full flex justify-evenly mt-7'>
                    <div className='w-full flex justify-center'>
                        <label className='mr-1'>Referencia: </label>
                        {/* TODO CAMBIAR EL VALUE */}
                        <input type="text" value={this.state.referenciaVenta} onChange={(event) => this.setState({referenciaVenta: event.target.value})}  className={`rounded-lg p-2 text-black w-5/12`}/>
                    </div>
                  </div> 
                )
              }
              <div className={'w-full flex gap-2 justify-center items-center mt-5'}>
                <button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Guardar</button>
                <button className='focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' onClick={() => this.cerrarModal()}>Cancelar</button>
              </div>
              
            </form>



            <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
        </div>
      </>
      
    );  
  }
  
}

export default ModalVenta;

