/** @format */

import { Component } from "react"
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom"
import AdministrativoService from "services/AdministrativoService"
import VinculacionService from "services/VinculacionService"
import clientes from "images/prospecto_white.png"
import admon from "images/gestion_de_usuarios/gerente.svg"
import prospecto from "images/gestion_de_usuarios/curriculum.svg"
import lista from "images/lista_white.png"
import puntoventa from "images/timep.png"
import sucursales from "images/chain-store.png"
import ReportesPrincipal from "./ReportesPrincipal"
import reporte_clientes from "images/clientes_white.png"
import reporte_venta_detalle from "images/analitica_white.png"

class ReportesPrincipalDos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			usuarios: [],
			rol: "",
			totales: [
				{ totalAlumno: '' }
			],
			totalAlumnos: "",
			totalDocentes: "",
			total_Administrativos: "",
			totalAgenteVentas: "",
			totalProspectos: "",
			totalTutores: "",
		}

		this.goBack = this.goBack.bind(this);
	}

	goBack() {
        this.props.history.goBack();
    }

	componentDidMount() {
		AdministrativoService.Total_Administrativos().then(res => {
			console.log();
			this.setState({ total_Administrativos: res.data })
		})
		//VinculacionService.ConsultabyMSISDN(9517160038);

		
	}

	render() {

		return (
			<>
				<div className='bg-primary_2 bg-opacity-0 py-10'>
					<div className="flex justify-end mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"></a>
									</div>
								</li>
								
							</ol>
						</nav>
					</div>
					<h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest'>
							REPORTES PRINCIPAL
					</h1>
					
						
					<div className='flex flex-row-reverse mb-4 mt-4 mr-20'>
							
					</div>
					

					<div class=' px-2 md:px-10 pt-4 md:pt-3  overflow-y-auto '>
						<div class='grid grid-cols-3 gap-10'>
									
								{
									(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'||sessionStorage.getItem("roles")==='["DISTRIBUIDOR"]'
									||sessionStorage.getItem("roles")==='["SOCIO_MARCA"]'||sessionStorage.getItem("roles")==='["SOCIO_COMERCIAL"]')&&
									<div className=' shadow-2xl rounded-md bg-secondary_2 bg-opacity-50 relative py-5'>
								<div className="absolute w-full top-3 h-10 flex justify-end items-center">
									<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
								</div>
								<div class='flex justify-center'>
									<img
										src={reporte_venta_detalle}
										class='w-8 h-8 lg:w-16 lg:h-16'
										alt=''></img>
								</div>
								<div class='flex justify-center py-2'>
									<h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
										
									</h1>
								</div>

								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow text-white tracking-widest'>
										REPORTE DE VENTAS
									</h1>
								</div>
								<div className='flex justify-center pt-3'>
									<NavLink
										to='/app/ReportesSocios'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center'>
											Ir
										</p>
									</NavLink>
								</div>
							</div>
							}
							{
									(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'
									||sessionStorage.getItem("roles")=== '["CAJERO"]'||sessionStorage.getItem("roles")==='["SOCIO_MARCA"]'|| sessionStorage.getItem("roles")==='["SOCIO_COMERCIAL"]')&&
									<div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
								<div className="absolute w-full top-3 h-10 flex justify-end items-center">
									<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
								</div>
								<div class='flex justify-center'>
									<img
										src={reporte_clientes}
										class='w-8 h-8 lg:w-16 lg:h-16'
										alt=''></img>
								</div>
								<div class='flex justify-center py-2'>
									<h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
										
									</h1>
								</div>

								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow text-white tracking-widest'>
										REPORTE DE RECARGAS
									</h1>
								</div>
								<div className='flex justify-center pt-3'>
									<NavLink
										to='/app/GestionDeClientes'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center'>
											Ir
										</p>
									</NavLink>
								</div>
							</div>
							}
							{
									(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["DISTRIBUIDOR"]'
									||sessionStorage.getItem("roles")=== '["CAJERO"]'||sessionStorage.getItem("roles")==='["SOCIO_MARCA"]')&&
									<div className=' shadow-2xl rounded-md bg-secondary_2 relative py-5'>
								<div className="absolute w-full top-3 h-10 flex justify-end items-center">
									<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
								</div>
								<div class='flex justify-center'>
									<img
										src={clientes}
										class='w-8 h-8 lg:w-16 lg:h-16'
										alt=''></img>
								</div>
								<div class='flex justify-center py-2'>
									<h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
										
									</h1>
								</div>

								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow text-white tracking-widest'>
										REPORTE DE ACTIVACIONES
									</h1>
								</div>
								<div className='flex justify-center pt-3'>
									<NavLink
										to='/app/ReporteActivaciones'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center'>
											Ir
										</p>
									</NavLink>
								</div>
							</div>
							}
							{
								(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["ADMINISTRADOR"]')&&
								<div className='shadow-2xl rounded-md bg-secondary_2 relative py-5'>
									<div className="absolute w-full top-3 h-10 flex justify-end items-center">
										<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
									</div>
								<div class='flex justify-center'>
									<img
										src={lista}
										class='w-8 h-8 lg:w-16 lg:h-16'
										alt=''></img>
								</div>
								 <div class='flex justify-center py-2'>
									{/* <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
										{this.state.totalProspectos}
									</h1> */}
								</div> 
								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow text-white tracking-widest uppercase'>
										REPORTE DE LINEAS
									</h1>
								</div>
								{/* <div class='flex justify-center'>
									<h1 class='text-green-500 text-xl sm:text-xl'>ACTIVOS</h1>
								</div> */}
								<div className='flex justify-center pt-3'>
									<NavLink
										to='/app/InventarioPrincipal'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
											Ir a Inventarios
										</p>
									</NavLink>
								</div>
							</div>
							}
							
							{
								(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["ADMINISTRADOR"]'||
								 sessionStorage.getItem("roles")==='["DIRECTOR"]'||sessionStorage.getItem("roles")==='["CONTROL ESCOLAR"]'||
								 sessionStorage.getItem("roles")==='["ADMINISTRATIVO"]')&&
								<div className='shadow-2xl rounded-md bg-secondary_2 relative py-5'>
										<div className="absolute w-full top-3 h-10 flex justify-end items-center">
											<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
										</div>
									<div class='flex justify-center'>
										<img
											src={sucursales}
											class='w-8 h-8 lg:w-16 lg:h-16'
											alt=''></img>
									</div>
				
									<div class='flex justify-center py-2'>
										{/* <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
											{this.state.totalTutores}
										</h1> */}
									</div>
								
								
								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow text-white tracking-widest uppercase'>
											REPORTE DE FONDOS
									</h1>
								</div>
								<div className='flex justify-center pt-3'>
									<NavLink
										to='/app/SucursalesPrincipal'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
											Ir
										</p>
									</NavLink>
								</div>
							</div>
							}	
							
							{
								(sessionStorage.getItem("roles")==='["SUPER_ADMIN"]'|| sessionStorage.getItem("roles")==='["ADMINISTRADOR"]'||
								 sessionStorage.getItem("roles")==='["DIRECTOR"]'||sessionStorage.getItem("roles")==='["CONTROL ESCOLAR"]'||
								 sessionStorage.getItem("roles")==='["ADMINISTRATIVO"]')&&
								<div className='shadow-2xl rounded-md bg-secondary_2 relative py-5'>
										<div className="absolute w-full top-3 h-10 flex justify-end items-center">
											<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
										</div>
									<div class='flex justify-center'>
										<img
											src={sucursales}
											class='w-8 h-8 lg:w-16 lg:h-16'
											alt=''></img>
									</div>
				
									<div class='flex justify-center py-2'>
										{/* <h1 class='text-2xl sm:text-4xl text-secondary font-black counter'>
											{this.state.totalTutores}
										</h1> */}
									</div>
								
								
								<div class='flex justify-center'>
									<h1 class='text-gray-500 text-xl sm:text-xl font-barlow text-white tracking-widest uppercase'>
											ESTADOS DE CUENTA
									</h1>
								</div>
								<div className='flex justify-center pt-3'>
									<NavLink
										to='/app/SucursalesPrincipal'
										className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 '>
										<p className='text-sm font-medium leading-none text-white text-center font-barlow tracking-widest'>
											Ir
										</p>
									</NavLink>
								</div>
							</div>
							}
							

						</div>
					</div>
				</div>

			</>
		)
	}
}

export default withRouter(ReportesPrincipalDos);