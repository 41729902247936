import React, { Component, Fragment } from 'react'
import UsuariosService from 'services/UsuariosService';
import { withRouter } from "react-router";
import image from '../images/playcell.png';
import swal from 'sweetalert2';
import ImgCargando from "../images/icono_cargando.svg"

class Landing extends Component {
    constructor(props){
        super(props);
        this.state={
            id_usuario:sessionStorage.getItem("id"),
            mostrarModalMsj: false,
            correo: "",
            bloquearBotones : false,
            errors : {},
            nombre_us:sessionStorage.getItem("nombre")
        }
        
    }

    componentDidMount() {
        let list = document.querySelectorAll('.topNavList');
        for (let i = 0; i < list.length; i++) {
            console.log("FOR DEL LANDING: ", list[i])
            list[i].onclick = function () {
                let j = 0;
                while (j < list.length) {
                    list[j++].className = 'topNavList';
                }
                list[i].className = 'topNavList active'
            }
        }

        UsuariosService.obtenerCorreoUsuarioSession(this.state.id_usuario).then((res)=>{
            if (res.data["correo"]!=undefined){
                if (!res.data["correo"]){
                    this.setState({mostrarModalMsj:true},()=>{ document.getElementById("inputCorreo").focus(); });
                } else {
                    if (!res.data["info"]){
                        swal.fire({
                            text: "Falta agregar su información personal.",
                            icon: "info",
                            showConfirmButton: true,
                            //allowOutsideClick: false,
                            //allowEscapeKey: false
                        }).then(() => {
                            //this.props.history.push("/app/PerfilUsuario");
                        });
                    }
                }
            }
		});
    }

    asignarValor =(event)=>{ this.setState({ [event.target.name] : event.target.value }); }

    blurCorreo =()=>{
		let expReg = /^(\w+[/./-]?){1,}@[a-z0-9.-]+[/.]\w{2,4}$/;
		if (!this.state.correo.trim()){
			this.state.errors.correo = "Correo electrónico requerido";
		} else if (this.state.correo.trim() != "" && (!expReg.test(this.state.correo.trim()))) {
			this.state.errors.correo = "Correo electrónico no valido";
		} else {
			this.state.errors.correo = "";
			delete this.state.errors.correo;
		}
	}

    ActualizarCorreo = async(e)=>{
        e.preventDefault();
        this.setState({bloquearBotones : true});
        this.blurCorreo();
        if (Object.keys(this.state.errors).length === 0){
            UsuariosService.VerificarCorreoRegistrado(this.state.correo).then((response) => {
                if (response.data){
                    this.state.errors.correo = "Correo electrónico ya esta registrado";
                    this.setState({bloquearBotones : false});
                } else {
                    this.state.errors.correo = "";
                    delete this.state.errors.correo;
                    UsuariosService.agregarCorreoElectronicoUsuario({id: this.state.id_usuario,correo:this.state.correo}).then((res)=>{
                        if(res.data){
                            swal.fire({
                                icon: "success",
                                title: 'Success',
                                text: 'Correo electrónico actualizado correctamente',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            });
                            this.setState({mostrarModalMsj:false});
                        } else {
                            swal.fire({
                                icon: "error",
                                title: 'Error',
                                text: 'Error al actualizar el correo electrónico',
                                allowOutsideClick: false,
                                allowEscapeKey: false
                            });
                        }
                        this.setState({bloquearBotones : false});
                    }).catch(error => {
                        swal.fire({
                            text: "No se pudo agregar el correo electrónico, inténtelo mas tarde.",
                            icon: "error",
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        })
                        this.setState({bloquearBotones : false});
                    });
                }
            });
        } else {
            swal.fire({
                title:"Error",
                text: "Falta correo electrónico valido",
                icon: "error",
                allowOutsideClick: false,
                allowEscapeKey: false
            })
            this.setState({bloquearBotones : false});
        }
    }

    render() {
        return (
            <div class="h-full flex justify-center items-center bg-green-500 text-color5 relative bg-opacity-10">
                <div class='block sm:flex animate__animated animate__fadeIn'>
                    <div className=" flex justify-center items-center text-center">
                        <section class="w-full">
                            <h1 class="text- 3xl lg: text-4xl sm:text-6xl font-bold text-white font-barlow tracking-widest"> Bienvenido {this.nombre_us}</h1>
                            {/*<p class =" font-bold mb-1 "> Control financiero </p>*/}
                            {/*<p> Texto de prueba...</p>*/}
                        </section>
                    </div>
                    <div class="lg:ml-12 bg-rose-300 item-start px-10">
                        <img class=" my-14 object-contain h-60 sm:h-70 w-full animate__animated animate__fadeIn" src={image} />
                    </div>
                </div>
                {this.state.mostrarModalMsj && (
                    <Fragment>
                        <form onSubmit={this.ActualizarCorreo}>
                            <div className="overflow-y-hidden text-black">
                                <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
                                    <div className='relative w-auto my-6 mx-auto max-w-3xl z-20'>
                                        <div className='rounded-3xl relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
                                            <div className='rounded-t-3xl'>
                                                <h3 className='text-primary text-3xl text-center my-5 font-bold uppercase'>Agregar correo electrónico</h3>
                                            </div>
                                            <div className='relative flex-auto'>
                                                <div className='grid grid-cols-1 gap-5 px-6 py-5  '>
                                                    <input
                                                        id="inputCorreo"
                                                        className='w-full mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out'
                                                        name='correo'
                                                        type='email'
                                                        maxLength={100}
                                                        placeholder="Correo electrónico"
                                                        value={this.state.correo}
                                                        onChange={(e) => {this.asignarValor(e); this.blurCorreo(); }} />
                                                    {this.state.errors.correo && this.state.errors.correo !="" ? <p style={{ fontWeigth : "bold", color: "#dc3545" }}>{this.state.errors.correo}</p> : <p></p> }
                                                </div>
                                            </div>
                                            {!this.state.bloquearBotones && (
                                                <div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
                                                    <button className=' bg-emerald-500 text-black active:bg-gray-600 font-bold uppercase text-sm px-6  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150' type='submit'>Actualizar</button>
                                                </div>
                                            )}
                                            {this.state.bloquearBotones && (
                                                <div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5 font-bold mb-10 text-2xl'>
                                                    <img className="w-10 h-10" src={ImgCargando} alt=">"/> Guardando informacion ...
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='opacity-25 fixed inset-0 bg-black' style={{"right":"12px"}}></div>
                                </div>
                            </div>
                        </form>
                    </Fragment>
                )}

            </div>
        )
    }

    
}


export default withRouter(Landing);
