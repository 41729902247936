/** @format */

import PropTypes from "prop-types"
import React, { memo, useEffect, useRef } from "react"
import { NavLink, useLocation } from "react-router-dom"
import image from "../images/playcell.png"


function TopNav(props) {
	const location = useLocation()
	const { pathname } = location
	const page = pathname.split("/")[1]

	const trigger = useRef(null)
	const TopNav = useRef(null)

	// close on click outside
	useEffect(() => {
		const clickHandler = ({ target }) => {
			if (!TopNav.current || !trigger.current) return
			if (
				!props.sidebarOpen ||
				TopNav.current.contains(target) ||
				trigger.current.contains(target)
			)
				return
			props.setSidebarOpen(false)
		}
		document.addEventListener("click", clickHandler)
		return () => document.removeEventListener("click", clickHandler)
	})

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }) => {
			if (!props.sidebarOpen || keyCode !== 27) return
			props.setSidebarOpen(false)
		}
		document.addEventListener("keydown", keyHandler)
		return () => document.removeEventListener("keydown", keyHandler)
	})


	return (
		<div className='lg:w-48 pt-32 pb-16 bg-lime-500 rounded-tr-lg rounded-tl-lg relative z-50 flex items-center bg-opacity-90 h-screen'>
			{/* Sidebar backdrop (mobile only) */}
			<div
				className={`fixed inset-0 bg-lime-500 bg-opacity-80 lg:hidden transition-opacity duration-200 ${props.sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
					}`}
				aria-hidden='true'></div>

			{/* Sidebar */}
			<div
				id='sidebar'
				ref={TopNav}
				className={`bg-secondary_2 bg-opacity-90 border-2 border-primary_2 overflow-x-hidden h-full fixed lg:w-24 left-5 rounded-tr-lg rounded-tl-lg pt-10 transition-all duration-300 ease-in-out transform lg:hover:w-56 group ${props.sidebarOpen ? "translate-x-0 w-60" : "-translate-x-64 lg:translate-x-0"}`}>
				{/* Sidebar header */}
				<div className='flex justify-between mb-10 pr-3 sm:px-2'>
					<NavLink exact to='/casa' className='animate__animated animate__zoomIn w-full pl-3 lg:pl-0 flex'>
						<img
							class=' transition duration-300 ease-in-out transform hover:-translate-y-1 w-14 ml-3'
							src={image}
						/>
						<span className={`lg:w-0 flex items-center text-white font-bold font-barlow tracking-wider overflow-hidden truncate transition-all duration-300 ease-in-out transform lg:opacity-0 group-hover:opacity-100 group-hover:w-full pl-3 ${props.sidebarOpen ? 'w-full' : ''} `}>Play Shop</span>
					</NavLink>
				</div>
					<div className="w-full h-px px-3 mb-10">
						<div className="w-full h-px bg-primary_2"></div>
					</div>


				<div>
					{/* <h3 className='text-xs uppercase text-gray-500 font-semibold pl-3'>
						Páginas
					</h3> */}
					<div className="mt-3 relative">
						<ul className=''>
							{props.routes.map(route => {
								// console.log("lop: ", route, " page: ", page);
								route.dependents &&
									route.dependents.includes("/" + page) &&
									(page = route.path.split("/")[1])
								if (route.visible) {
									return (
										//console.log("show vars: ",route," page: ",page, "path: ",route.path),
										<div className="mb-7 relative">
											<NavLink
												key={route.path}
												to={`${props.prefix}${route.path}`}
												className={`w3-bar-item block text-white hover:text-white ${page === "" && "hover:text-color7"
													} side-links animate__animated animate__fadeInRight`}>
												{/* Dashboard */}
												<div className="flex m-1">
													<li
														className={`topNavList w-full group`}>
														<div className='pl-8 flex'>
															{/* <img src={route.src} className="w-10 h-10 mr-2 srcColor" /> */}
															<img src={route.src_white} className="srcWhite w-7 h-7 mr-2 transition-all duration-300 ease-in-out transform" />
															<span onClick={() => props.setSidebarOpen(!props.sidebarOpen)} className={`text-sm font-medium flex items-center font-barlow tracking-wider lg:w-0 overflow-hidden truncate lg:opacity-0 hover:translate-x-1 transition-all duration-300 ease-in-out transform group-hover:opacity-100 group-hover:w-full ${props.sidebarOpen ? 'w-full' : ''} `}>
																{route.title}
															</span>
														</div>
														<p align="center">{route.name}</p>
													</li>
												</div>
											</NavLink>
										<div >
											{
												route.childrens?.lenght != 0 &&(
													route.childrens?.map(routeChildren => {
														// console.log("lop: ", route, " page: ", page);
														routeChildren.dependents &&
															routeChildren.dependents.includes("/" + page) &&
															(page = routeChildren.path.split("/")[1])
														if (routeChildren.visible) {
															return (
															<NavLink
																key={routeChildren.path}
																to={`${props.prefix}${routeChildren.path}`}
																className={`w3-bar-item block text-white hover:text-white ${page === "" && "hover:text-color7"
																	} side-links animate__animated animate__fadeInRight`}>
																<div className="flex ml-5">
																	<li
																		className={`topNavList w-full group`}>
																		<div className='pl-8 flex'>
																			{/* <img src={routeChildren.src} className="w-10 h-10 mr-2 srcColor" /> */}
																			<img src={routeChildren.src_white} className="srcWhite w-7 h-7 mr-2 transition-all duration-300 ease-in-out transform" />
																			<span onClick={() => props.setSidebarOpen(!props.sidebarOpen)} className={`text-sm font-medium flex items-center font-barlow tracking-wider lg:w-0 overflow-hidden truncate lg:opacity-0 hover:translate-x-1 transition-all duration-300 ease-in-out transform group-hover:opacity-100 group-hover:w-full ${props.sidebarOpen ? 'w-full' : ''} `}>
																				{routeChildren.title}
																			</span>
																		</div>
																		<p align="center">{routeChildren.name}</p>
																	</li>
																</div>
															</NavLink>
															)
														}
													}
													)
												)
											}
										</div>
										</div>
									)
								}
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}



TopNav.propTypes = {
	routes: PropTypes.arrayOf(
		PropTypes.shape({
			path: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		})
	).isRequired,
	prefix: PropTypes.string,
	className: PropTypes.string,
}

TopNav.defaultProps = {
	prefix: "",
	className: "",
}

export default memo(TopNav)
