import React from 'react'
import sweetalert from "sweetalert2"
import InventarioService from '../services/InventarioService';
import ResguardosService from '../services/ResguardosService';
import SucursalesService from '../services/SucursalesService';
import InventarioDetalles from './InventarioDetalles';
import InventarioModal from './InventarioModal';
import ResguardoDetalles from './ResguardosDetalles';
import UsuariosModal from './usuariosModal';

class ResguardosPrincipal extends React.Component {

  constructor(){
    super();
    this.state = {
      resguardosInicial: [],
      resguardosModificado: [],
      sucursales: [],
      usuarios: [],
      modalVisible: false,
      modalInventario: false,
      modalUsuarios: false,
      usuario_aSelect: true,
      resguardoSelect: 0,
      indexResguardo: 0,
      estatus: [
        {id_estatus: "2", nombre: "Recibido"}, 
        {id_estatus: "3", nombre: "Rechazado"}
      ],
      firmas: [
        {id_firma: "0", nombre: "Firmado"}, 
        {id_firma: "1", nombre: "No firmado"}
      ]
    }
  }

  cerrarModal = () =>{
    this.setState({modalVisible: false})
  }
  cerrarModalInventario = () =>{
    this.setState({modalInventario: false})
  }
  cerrarModalUsuarios = () =>{
    this.setState({modalUsuarios: false})
  }

  componentDidMount(){
    this.getResguardos();
    this.getSucursales();
  }

  getResguardos = () =>{
    ResguardosService.ListarResguardos().then(response =>{
      this.setState({resguardosInicial: response.data, resguardosModificado: response.data})
      console.log(response.data);
    }).catch(err =>{
      console.log('Error en listarResguardos ', err)
    })
  }

  getSucursales = () =>{
    SucursalesService.ListarSucursales().then(response =>{
        this.setState({sucursales: response.data});
        console.log(response.data)
    }).catch(err =>{
        console.log("Error en getSucursales ", err);
    })
  }

  getSucursalNombre = (idSucursal) =>{
    let sucursal = this.state.sucursales.find(sucursal =>{
      return sucursal.id_sucursal === idSucursal;
    })

    if(sucursal) return sucursal.nombre;
    else return '';
  }

  cambiarSucursal = (event, index, origen) =>{
    let resguardosModificado = this.state.resguardosModificado.map((item, i) =>{
      if(index === i){
        if(origen) return {...item, suc_id_origen: event.target.value}
        else return {...item, suc_id_destino: event.target.value}
      }else{
        return item;
      }
    })

    this.setState({resguardosModificado: resguardosModificado});

    if(this.state.resguardosInicial === resguardosModificado) return;
    sweetalert
      .fire({
        text:`Seguro quiere cambiar la sucursal`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const usuario = sessionStorage.getItem("id");
          const data = {...resguardosModificado[index], lu_by: usuario};
          ResguardosService.ActualizarResguardo(data).then(response =>{
            console.log("Actualizado con exito, ", response);
            this.getResguardos();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al actualizar los datos",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log("Error en aplicarCambios, ", err);
          });
        }
      });  
  }

  cambiarSelect = (event, index, input, message) =>{
    let resguardosModificado = this.state.resguardosModificado.map((item, i) =>{
      if(index === i){
        return {...item, [input]: event.target.value}
      }else{
        return item;
      }
    })

    this.setState({resguardosModificado: resguardosModificado});

    if(this.state.resguardosInicial === resguardosModificado) return;
    sweetalert
      .fire({
        text:`Seguro quiere cambiar ${message}`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const usuario = sessionStorage.getItem("id");
          const data = {...resguardosModificado[index], lu_by: usuario};
          ResguardosService.ActualizarResguardo(data).then(response =>{
            console.log("Actualizado con exito, ", response);
            this.getResguardos();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al actualizar los datos",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log("Error en aplicarCambios, ", err);
          });
        }
      }); 
  }

  onChangeInput = (event, index, input) =>{
    let resguardosModificado = this.state.resguardosModificado.map((item, i) =>{
      if(index === i){
        return {...item, [ input ]: event.target.value}
      }else{
        return item;
      }
    })

    this.setState({resguardosModificado: resguardosModificado});
  }

  resetInventario = () =>{
    this.setState({resguardosModificado: this.state.resguardosInicial});
  }

  aplicarCambios = (event, index) =>{
    if(!(event.code === "Enter")) return;
    if(this.state.resguardosInicial === this.state.resguardosModificado) return;
    
    const usuario = sessionStorage.getItem("id");
    const data = {...this.state.resguardosModificado[index], lu_by: usuario};
    ResguardosService.ActualizarResguardo(data).then(response =>{
      console.log("Actualizado con exito, ", response);
      this.getResguardos();
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al actualizar los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log("Error en aplicarCambios, ", err);
    });
  }

  eliminarItem = (id_resguardo, descripcion) =>{
    sweetalert
      .fire({
        text:`Seguro quiere eliminar el resguardo: ${descripcion}`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const data = {
            status: 0,
            id_resguardo: id_resguardo
          }
          ResguardosService.ActualizarStatusResguardo(data).then(response =>{
            this.getResguardos();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al eliminar el resguardo",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log(err);
          })
        }
      }); 
  }

  abrirDetallesResguardo(idResguardo){
    this.setState({modalVisible: true, resguardoSelect: idResguardo});
  }

  abrirInventarioModal(index) {
    this.setState({modalInventario:true, indexResguardo: index})
  }
  abrirUsuariosModal(index, usuario_aSelect) {
    this.setState({modalUsuarios:true, indexResguardo: index, usuario_aSelect})
  }

  cambiarItemInventario = (inventario) =>{
    let resguardoModificado = this.state.resguardosInicial.map((item, i) =>{
      if(this.state.indexResguardo === i){
        return {...item, inventario_id: inventario.id_inventario, inventario: inventario}
      }else{
        return item;
      }
    })

    this.setState({resguardoModificado: resguardoModificado});

    if(this.state.resguardosInicial == resguardoModificado) return;
    sweetalert
      .fire({
        text:`Seguro quiere cambiar el item de resguardo`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const usuario = sessionStorage.getItem("id");
          const data = {...resguardoModificado[this.state.indexResguardo], lu_by: usuario};
          ResguardosService.ActualizarResguardo(data).then(response =>{
            console.log("Actualizado con exito, ", response);
            this.getResguardos();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al actualizar los datos",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log("Error en aplicarCambios, ", err);
          });
        }
      }); 
  }

  cambiarUsuario = (usuario) =>{
    let resguardoModificado = this.state.resguardosInicial.map((item, i) =>{
      if(this.state.indexResguardo === i){
        if(this.state.usuario_aSelect){
          return {...item, usuario_a_id: usuario.id_usuario, usuario_a: usuario}
        }else{
          return {...item, usuario_r_id: usuario.id_usuario, usuario_r: usuario}
        }
      }else{
        return item;
      }
    })

    this.setState({resguardoModificado: resguardoModificado});

    if(this.state.resguardosInicial == resguardoModificado) return;
    sweetalert
      .fire({
        text:`Seguro quiere cambiar el ${this.state.usuario_aSelect ? "usuario que asigna": "usuario al que se asigna"}`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const usuario = sessionStorage.getItem("id");
          const data = {...resguardoModificado[this.state.indexResguardo], lu_by: usuario};
          ResguardosService.ActualizarResguardo(data).then(response =>{
            console.log("Actualizado con exito, ", response);
            this.getResguardos();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al actualizar los datos",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log("Error en aplicarCambios, ", err);
          });
        }
      });
  }

  render(){
    return (
      <div className='bg-secondary_2 bg-opacity-30 text-white p-10'>
        <h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
						Resguardos
					</h1>

					<div className="flex justify-start mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Resguardos</span>
									</div>
								</li>
							</ol>
						</nav>
					</div>
        <div className='flex justify-end p-3'>
          <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirDetallesResguardo(0) }><i class='fas fa-plus-circle'></i> Agregar Resguardo</button>

        </div>
        <div className='h-11/12 w-full p-2'>
          <div className='flex flex-row gap-1'>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Item</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Usuario que asigna</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Usuario asignado</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Sucursal origen</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Sucursal destino</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Estatus</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Firmado</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Acciones</h4>
          </div>
          {
            this.state.resguardosModificado.map((item, index) =>{
              return (
                <div key={item.id_resguardo} className='flex justify-center flex-row w-full mt-5 rounded-2xl md:w-full h-auto px-5 py-5 bg-secondary_2'>
                  <p className={`w-full text-center mt-1 p-2 bg-transparent`} 
                     onClick={() =>  this.abrirInventarioModal(index) }>
                    {item.inventario.identificador}
                  </p>
                  <p className={`w-full text-center mt-1 p-2 bg-transparent`} 
                     onClick={() =>  this.abrirUsuariosModal(index, true) }>
                    {`${item.usuario_a.nombre} ${item.usuario_a.app_paterno} ${item.usuario_a.app_materno}`}
                  </p>
                  <p className={`w-full text-center mt-1 p-2 bg-transparent`} 
                     onClick={() =>  this.abrirUsuariosModal(index, false) }>
                    {`${item.usuario_r.nombre} ${item.usuario_r.app_paterno} ${item.usuario_r.app_materno}`}
                  </p>
                  <select
                      className={`w-full text-center mt-1 p-2 bg-transparent`}
                      value={item.suc_id_origen}
                      onChange={(event) => this.cambiarSelect(event, index, "suc_id_origen", "la sucursal de origen")}
                      onBlur={() => this.resetInventario()}
                      >
                        {
                          this.state.sucursales.map(sucursal =>{
                            return <option key={sucursal.id_sucursal} value={sucursal.id_sucursal} >{this.getSucursalNombre(sucursal.id_sucursal)}</option>
                          })
                        }

                  </select>
                  <select
                      className={`w-full text-center mt-1 p-2 bg-transparent`}
                      value={item.suc_id_destino}
                      onChange={(event) => this.cambiarSelect(event, index, "suc_id_destino", "la sucursal de destino")}
                      onBlur={() => this.resetInventario()}
                      >
                        {
                          this.state.sucursales.map(sucursal =>{
                            return <option key={sucursal.id_sucursal} value={sucursal.id_sucursal} >{this.getSucursalNombre(sucursal.id_sucursal)}</option>
                          })
                        }

                  </select>
                  <select
                      className={`w-full text-center mt-1 p-2 bg-transparent`}
                      value={item.status}
                      onChange={(event) => this.cambiarSelect(event, index, "status", "el estatus")}
                      onBlur={() => this.resetInventario()}
                      >
                        {
                          this.state.estatus.map(estatus =>{
                            return <option key={estatus.id_estatus} value={estatus.id_estatus} >{estatus.nombre}</option>
                          })
                        }

                  </select>
                  <select
                      className={`w-full text-center mt-1 p-2 bg-transparent`}
                      value={item.firma}
                      onChange={(event) => this.cambiarSelect(event, index, "firma", "la firma")}
                      onBlur={() => this.resetInventario()}
                      >
                        {
                          this.state.firmas.map(firma =>{
                            return <option key={firma.id_firma} value={firma.id_firma} >{firma.nombre}</option>
                          })
                        }

                  </select>
                  
                  <div className={`w-full flex justify-evenly items-center p-2 mt-1 bg-transparent `}>
                    <button className='bg-red-500 rounded p-1 px-2 mr-2' onClick={ () => this.eliminarItem(item.id_resguardo, item.inventario_id)} >D</button>
                    <button onClick={ () => this.abrirDetallesResguardo(item.id_resguardo) }>
                      <svg
														class='h-6 w-6 text-white'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'>
														{" "}
														<path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7' />{" "}
														<path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z' />
											</svg>
                    </button>
                  </div>
  
                </div>
              )
            })
          }
          
          {
            this.state.modalVisible && (
              <ResguardoDetalles cerrarModal={this.cerrarModal} resguardoSelect={this.state.resguardoSelect} getResguardos={this.getResguardos}/>
            )
          }
          {
            this.state.modalInventario && (
              <InventarioModal cerrarModal={this.cerrarModalInventario} cambiarItemInventario={this.cambiarItemInventario} />
            )
          }
          {
            this.state.modalUsuarios && (
              <UsuariosModal cerrarModal={this.cerrarModalUsuarios} cambiarUsuario={this.cambiarUsuario} />
            )
          }
        </div>
      </div>
    );  
  }
  
}

export default ResguardosPrincipal;
