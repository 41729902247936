import { Component, createRef } from "react"

import { NavLink, Link } from "react-router-dom"
import AdministrativoService from "services/AdministrativoService"
import VinculacionService from "services/VinculacionService"
import VinculacionDetalles from "./VinculacionDetalles"
import SweetAlert from "sweetalert2"
import Puestos_AdmonService from "services/Puestos_AdmonService"
import CatalogoDireccionService from "services/CatalogoDireccionService"
import { withRouter } from "react-router-dom"
import close from '../images/cancel.svg'
import ClientesService from "services/ClientesService"
import { sweetAlert } from "sweetalert2/dist/sweetalert2"
import sweetalert from "sweetalert2"
import { toHaveValue } from "@testing-library/jest-dom/dist/matchers"
import NuevasVinculaciones from "./NuevasVinculaciones"


class Vinculacion extends Component {
	refModalDet
	refModalAct
	buscador
	constructor(props) {
		super(props)
		this.refModalDet = createRef()
		this.refModalAct = createRef()
		this.buscador = ""
		this.state = {
			modalDetalles: false,
			modalActualizar: false,
			listaVinculacion:[],
            modalVisible: false,
            modalVisibleNuevaVinculacion: false,
            itemSelect: 0,
			dn:0,
        }
            
	}
	cerrarModal = () =>{
        this.setState({modalVisible: false})
    }
	cerrarModalNuevaVinculacion = () =>{
        this.setState({modalVisibleNuevaVinculacion: false})
    }

	  getLista = () =>{
		VinculacionService.listaVinculacion().then(response =>{
		  this.setState({listaVinculacion: response.data})
		  console.log(response.data);
		}).catch(err =>{
		  sweetAlert.fire({
			text: "Error al obtener los datos",
			allowOutsideClick: false,
			allowEscapeKey: false
		  })
		  console.log('Error en listarInventario ', err)
		})
	  }
	  getDetalleMSISDN(msisdn) {
		this.setState({modalVisible: true, itemSelect: msisdn});
	  }
	  componentDidMount() {

		this.getLista();
		//VinculacionService.ConsultabyMSISDN(this.state.dn);
		

		
	}
	

abrirDettallesVinculados(item){
	//TODO: Se debe sacar el id o algun identificador del item para poder buscarlo en la base de datos desde el modal
    this.setState({modalVisible:true,itemSelect:item})
}
abrirNuevaVinculacion(){
	//TODO: Se debe sacar el id o algun identificador del item para poder buscarlo en la base de datos desde el modal
    this.setState({modalVisibleNuevaVinculacion:true})
}

eliminarItem = (item) =>{
    sweetalert
      .fire({
        text: `Seguro quiere eliminar el item`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
			const data = {
				"status": 0,
				"id_vinculacion": item.ID_Vinculacion
			}
			VinculacionService.ActualizarStatusVinculacion(data).then(response =>{
				console.log("Vinculacion desabilitada con exito", response);
				this.getLista();
			}).catch(err =>{
				console.log("Error al eliminarItem", err);
			})
        }
      });
  }

	render() {
		return (
			<div className='bg-secondary_2 bg-opacity-30 text-white p-10'>
			  <h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
							  Vinculacón
						  </h1>
	  
						  <div className="flex justify-start mx-10 mt-4">
							  <nav class="flex" aria-label="Breadcrumb">
								  <ol class="inline-flex items-center space-x-1 md:space-x-3">
									  <li class="inline-flex items-center">
										  <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
											  <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
											  Home
										  </a>
									  </li>
									  
									  <li aria-current="page">
										  <div class="flex items-center">
											  <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
											  <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Vinculación</span>
										  </div>
									  </li>
								  </ol>
							  </nav>
						  </div>
			  
			  <div className='col-span-2'>
			  <div className="flex justify-start ">
								  <div class="xl:w-96 mt-8">
									  <div class="ml-10 input-group relative flex flex-wrap items-stretch w-full mb-4 ">
										  <input type="search"
											  class="form-control relative flex-auto px-3 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none"
											  placeholder="Buscar Ítem"
											  aria-label="Search"
											  id="txtbuscar"
											  name="txtbuscar"
										  // 	onKeyUp={event => {
										  // 		if (event.target.value !== "") {
										  // 			const array = this.state.inventarioModificado.filter(
										  // 				(value, posi) =>
										  // 					event.target.value ===
										  // 					value.nombre.substring(0, event.target.value.length)
										  // 				)
										  // 			this.setState({ inventarioModificado: array })
										  // 		} else {
										  // 				this.setState({
						//           inventarioModificado: this.state.inventarioModificado,
										  // 					})
										  // 				}
										  // 					}
										  // }
										  aria-describedby="button-addon2" />
	  
										  <button>
											  <svg class="h-6 w-6 text-white ml-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
										  </button>
								  </div>
								  </div>
							  </div>
				<div className='flex flex-row-reverse mr-10'>
				 <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirNuevaVinculacion() }><i class='fas fa-plus-circle'></i> Agregar nueva Vinculación</button>
				</div>
				
	  
			  </div>
			  <div className='h-6/6 w-full p-2'>
				<div className='flex flex-row gap-1'>
				  <h4 className='w-1/6 flex-1 text-center mt-1 p-2 font-bold text-lg rounded-tl-lg'>Cliente</h4>
				  {/* <h4 className='w-1/6 flex-1 text-left mt-1 p-2 font-bold text-lg rounded-tl-lg'></h4> */}
				  <h4 className='w-1/6 flex-1 text-left mt-1 p-2 font-bold text-lg'>Email</h4>
				  <h4 className='w-1/6 flex-1 text-left mt-1 p-2 font-bold text-lg'>Télefono</h4>
				  <h4 className='w-1/6 flex-1 text-left mt-1 p-2 font-bold text-lg'>Patrocinador</h4>
				  <h4 className='w-1/6 flex-1 text-left mt-1 p-2 font-bold text-lg'>Email</h4>
			
				 
				 
				  <h4 className='w-1/6 flex-1 text-center mt-1 p-2 font-bold text-lg rounded-tr-lg'>Acciones</h4>
				</div>
				{
				  this.state.listaVinculacion.map((item, index) =>{
					return (
					  <div className='flex justify-around flex-row w-full mt-5 rounded-2xl md:w-full h-auto px-5 py-5 bg-secondary_2'>
							{/* <div className={`w-full flex justify-evenly items-center p-2 mt-1 bg-transparent `}> */}
								<h1 className="w-1/6 text-white text-left pl-3 md:pl-5 text-lg font-barlow ">{`${item.cNombre} ${item.cApp} ${item.cApm}`}</h1>
								{/* <h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow ">{item.cApp}</h1>
								<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow ">{item.cApm}</h1> */}
							{/* </div> */}
								<h1 className="w-1/6 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.cEmail}</h1>
								<h1 className="w-1/6 text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.msisdn}</h1>
								<h1 className="w-1/6 text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{`${item.iNombre} ${item.iApp} ${item.iApm}`}</h1>
								<h1 className="w-1/6 text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.cEmail}</h1>
																		
								<div className={`w-1/6 flex justify-evenly items-rig p-2 mt-1 bg-transparent `}>	
										<button title='Editar item' onClick={ () => this.abrirDettallesVinculados(item.ID_Vinculacion) }><svg
																			class='h-6 w-6 text-white'
																			width='24'
																			height='24'
																			viewBox='0 0 24 24'
																			xmlns='http://www.w3.org/2000/svg'
																			fill='none'
																			stroke='currentColor'
																			stroke-width='2'
																			stroke-linecap='round'
																			stroke-linejoin='round'>
																			{" "}
																			<path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7' />{" "}
																			<path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z' />
																		</svg>
										</button>
										{/* <button title='Eliminar item' className='bg-red-500 rounded p-1 px-2 mr-2' >Eliminar</button> */}
										<button title='Eliminar item' className='bg-red-500 rounded p-1 px-2 mr-2' onClick={ () => this.eliminarItem(item)} >Eliminar</button>
								</div>
						  
		
					  </div>
					)
				  })
				}

				{
					this.state.modalVisible && (
					//TODO: AQUI VA EL COMPONENTE QUE VA A SER EL MODAL DE DETALLES
					<VinculacionDetalles cerrarModal={this.cerrarModal} itemSelect={this.state.itemSelect} />
					)
				}
				{
					this.state.modalVisibleNuevaVinculacion && (
					<NuevasVinculaciones cerrarModal={this.cerrarModalNuevaVinculacion} getLista={this.getLista} />
					)
				}
				
				
			  </div>
			</div>
		  );  
		
       
	}
}

export default withRouter(Vinculacion);