import { Component, createRef } from "react"
import { NavLink, Link } from "react-router-dom"
import AdministrativoService from "services/AdministrativoService"
import SweetAlert from "sweetalert2"
import Puestos_AdmonService from "services/Puestos_AdmonService"
import CatalogoDireccionService from "services/CatalogoDireccionService"
import { withRouter } from "react-router-dom"
import close from '../images/cancel.svg'
import AprovisionamientoLogService from "services/AprovisionamientoLogService"
import Swal from "sweetalert2"
import AprovisionamientoService from "services/AprovisionamientoService"


class Aprovisionamiento extends Component {
	refModalDet
	refModalAct
	buscador

	constructor(props) {
		super(props)
		this.refModalDet = createRef()
		this.refModalAct = createRef()
		this.buscador = ""
		this.state = {
			listAdministrativos: [],
			administrativo: null,
			modalActualizar: false,
			option_puesto_administrativo: [],
			lAdmonTabla: [],
			saldoUserLogin: 0,
			saldoUserLoginFinal: 0,
			saldoUserReceptor: 0,
			saldoUserReceptorFinal: 0,
			saldoAProvisionar: 0,
			roles: [],
			infoLoginBssMovil: {},
		}

		this.goBack = this.goBack.bind(this);
	}
	
	componentDidMount = () => {
		this.setState({roles: sessionStorage.getItem('roles')})
		this.getListaAdmon()
		this.getListPuesto_Admon("all").then(value =>
			this.setState({ option_puesto_administrativo: value })
		)
		this.getAprovisionamientoLogUsuario(sessionStorage.getItem("id")).then(response =>{
			if(response.saldo != null){
				this.setState({saldoUserLogin: response.saldo, saldoUserLoginFinal: response.saldo})
			}else{
				const aprovisionamientoLog = {
					usuario_id: sessionStorage.getItem('id'),
					saldo: 0,
					register_by: sessionStorage.getItem("id"),
					status: 1,
				}
				AprovisionamientoLogService.InsertarAprovisionamientoLog(aprovisionamientoLog).then(response =>{
					console.log("Creado aprovisionamiento");
				}).catch(err =>{
					console.log("Error al crear aprovisionamiento");
				})
				this.setState({saldoUserLogin: 0, saldoUserLoginFinal: 0})
			}
		});
		this.loginBssmovil();
	}

	loginBssmovil = () =>{
		AdministrativoService.loginPlaycel().then((res1) => {
			if (res1.status===200){
				this.setState({infoLoginBssMovil: res1.data})
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: "No se pudo concetar con el sistema de Playcel",
				});
			}
		});
	}

	getListaAdmon = async () => {
		const arrayAux = await AdministrativoService.listAdministrativos(sessionStorage.getItem("id"))
			.then(resultado => resultado.data)
			.catch(error => [])
		for(let i = 0; i < arrayAux.length; i ++){
			await this.getAprovisionamientoLogUsuario(arrayAux[i]?.id_usuario).then(response =>{ 
				if(response.saldo != null){
					arrayAux[i] = {...arrayAux[i], saldo: response.saldo};
				}else{
					const aprovisionamientoLog = {
						usuario_id: arrayAux[i]?.id_usuario,
						saldo: 0,
						register_by: sessionStorage.getItem("id"),
						status: 1,
					}
					AprovisionamientoLogService.InsertarAprovisionamientoLog(aprovisionamientoLog).then(response =>{
						console.log("Creado aprovisionamiento");
					}).catch(err =>{
						console.log("Error al crear aprovisionamiento");
					})
					arrayAux[i] = {...arrayAux[i], saldo: 0};
				}
			});
		}
		this.setState({
			listAdministrativos: arrayAux,
			lAdmonTabla: arrayAux,
		})
		console.log({arrayAux})
	}

	getAprovisionamientoLogUsuario = async(idUsuario) =>{
		return await AprovisionamientoLogService.AprovisionamientoLogByUsuarioId(idUsuario).then(response =>{
			return response.data;
		}).catch(err =>{
			console.log("Error en getAprovisionamientoLogUsuarioLogin ", err);
			return 0;
		})
	}




	getListPuesto_Admon = async rol => {
		return (await Puestos_AdmonService.listaPuestos(!rol ? this.rol : rol)).data
	}

	updateData = data => {
		this.setState({ administrativo: data, modalActualizar: true }, () => {
			this.refModalAct.current.focus()
		});
		this.getAprovisionamientoLogUsuario(data.id_usuario).then(response =>{
			console.log({response}); 
			if(response.saldo != null){
				this.setState({saldoUserReceptor: response.saldo, saldoUserReceptorFinal: response.saldo});
			}else{
				const aprovisionamientoLog = {
					usuario_id: data.id_usuario,
					saldo: 0,
					register_by: sessionStorage.getItem("id"),
					status: 1,
				}
				AprovisionamientoLogService.InsertarAprovisionamientoLog(aprovisionamientoLog).then(response =>{
					console.log("Creado aprovisionamiento");
				}).catch(err =>{
					console.log("Error al crear aprovisionamiento");
				})
				this.setState({saldoUserReceptor: 0});
			}
		});
	}

	cambiarSaldoAprovisionar = async(value) =>{
		if(value == ''){
			this.setState({ saldoAProvisionar: value })
			this.setState({ saldoUserLoginFinal: this.state.saldoUserLogin, saldoUserReceptorFinal: this.state.saldoUserReceptor })
		}else{
			const nuevoSaldoUserLogin = parseFloat(this.state.saldoUserLogin - parseFloat(value)).toFixed(4);
			if(nuevoSaldoUserLogin >= 0 ){
			this.setState({ saldoAProvisionar: value })
				const nuevoSaldoUserReceptor = parseFloat(this.state.saldoUserReceptor + parseFloat(value)).toFixed(4);
				return this.setState({ saldoUserLoginFinal: nuevoSaldoUserLogin, saldoUserReceptorFinal: nuevoSaldoUserReceptor })
			}else{
				// Swal.fire("Sin saldo suficiente", "algo", "algo otra vez")
				Swal.fire({
					title: 'Saldo insuficiente',
					showConfirmButton: false,
					toast: true,
					position: 'top-right',
					timer: 2000,
				  })
			}
		}
		

	}


	sendData = event => {
		event.preventDefault()
		this.setState({modalActualizar: false});
		let dataAprovisionamientoLog = {
			"saldo": this.state.saldoUserReceptorFinal,
            "usuario_id": this.state.administrativo?.id_usuario
		}
		AprovisionamientoLogService.ActualizarSaldoAprovisionamientoLog(dataAprovisionamientoLog).then(response =>{
			console.log("Actualizado saldo");
			const dataAprovisionamiento = {
				"usuario_id_proveedor": sessionStorage.getItem('id'),
				"usuario_id_receptor": this.state.administrativo.id_usuario,
				"saldo": this.state.saldoAProvisionar,
				"register_by": sessionStorage.getItem('id'),
				"status": 1,
			}
			AprovisionamientoService.InsertarAprovisionamiento(dataAprovisionamiento).then(response =>{
				console.log("Registrado aprovisionamiento");
			}).catch(err =>{
				console.log("Error al registrar aprovisionamiento", err);
			})

			dataAprovisionamientoLog = {
				"saldo": this.state.saldoUserLoginFinal,
				"usuario_id": sessionStorage.getItem("id")
			}
			AprovisionamientoLogService.ActualizarSaldoAprovisionamientoLog(dataAprovisionamientoLog).then(response =>{
				console.log("Actualizado saldo");
				this.getAprovisionamientoLogUsuario(sessionStorage.getItem('id')).then(response =>{
					this.setState({saldoUserLogin: response.saldo, saldoUserLoginFinal: response.saldo})
				})
				this.getListaAdmon();
			}).catch(err =>{
				console.log("Error al actualizar el saldo", err);
				Swal.fire({
					icon: 'error',
					title: 'Error al realizar aprovisionamiento',
					text: 'Algo fue mal',
				  })
			});
		}).catch(err =>{
			console.log("Error al actualizar el saldo", err);
			Swal.fire({
				icon: 'error',
				title: 'Error al realizar aprovisionamiento',
				text: 'Algo fue mal',
			  })
		});
		
		
	}

	goBack() {
		this.props.history.push("/app/ListaUsuarios");
	}

	cambiarStatus(txt) {
		if (txt === 'A') {
			return "ACTIVO"
		}
		else if (txt === 'I') {
			return "INACTIVO"
		}
	}

	recargarSaldo = () =>{
		if(this.state.infoLoginBssMovil != {}){
			Swal.fire({
				text:`Esta seguro de recargar saldo?`,
				showCancelButton:true,
				confirmButtonText: "Aceptar",
				cancelButtonText: "Cancelar",
				cancelButtonColor: "red",
				allowOutsideClick: true,
				allowEscapeKey: true
			})
			.then(resultado => {
				if (resultado.isConfirmed){
					AdministrativoService.loginPlaycel().then((res1) => {
						if (res1.status===200){
							this.setState({infoLoginBssMovil: res1.data})
							AdministrativoService.recargarSaldoCocobel({brer: res1.data?.token}).then(response =>{
								console.log("Recarga realizada", response.data);
								const balance = response.data?.balance;
								const nuevoSaldo = this.state.saldoUserLogin + balance;
								const data = {
									"saldo": nuevoSaldo,
									"usuario_id": sessionStorage.getItem("id"),
								}
								AprovisionamientoLogService.ActualizarSaldoAprovisionamientoLog(data).then(response =>{
									console.log("Saldo actualizado");
									this.setState({saldoUserLogin: nuevoSaldo});
								}).catch(err =>{
									console.log("Error al actualizar el saldo en la base de datos", err);
								})
							}).catch(err =>{
								console.log("Error al recargarSaldo", err);
								Swal.fire({
									icon: 'error',
									title: 'Algo fue mal',
									text: 'Error al recargar el saldo, pruebe más tarde',
								  });
							});
						} else {
							Swal.fire({
								icon: 'error',
								title: 'Error',
								text: "No se pudo concetar con el sistema de Playcel",
							});
						}
					});
				}
			});  
		}
	}

	render() {
		return (
			<>
				<div className="bg-secondary_2 text-white h-auto py-10 bg-opacity-50">
					
					<h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
						Asignación de Fondos
					</h1>

					<div className="flex justify-start mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">usuarios</a>
									</div>
								</li>
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Asignación de Fondos</span>
									</div>
								</li>
							</ol>
						</nav>
					</div>

					<section>

					<div className="col-span-2">
                    <div className="flex justify-start ">
                        <div class="xl:w-96 mt-8">
                            <div class="ml-10 input-group relative flex flex-wrap items-stretch w-full mb-4 ">
								<input type="search"
									class="form-control relative flex-auto px-3 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none"
									placeholder="Buscar nombre"
									aria-label="Search"
									id="txtbuscar"
									name="txtbuscar"
									onKeyUp={event => {
										if (event.target.value !== "") {
											const array = this.state.listAdministrativos.filter(
												(value, posi) =>
													event.target.value ===
													value.nombre.substring(0, event.target.value.length)
											)
											this.setState({ lAdmonTabla: array })
										} else {
											this.setState({
												lAdmonTabla: this.state.listAdministrativos,
											})
										}
									}}
									aria-describedby="button-addon2" />

								<button
								><svg class="h-6 w-6 text-white ml-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
								</button>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-row mr-10 justify-end'>
						<h2 className="text-3xl">Tu saldo: {this.state.saldoUserLogin} </h2>
						{
							this.state.roles?.includes("SUPER_ADMIN") &&(
								<button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.recargarSaldo() }>Recargar saldo</button>
							)
						}
                    </div>
                </div>

						<div className="px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto" >
							<div className="w-full ">
								<div className="w-full text-sm grid grid-cols-12 text-gray-800 font-semibold">

									<div className="font-semibold text-center col-span-3 ml-3 px-6 py-3 text-white">
										<div className="flex justify-start items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Nombre completo</label><br />
											</div>
										</div>
									</div>

									<div className="font-semibold text-center col-span-3 ml-3 px-6 py-3 text-white">
										<div className="flex justify-start items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Puesto</label><br />
												<section className='flex justify-center width-100pr'>
													<select
														onChange={event => {
															let selectIndex = event.target.selectedIndex
															console.log(
																selectIndex,
																event.target.options[selectIndex].value
															)
															let puesto_id = parseInt(
																event.target.options[selectIndex].value
															)
															if (!isNaN(puesto_id)) {
																const array = this.state.listAdministrativos.filter(
																	(value, posi) => puesto_id === value.puesto_id
																)
																this.setState({ lAdmonTabla: array })
															} else {
																this.setState({
																	lAdmonTabla: this.state.listAdministrativos,
																})
															}
														}}
														name='puesto_id'
														class='bg-purple-white shadow rounded border-0 p-1 transition-all duration-300 ease-in-out outline-none focus:ring-2 focus:ring-secondary text-secondary'>
														<option>NO ESPECIFICADO</option>
														{this.state.option_puesto_administrativo.map(item => {
															return (
																<option key={item.id_puesto} value={item.id_puesto}>
																	{item.descripcion}
																</option>
															)
														})}
													</select>
												</section>
											</div>
										</div>
									</div>

									<div className="font-semibold text-center col-span-2 ml-3 px-6 py-3 text-white">
										<div className="flex justify-center items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Saldo</label><br />
											</div>
										</div>
									</div>

									<div className="font-semibold text-center col-span-2 ml-3 px-6 py-3 text-white">
										<div className="flex justify-center  items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest " htmlFor="">Status</label><br />
												<section className='flex justify-center width-100pr'>
													<select
														class='bg-purple-white shadow rounded border-0 p-1 transition-all duration-300 ease-in-out outline-none focus:ring-2 focus:ring-secondary text-secondary'
														name='status2'
														id='status2'
														onChange={event => {
															let selectIndex = event.target.selectedIndex
															console.log(
																selectIndex,
																event.target.options[selectIndex].value
															)
															let opcion = event.target.options[selectIndex].value
															const array = this.state.listAdministrativos.filter(
																(value, posi) => opcion === value.estatus
															)
															this.setState({ lAdmonTabla: array })
														}}>
														<option value={"A"}> A </option>
														<option value={"I"}> I </option>
													</select>
												</section>
											</div>
										</div>
									</div>
									<div className="font-semibold text-center col-span-2 ml-3 px-6 py-3 text-white">
										<div className="flex justify-center items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Aprovisionar</label><br />
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

						{this.state.lAdmonTabla.map((value, index) => (
							<div className="w-full flex justify-center items-center">
								<div className="mt-5 rounded-2xl md:w-full lg:grid grid-cols-12 h-auto px-5 py-5 bg-secondary_2 relative">

									<div className="col-span-3 flex justify-start items-center">
										<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{value.nombre} {value.app_paterno} {value.app_materno}</h1>
									</div>

									<div className="col-span-3 flex justify-start items-center">
										<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{value.puesto}</h1>
									</div>

									<div className="col-span-2 flex justify-center items-center">
										<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{value.saldo}</h1>
									</div>

									<div className="col-span-2 flex justify-center items-center">
										<section className='col-span-2 flex justify-center items-center'>
											<span
												className={
													"" + value.estatus === "A"
														? "bg-activo rounded-full px-3 py-1"
														: value.estatus === "Baja temporal"
															? "bg-baja-temporal rounded-full px-3 py-1"
															: value.estatus === "I"
																? "bg-inactivo rounded-full px-3 py-1"
																: value.estatus === "Baja definitiva"
																	? "bg-baja-definitiva rounded-full px-3 py-1"
																	: "bg-activo rounded-full px-3 py-1 text-white text-center uppercase tracking-wide"
												}>
												<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{this.cambiarStatus(value.estatus)}</h1>
											</span>
										</section>
										
									</div>

									<div className="col-span-2 flex justify-center items-center">
										<div className='flex justify-center items-center col-span-1'>

											<div class='mt-2 ml-2 transform hover:text-purple-500 hover:scale-110'>
												<button
													hidden={this.state.ocultar}
													onClick={() => {
														this.updateData(value)
													}}
													title='Actualizar alumno'>
													<svg
														class='h-6 w-6 text-white'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'>
														{" "}
														<path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7' />{" "}
														<path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z' />
													</svg>
												</button>
											</div>
										</div>
									</div>
								</div>

							</div>
						))}



						{this.state.modalActualizar ? (
							<>
								<form onSubmit={this.sendData}>
									<div div class="overflow-y-auto h-32">
										<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
											<div className='relative w-auto my-6 mx-auto max-w-3xl z-20'
												tabIndex={1}
												ref={this.refModalAct}
												onKeyDown={event => {
													if (event.code === "Escape") {
														console.log(event.code)
														this.setState({ modalActualizar: false })
													}
												}}>
												{/*content*/}
												<div className='rounded-3xl bg-black relative flex flex-col w-full outline-none focus:outline-none shadow-sm2'>
													{/*header*/}
													<div className='rounded-t-3xl bg-black'>
														<h3 className='text-white text-3xl text-center my-5 font-bold uppercase tracking-widest'>
															Aprovisionar
														</h3>
													</div>
													{/*body*/}

													<div className='relative flex flex-col items-center gap-5 px-6 py-5 bg-black '>
														<div className="">
															<p>Tu saldo final: {this.state.saldoUserLoginFinal}</p>
														</div>
														<div>
															<p>Saldo final de {this.state.administrativo?.nombre}: {this.state.saldoUserReceptorFinal}</p>
														</div>
														<div >
															<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																Cantidad a provisionar
																<span className='text-red-500 text-xl'>*</span>
															</label>
															<input
																name='saldoAProvisionar'
																value={this.state.saldoAProvisionar}
																type="number"
																onChange={event => {this.cambiarSaldoAprovisionar(event.target.value)}}
																className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '
																required>
															</input>
														</div>
													</div>
													{/*footer*/}
													<div className='bg-black flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
														<button
															className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
															type='button'
															onClick={() => this.setState({ modalActualizar: false })}>
															Cerrar
														</button>

														<button
															className='bg-emerald-500 text-white font-bold uppercase text-sm px-3 py-2  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 bg-normal'
															type='submit'>
															Actualizar
														</button>
													</div>
												</div>
											</div>
											<div style={{ right: "12px" }} onClick={() => this.setState({ modalActualizar: false })} className='cursor-pointer opacity-25 fixed inset-0 bg-black'></div>
										</div>

									</div>
								</form>
							</>
						) : null}
					</section>

				</div>
			</>

		)
	}
}

export default withRouter(Aprovisionamiento);