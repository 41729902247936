import axios from 'axios';
import { APIURL } from './Host';


class SucursalesService{


    ListarSucursales(){
        return axios.post(`${APIURL}/sucursales`);
    }

    SucursalById(idSucursal){
        return axios.post(`${APIURL}/sucursales/${idSucursal}`);
    }

    InsertarSucursal(sucursales){
        const data = {
            "nombre": sucursales.nombre,
            "estado_id": sucursales.estado_id,
            "mun_id":sucursales.mun_id,
			"localidad_id": sucursales.localidad_id,
            "calle": sucursales.calle,
            "numero": sucursales.numero,
            "observaciones":sucursales.observaciones,
			"status": sucursales.status,
            "register_by":sucursales.register_by,
        }
        return axios.post(`${APIURL}/sucursales/insert`, data);
    }

    ActualizarSucursal(sucursales){
        const data = {
            "nombre": sucursales.nombre,
            "estado_id": sucursales.estado_id,
            "mun_id":sucursales.mun_id,
			"localidad_id": sucursales.localidad_id,
            "calle": sucursales.calle,
            "numero": sucursales.numero,
            "observaciones":sucursales.observaciones,
			"status": sucursales.status,
            "register_by":sucursales.register_by,
            "lu_by": sucursales.lu_by,
            "id_sucursal": sucursales.id_sucursal
        }
        return axios.post(`${APIURL}/sucursales/update`, data);
    }

    ActualizarStatusSucursal(sucursales){
        const data = {
            "status": sucursales.status,
            "id_sucursal": sucursales.id_sucursal
        }
        return axios.post(`${APIURL}/sucursales/update/status`, data);
    }

}

export default new SucursalesService();