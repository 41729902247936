import axios from 'axios';
import { APIURL } from './Host';


class InversionistaService{


    ListarInversionista(){
        return axios.post(`${APIURL}/inversionista`);
    }

    InversionistaById(idInversionista){
        return axios.post(`${APIURL}/inversionista/${idInversionista}`);
    }

    InsertarInversionista(inversionista){
        const data = {
            "usuario_id": inversionista.usuario_id,
            "distribuidor": inversionista.distribuidor,
            "email": inversionista.email,
            "tipo_inversionista": inversionista.tipo_inversionista,
            "status": inversionista.status,
        }
        return axios.post(`${APIURL}/inversionista/insert`, data);
    }

    ActualizarInversionista(inversionista){
        const data = {
            "usuario_id": inversionista.usuario_id,
            "distribuidor": inversionista.distribuidor,
            "email": inversionista.email,
            "tipo_inversionista": inversionista.tipo_inversionista,
            "lu_by": inversionista.lu_by,
            "status": inversionista.status,
            "id_inversionista": inversionista.id_inversionista,
        }
        return axios.post(`${APIURL}/inversionista/update`, data);
    }

    ActualizarStatusInversionista(inversionista){
        const data = {
            "status": inversionista.status,
            "id_inversionista": inversionista.id_inversionista
        }
        return axios.post(`${APIURL}/inversionista/update/status`, data);
    }

	listInversionista(id_admin) {
		console.log("ID recibido Service****"+id_admin);
		return axios.post(APIURL + "/inversionista/list/"+id_admin);
	}

	updateInversionista(data) {
		return axios.put(APIURL + "/inversionista/update", data)
	}

	updateStatusInversionistaUsuario(data) {
		return axios.put(APIURL + "/inversionista/update/status", data)
	}

	inversionistaById(id_admin){
		return axios.get(APIURL+"/inversionistaById/"+id_admin);
	}

    ListarTipoInversionista(){
        return axios.post(`${APIURL}/tipo_inversionista`);
    }

}

export default new InversionistaService();