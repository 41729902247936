import axios from 'axios';
import { APIURL } from './Host';


class CatIdentificadoresService{


    ListarIdentificadores(){
        return axios.post(`${APIURL}/identificadores`);
    }

    IdentificadorById(idIdentificador){
        return axios.post(`${APIURL}/identificadores/${idIdentificador}`);
    }

    IdentificadorByCategoria(idCategoria){
        return axios.post(`${APIURL}/identificadores/byCategoria/${idCategoria}`);
    }

    InsertarIdentificador(identificador){
        const data = {
            "descripcion": identificador.descripcion, 
            "register_by": identificador.register_by
        }
        return axios.post(`${APIURL}/identificadores/insert`, data);
    }

    ActualizarIdentificador(identificador){
        const data = {
            "descripcion": identificador.descripcion, 
            "register_by": identificador.register_by,
            "lu_by": identificador.lu_by, 
            "id_identificadores": identificador.id_identificadores
        }
        return axios.post(`${APIURL}/identificadores/update`, data);
    }

    EliminarIdentificador(idIdentificador){
        return axios.post(`${APIURL}/identificadores/delete/${idIdentificador}`);
    }

}

export default new CatIdentificadoresService();