
import { withRouter } from "react-router-dom";
import PortabilidadesService from "services/PortabilidadesService";
import PortabilidadModal from "./PortabilidadModal";
import CambioMSISDNService from "services/CambioMSISDNService";
import GeneralService from "services/GeneralService";
import CambioMSISDNModal from "./CambioMSISDNModal";
const { Component } = require("react-image-crop");

class Portabilidades extends Component{

	constructor(props) {
		super(props);
		this.state = {
			id_usuario : sessionStorage.getItem("id"),
			cambiosMSISDN:[],
			showCambioMSISDNModal : false,
		};
	}
	componentDidMount(){
		this.getCambiosMSISDN();
	}	

	getCambiosMSISDN = () =>{
		CambioMSISDNService.ListarCambiosMSISDN(GeneralService.permutaIdUsuario(this.state.id_usuario)).then(response =>{
			console.log({response});
			this.setState({cambiosMSISDN: response.data});
		}).catch(err =>{
			console.log("Error en getPortabilidades", err);
		})
	}


	nuevoCambioMSISDN=()=>{
		this.setState({showCambioMSISDNModal : true});
	}

	cerrarCambioMSISDNModal = () =>{
		this.setState({showCambioMSISDNModal: false});
	}

render(){
		return (
		  <div className='bg-secondary_2 bg-opacity-30 text-white p-10'>
			<h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
			Cambios de MSISDN
						</h1>
	
						<div className="flex justify-start mx-10 mt-4">
							<nav class="flex" aria-label="Breadcrumb">
								<ol class="inline-flex items-center space-x-1 md:space-x-3">
									<li class="inline-flex items-center">
										<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
											<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
											Home
										</a>
									</li>
									
									<li aria-current="page">
										<div class="flex items-center">
											<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
											<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Cambios de MSISDN</span>
										</div>
									</li>
								</ol>
							</nav>
						</div>
			<div className='flex justify-end p-3'>
			  <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.nuevoCambioMSISDN() }><i class='fas fa-plus-circle'></i> CAMBIO DE MSISDN</button>
	
			</div>
			<div className='h-11/12 w-full p-2'>
			  <div className='flex flex-row'>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Folio</h4>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>MSISDN Anterior</h4>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>MSISDN Nuevo</h4>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Fecha de Cambio</h4>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Estatus</h4>
			  </div>
			  {
				this.state.cambiosMSISDN.map((item, index) =>{
				  return (
					<div className='flex flex-row w-full mt-5 rounded-2xl md:w-full h-autopy-5 py-5 bg-secondary_2'>
						<h1 className="w-1/5 text-white text-center pl-3 md:pl-5 text-lg font-barlow ">{item.folio}</h1>
						<h1 className="w-1/5 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.msisdn_anterior}</h1>
						<h1 className="w-1/5 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.msisdn_Nuevo}</h1>
						<h1 className="w-1/5 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.date_reg}</h1>
						<h1 className="w-1/5 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.status == 0 ? "Error" : item.status == 1 ? "Finalizado" : "DESCONOCIDO"}</h1>
			  		</div>
				  )
				})
			  }
			  
			
			  
			</div>
			
			{this.state.showCambioMSISDNModal && (
					<CambioMSISDNModal cerrarModal={this.cerrarCambioMSISDNModal} getCambiosMSISDN={this.getCambiosMSISDN}/>
				)}

			</div>
		);  
	}
}
export default withRouter(Portabilidades) 