import { Component} from "react"
import { withRouter } from "react-router";
import UsuariosService from "services/UsuariosService"
import AdministrativoService from "services/AdministrativoService"
import sweetalert from "sweetalert2"
import ImgCargando from "../images/icono_cargando.svg"

import AguilaCaida from "../images/error.png"
import ClientesService from "services/ClientesService";
import FormClienteModal from "./FormClienteModal";
import PortabilidadesService from "services/PortabilidadesService";
import GeneralService from "services/GeneralService";

class PortabiliadModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			usuario: {},
			showModalClientes: false,
			nip: "",
			iccid: "",
			imei: "",
			date: "",
			bloquearBotones: false,
			num_transitorio:"",
			mostrar:false,
		}
	
	}

	abrirModalClientes =() => {
		this.setState({showModalClientes: true});
	}
	cerrarModalClientes =() => {
		this.setState({showModalClientes: false});
	}

	guardarUsuarioEnState = (usuario) =>{
		this.setState({usuario: usuario});
	}


	guardarUsuarioCliente = async() =>{
		const usuario = this.state.usuario;
		return await UsuariosService.registrarNuevoUsuario(usuario).then(async response => {
			const dataLogin = {
				usuario_id: response.data?.user?.id_usuario,
				tipo_usuario_id: "6",
				username: usuario.telefono1,
				password: usuario.telefono1
			}
			UsuariosService.registrarUsuarioLogin(dataLogin).then(response =>{
				console.log("Registrado en login");
			}).catch(err =>{
				console.log("Error al registrarUsuarioLogin ", err);
			})
			const data = {
				usuario_id: response.data?.user?.id_usuario,
				distribuidor: parseInt(sessionStorage.getItem("id")),
				email: response.data?.user?.correo,
				tipo_cliente: usuario?.tipo_cliente,
				status: 1,
			}
			return await ClientesService.InsertarCliente(data).then(response =>{
				return response.data?.id_cliente;
			}).catch(err =>{
				console.log("Error en insertarCliente ", err);
				return 0;
			})
			// this.props.history.push("/app/GestionDeClientes");
			
		}).catch(error => {
			sweetalert.fire({
				text: "No se pudo almacenar los datos a la base de datos",
				imageUrl:AguilaCaida,
				allowOutsideClick: false,
				allowEscapeKey: false
			})
			this.setState({bloquearBotones : false});
		});
	}

	realizarPortabilidad = (event) =>{
		event.preventDefault();

		const data = {
			number: this.state.usuario?.telefono1,
			nip: this.state.nip,
			client_name: `${this.state.usuario?.nombre} ${this.state.usuario?.apellido_pat} ${this.state.usuario?.apellido_mat}`,
			client_email: this.state.usuario?.email,
			client_contact_number: this.state.usuario?.telefono2,
			date: this.state.date,
			
		}
		PortabilidadesService.realizarPortabilidad(data).then(async response =>{
			console.log({response});
			const id_cliente = await this.guardarUsuarioCliente();
			let statusResponse = 1;
			switch (response.data?.status) {
				case "POR VERIFICAR":
					statusResponse= 1;
					break;
				case "VERIFICADO":
					statusResponse= 2;
					break;
				default:
					statusResponse= 1;
					break;
			}
			const dataPortabilidad ={
				telefono: this.state.usuario?.telefono1,
				id_cliente: id_cliente,
				date: this.state.date,
				imei: this.state.imei,
				iccid: this.state.iccid,
				nip: this.state.nip,
				user_id: response.data?.user_id,
				target_partner_id: response.data?.target_partner_id,
				id_portabilidad_altan: response.data?.id,
				register_by: sessionStorage.getItem("id"),
				status: statusResponse,
				num_transitorio:this.state.num_transitorio
			};
			PortabilidadesService.InsertarPortabilidad(dataPortabilidad).then(response =>{
				console.log("Portabilidad registrada", response.data);
				if(this.props.getPortabilidades){
					this.props.getPortabilidades();
				}
				sweetalert.fire({
					icon: 'success',
					title: 'Exito',
					text: "Portabilidad realizada y registrada exitosamente",
				});
				this.cerrarModal();
			}).catch(err =>{
				console.log("Error al registra portabilidad", err);
				sweetalert.fire({
					title: 'Error',
					text: "Portabilidad realizada exitosamente pero no registrada",
				});
				this.cerrarModal();
			})
		}).catch(err =>{
			console.log("Error en realizarPortabilidad", err);
			sweetalert.fire({
				icon: 'error',
				title: 'Error',
				text: "Error no se pudo realizar la portabilidad, vuelva a intentarlo",
			});
			this.cerrarModal();
		})
	}
	
	handleChange(event){
		const iccid = event.target.value;
		if(GeneralService.validarNumeros(iccid)){
			this.setState({iccid: iccid});
		}else{
			this.setState({iccid: ""});
		}
		
        if(GeneralService.validarLongitud(iccid,19)){
			this.setState({ mostrar: true });
			this.consultarIccid(iccid);
		}else{
			if(GeneralService.validarLongitud(iccid,0)){
				this.setState({num_transitorio: ""});
			}
			this.setState({ mostrar: false });
		}
    }

	consultarIccid(ccid){
		let token="";
		AdministrativoService.loginPlaycel().then(response =>{
	        token=response.data.token.split("|")[1];
            PortabilidadesService.consultarIccid(ccid,token).then(response =>{
                this.setState({ mostrar: false });
                this.setState({ num_transitorio: response.data.subscriber.msisdn});
              }).catch(err =>{
              })
		  })
	}

	cerrarModal = () =>{
		this.props.cerrarModal();
	}




	render() {
		return (
			<div className='fixed inset-6 z-50 flex flex-wrap justify-center items-center h-full w-full'>
            	<div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
				<section className='z-50 flex flex-col justify-center items-center w-2/3'>

					<form className="" onSubmit={this.realizarPortabilidad}>
					<div className='bg-secondary_2 rounded-3xl shadow-sm2 p-5 bg-base text-white'>
						<div className='text-center font-bold text-white text-3xl uppercase tracking-widest title-section'>
							"Nuevo Portabilidad"
						</div>
						<div >
				

							<span className='w-full'>
								<label htmlFor='nombre' className='text-12pt text-white'>
									Nip de Portabilidad*
								</label>
								<input
									name='nip'
									value={this.state.nip}
									onChange={event => this.setState({nip: event.target.value})}
									minLength="4"
									maxLength="4"
									className='w-full text-12pt border-radius-7px text-black'
									required
									/>
									{/* {this.state.errors.nombre && this.state.errors.nombre !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nombre}</p> : <p></p> } */}
							</span>
							<span className='w-full'>
								<label htmlFor='nombre' className='text-12pt text-white'>
									ICCID*
								</label>
								<input
									name='iccid'
									value={this.state.iccid}
									onChange={(event) => this.handleChange(event)}
									className='w-full text-12pt border-radius-7px text-black'
									required
									/>
									{/* {this.state.errors.nombre && this.state.errors.nombre !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nombre}</p> : <p></p> } */}
							</span>
							<span className='w-full'>
								<label htmlFor='nombre' className='text-12pt text-white'>
									Numero transitorio
								</label>
								<input
									name='num_transitorio'
									value={this.state.mostrar ? (
										'CARGANDO'
										) : (
											this.state.num_transitorio
										)}
		
									className='w-full text-12pt border-radius-7px text-black'
									disabled
									/>
									{/* {this.state.errors.nombre && this.state.errors.nombre !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nombre}</p> : <p></p> } */}
							</span>
							<span className='w-full'>
								<label htmlFor='nombre' className='text-12pt text-white'>
									IMEI
								</label>
								<input
									name='imei'
									value={this.state.imei}
									onChange={(event) => this.setState({imei: event.target.value})}
									className='w-full text-12pt border-radius-7px text-black'
									/>
									{/* {this.state.errors.nombre && this.state.errors.nombre !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nombre}</p> : <p></p> } */}
							</span>
							<span className='w-full'>
								<label>Fecha final</label>
								<br/>
								<input 
									className={`p-1 text-12pt border-radius-7px text-black`} 
									value={this.state.date}
									onChange={ event => this.setState({date: event.target.value}) }
									required
									type='date'
								/>
							</span>
							<div className='w-full flex justify-center items-center'>
								<button className="m-5 bg-indigo-700 p-2 rounded-lg"
									type="button"
									onClick={() => this.abrirModalClientes()}
								>Editar Cliente</button>
							</div>
							<span className='w-full'>
								<label htmlFor='nombre' className='text-12pt text-white'>
									Numero a portar*
								</label>
								<input
									value={this.state.usuario?.telefono1 || ""}
									className='w-full text-12pt border-radius-7px bg-gray-400 opacity-50 text-white'
									required
									/>
							</span>
							<span className='w-full'>
								<label htmlFor='nombre' className='text-12pt text-white'>
									Nombre del cliente*
								</label>
								<input
									value={`${this.state.usuario?.nombre || ""} ${this.state.usuario?.apellido_pat || ""} ${this.state.usuario?.apellido_mat || ""}`}
									className='w-full text-12pt border-radius-7px bg-gray-400 opacity-50 text-white'
									required
									/>
							</span>
							<span className='w-full'>
								<label htmlFor='nombre' className='text-12pt text-white'>
									Correo electronico*
								</label>
								<input
									value={this.state.usuario?.correo || ""}
									className='w-full text-12pt border-radius-7px bg-gray-400 opacity-50 text-white'
									required
									/>
							</span>
							<span className='w-full'>
								<label htmlFor='nombre' className='text-12pt text-white'>
									Numero alternativo de contacto
								</label>
								<input
									value={this.state.usuario?.telefono2 || ""}
									className='w-full text-12pt border-radius-7px bg-gray-400 opacity-50 text-white'
									/>
							</span>
							

						</div>
					</div>
					{!this.state.bloquearBotones && (
						<div className='flex items-center justify-center  md:gap-5 gap-5 pt-5 pb-5'>
							<button type="submit" className="focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer">
								Guardar
							</button>
							<button type="button" onClick={() => this.cerrarModal()} className="focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer">
								Cancelar
							</button>
						</div>
					)}
					{this.state.bloquearBotones && (
						<div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5 font-bold mb-10 text-2xl'>
							<img className="w-10 h-10" src={ImgCargando} alt=">"/> Guardando informacion ...
						</div>
					)}
					</form>
				</section>
				<div className={`${this.state.showModalClientes ? "" : "hidden"} z-50`}>
					<FormClienteModal cerrarModal={this.cerrarModalClientes} retornarCliente={this.guardarUsuarioEnState}/>
				</div>
			</div>
		)
	}
}

export default withRouter(PortabiliadModal);