/** @format */

import axios from "axios"
import GeneralService from 'services/GeneralService';

import { APIURL } from "./Host"

class AdministrativoService {

	Total_Administrativos() {
		return axios.get(APIURL + "/total_administrativos")
	}
	insertNuevoAdministrativo(administrativo) {
		return axios.post(APIURL + "/administrativo/insert", administrativo)
	}
	// listAdministrativos(id_admin) {
	// 	console.log("Entrando al nuevo list IDRecbido"+ id_admin);
	// 	return axios.post(APIURL + `/administrativo/list?id=${id_admin}`)
	// }
	listAdministrativos(id_admin) {
		console.log("ID recibido Service****"+id_admin);
		return axios.post(APIURL + "/administrativo/list/"+id_admin);
	}

	updateAdministrativo(data) {
		return axios.put(APIURL + "/administrativo/update", data)
	}

	updateStatus(data) {
		return axios.put(APIURL + "/administrativo/update/status", data)
	}

	AdministrativoById(id_admin){
		return axios.get(APIURL+"/administrativo_by_id/"+id_admin);
	}
	ListaRegisterBy(rol){
		return axios.post(APIURL+"/socioslista_Registerby_ID",rol);
	}

	obtenerUsuarioConekta(datos){
		return axios.post(APIURL+"/obtener_usuario_conekta/",datos);
	}

	autorizacion(userStr){
		return axios.post("https://panel.playcellshop.com:7443/conekta-1.0/api/conekta/login", userStr);
	}

	registrar_userjwt(bearer,user) {
		return axios.post("https://panel.playcellshop.com:7443/conekta-1.0/api/conekta/registrar_userjwt",user,{
			headers: {
			"Content-type": "application/json; charset=UTF-8",
			Authorization: 'Bearer ' + bearer
		}});
	}

	token(bearer) {
		return axios.post("https://panel.playcellshop.com:7443/conekta-1.0/api/conekta/token_pago",null,{
			headers: {
			"Content-type": "application/json; charset=UTF-8",
			Authorization: 'Bearer ' + bearer
		}});
	}

	obtener_llave(bearer) {
		return axios.get("https://panel.playcellshop.com:7443/conekta-1.0/api/conekta/obtener_llave",{
			headers: {
			"Content-type": "application/json; charset=UTF-8",
			Authorization: 'Bearer ' + bearer
		}}) 
	}

	realizar_pago(datos) {
		return axios.post(APIURL+"/realizar_pago/",datos);
	}

	realizarLineaPago(datos) {
		return axios.post(APIURL+"/realizar_linea_pago/",datos);
	}

	agregarPagoBD(datas) {
		return axios.post(APIURL + "/agregar_pago_bd", datas)
	}

	obtenerInformacionUsuario(info) {
		return axios.post(APIURL + "/obtener_informacion_usuario", info)
	}

	loginPlaycel() {
		return axios.post("https://playcell.live.bssmovil.com/auth/login",{
			email : "PLAYCELLEUROAMERICANO@GAIL.COM",
			//email : "saarjuma@gmail.com",
			password : "playcell.-22F" 
			//password : "Juanmanu152.-" 
		});
	}

	obtenerPaquetesPlaycel(info) {
		return axios.get("https://playcell.live.bssmovil.com/partners/"+(info.partner_id !== undefined ? info.partner_id : 0)+"/offers",{
			headers: {
				Accept: "application/json",
				Authorization: 'Bearer ' + (info.token !== undefined ? info.token : "")
			} 
		});
	}
	
	validarTelefonoRecarga(brer,telefonoRec) {
		try {
			axios.get("https://playcell.live.bssmovil.com/profile/"+(telefonoRec !== undefined ? telefonoRec : 0)+"/msisdn",{
				headers: {
					"Content-type": "application/json",
					Accept: "application/json",
					Authorization: 'Bearer ' + (brer !== undefined ? brer : "")
				} 
			}).then(response=>{
				return response;
		
			}).catch(error=>{
				return {status : 400};
			});
		} catch (error) { return {status : 400}; }
	}
	
	realizarRecargaPlaycell(info) {
		console.log("Entrando al service de recargas"+info);
		return axios.post("https://playcell.live.bssmovil.com/recharges",{
			"identifier_value" : (info.telefonoRecargaSi !== undefined ? info.telefonoRecargaSi : ""),
			"identifier_type" : "msisdn",
			"offer_id" : parseInt(info.offer_id !== undefined ? info.offer_id : ""),
			"schedule_date" : "",
			"pos_id":""
		},{
			headers: {
				Accept: "application/json",
				Authorization: 'Bearer ' + (info.brer !== undefined ? info.brer : "")
			} 
		});
	}

	enviarCorreoLineaPago(info) {
		return axios.post(APIURL + "/enviar_correo_linea_pago", info)
	}

	enviarWhatsappLineaPago(telefono, monto, lineaPago){
		return axios.post("https://graph.facebook.com/v16.0/108793108848468/messages",{
			"messaging_product": "whatsapp",
			"preview_url": true,
			"to": "52"+telefono,
			"type": "text",
			"template": {
				"name": "hello_world",
				"language": {
					"code": "us_US"
				}
			},
			"text": {
				"preview_url": true,
				"body": "Hola!, acceda a este enlace "+lineaPago+" para que pueda realizar su pago de la recarga de $"+monto+", Saludos."
			}
		},{
			headers: {
				"Content-Type": "application/json",
				Authorization: 'Bearer EAAMgGQ62GNEBAJ2yVKAhq1si45fSCxhqMuqNEhfXMWZCA6VZCwcvwmwhZAyGpSNImZB1c8DF3OUjQALWaXRhVPt16Trhp7tiLyQbmJMr7veZAkbu8UIKdNfnoYlTz2Yo1d1GZBJWJt2989K0RmslQgzhgWdAKf7tVBjArQFjpOPlNFXnQ9yUZB4KHakE7v0JJG6DLPwrxmqdnpSWBES0OO4'
			} 
		});
	}

	obtenerLineasPago(info) {
		return axios.post(APIURL + "/obtener_lineas_pagos", info);
	}

	obtenerUnaLineaPago(info) {
		return axios.post(APIURL + "/obtener_una_linea_pago", info);
	}
	
	recargarSaldoCocobel(info) {
		return axios.get("https://playcell.live.bssmovil.com/partners/300",{
			headers: {
				Accept: "application/json",
				Authorization: 'Bearer ' + (info.brer !== undefined ? info.brer : "")
		}}) 
	}

	validarImei(imei,token) { 
		return axios.get("https://playcell.live.bssmovil.com/imei/"+(imei !== undefined ? imei : 0)+"/check",{
		 	headers: {
		 		Accept: "application/json",
		 		Authorization: 'Bearer ' + (token !== undefined ? token : "")
		 	} 
		 });
	}

	turboShooting(telefono,token) { 
		return axios.get("https://playcell.live.bssmovil.com/profile/"+(telefono !== undefined ? telefono : 0)+"/msisdn",{
		 	headers: {
		 		Accept: "application/json",
		 		Authorization: 'Bearer ' + (token !== undefined ? token : "")
		 	} 
		 }).then(response => {
			response.data.throttling.throttlings.map(throttling => {
				throttling.availableAmt=GeneralService.bytesToMegabytes(throttling.availableAmt);
				throttling.initialAmt=GeneralService.bytesToMegabytes(throttling.initialAmt);
				return throttling;
			 }
			 );
			 response.data.purchasedOfferings.offers.map(offer => {
				if(offer.serviceType=='Datos'){
					offer.availableAmt=GeneralService.bytesToMegabytes(offer.availableAmt);
				    offer.initialAmt=GeneralService.bytesToMegabytes(offer.initialAmt);
				}
				return offer;
			 }
			 );
			return response
		  })
	}


}

export default new AdministrativoService()
