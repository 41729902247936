import { Fragment } from "react";
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom"
import AdministrativoService from "services/AdministrativoService"
import clientes from "../images/prospecto_white.png"
import recarga_140 from "../images/140.png"
import recarga_300 from "../images/300.png"
import recarga_420 from "../images/420.png"
import recarga_550 from "../images/550.png"
import sucursales from "../images/chain-store.png"
import swal from 'sweetalert2';
import ImgCargando from "../images/icono_cargando.svg"
import axios from "axios"

const { Component } = require("react-image-crop");

//const { Client } = require('whatsapp-web.js');

class Recargas extends Component{

	constructor(props) {
		super(props);
		this.state = {
			mostrarModalPagar : false,
			username : "",
			password : "",
			usuario : "",
			pass : "",
			montoPagar : 0,
			bearer : "",
			id_usuario : sessionStorage.getItem("id"),
			checkout_id : 0, 
			token : "",
			public_key : "",
			errorMessage : "",
			listaPaquetes: [],
			telefonosUsuario : [],
			telefonoRecarga : "",
			telefonoRecarga2 : "",
			telefonoRecargaSi : "",
			mostrarModalTelefono : false,
			disableBotonesModal : false,
			obteniendoInformacion : true,
			brer : "",
			cargandoModalbtn: false,
			offerId : 0,
			listaPaquetes : [],
			obteniendoInformacion : false,
			brer : "",
		};
	}

	componentDidMount() {
		
		//const { Client } = require('whatsapp-web.js');
		// const client = new Client();

		// client.on('qr', (qr) => {
		// 	console.log('QR RECEIVED', qr);
		// });

		// client.on('ready', () => {
		// 	console.log('Client is ready!');
		// });

		// client.initialize();
		
		

		
		AdministrativoService.obtenerInformacionUsuario({idUsu: this.state.id_usuario}).then((res) => {
			if (res.status===200){
				if (res.data !== undefined){
					this.setState({
						telefonosUsuario: (res.data[0] !== undefined ? res.data[0] : []),
						telefonoRecarga : (res.data[0] !== undefined ? (res.data[0].Telefono1 !== "" ? res.data[0].Telefono1 : (res.data[0].Telefono2 !== "" ? res.data[0].Telefono2 : "otro")) : "otro")
					},()=>{
						AdministrativoService.loginPlaycel().then((res1) => {
							if (res1.status===200){
								AdministrativoService.obtenerPaquetesPlaycel(res1.data).then((res2) => {
									if (res2.status===200){

										this.setState({listaPaquetes : res2.data, obteniendoInformacion : false, brer : (res1.data.token !== undefined ? res1.data.token : "")},()=>{
											
											console.log("BEARER: ",this.state.brer);


										});
									} else {
										swal.fire({
											icon: 'error',
											title: 'Error',
											text: "No se pudo obtener los paquetes de Playcel",
										});
									}
								});
							} else {
								swal.fire({
									icon: 'error',
									title: 'Error',
									text: "No se pudo concetar con el sistema de Playcel",
								});
							}
						});
					});
				} else {
					swal.fire({
						icon: 'error',
						title: 'Error',
						text: "No se pudo obtener la información del usuario",
					});
				}
			} else {
				swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "No se pudo obtener la información del usuario",
                });
			}
		});
	}

	validarTelefonoRecarga=()=>{
		let tthis = this;
		this.setState({disableBotonesModal : true, cargandoModalbtn: true},()=>{
			let telefonoRec = (this.state.telefonoRecarga!=="otro"?this.state.telefonoRecarga:this.state.telefonoRecarga2);
			if (telefonoRec!==""){
				axios.get("https://playcell.live.bssmovil.com/profile/"+(telefonoRec !== undefined ? telefonoRec : 0)+"/msisdn",{
					headers: {
						"Content-type": "application/json",
						Accept: "application/json",
						Authorization: 'Bearer ' + (this.state.brer !== undefined ? this.state.brer : "")
					} 
				}).then(res=>{
					if (res.status===200){
						if (res.data.mvno !== undefined ? (res.data.mvno.beId!==undefined?parseInt(res.data.mvno.beId)===205:false) : false){
							if (res.data.subscriber !== undefined ? (res.data.subscriber.status!==undefined?(res.data.subscriber.status.status!==undefined?res.data.subscriber.status.status==="Active":false):false) : false){
								tthis.setState({telefonoRecargaSi: telefonoRec}, ()=>{
									tthis.abrirModalConekta();
								});
							} else {
								swal.fire({
									icon: 'error',
									title: 'Error',
									text: "El numero a recargar esta inactivo, consulte a soporte técnico",
								});
								this.setState({disableBotonesModal : false, cargandoModalbtn: false});
							}
						} else {
							swal.fire({
								icon: 'error',
								title: 'Error',
								text: "El número proporcionado no es operado por Playcell",
							});
							this.setState({disableBotonesModal : false, cargandoModalbtn: false});
						}
					} else {
						swal.fire({
							icon: 'error',
							title: 'Error',
							text: "Error al validar el número teléfonico",
						});
						this.setState({disableBotonesModal : false, cargandoModalbtn: false});
					}
				}).catch(error=>{
					swal.fire({
						icon: 'error',
						title: 'Error',
						text: "Error al validar el número teléfonico, El número proporcionado no es operado por Playcell.",
					});
					this.setState({disableBotonesModal : false, cargandoModalbtn: false});
				});
			} else {
				swal.fire({
					icon: 'error',
					title: 'Error',
					text: "Falta escribir un número telefonico correcto",
				});
				this.setState({disableBotonesModal : false});
			}
		});
	}

	abrirModalConekta=()=>{
		AdministrativoService.obtenerUsuarioConekta({idUsu: this.state.id_usuario}).then((res) => {
			if (res.status===200){
				if (res.data.length === 2){
					this.setState({
						username: res.data[0][0].usu,
						password: res.data[0][0].pass,
						usuario: res.data[1][0].Username,
						pass: res.data[1][0].Password,
					},() => {
						const user = {
							username: this.state.username,
							password: this.state.password
						};
						const userStr = JSON.stringify(user);
						
						AdministrativoService.autorizacion(userStr).then((res2) => {
							let bearer = res2.headers.authorization.split("Bearer ")[1];
							this.setState({ bearer: res2.headers.authorization.split("Bearer ")[1] });
							const user_data = {
								username: this.state.usuario,
								password: this.state.pass,
								id_usuario: this.state.id_usuario
							};
							const userStrdata = JSON.stringify(user_data);
							
							AdministrativoService.registrar_userjwt(bearer, userStrdata).then((resp3) => {
								AdministrativoService.token(bearer).then((resp3) => {
									let checkout_id = resp3.data.checkout.id;
									let token_id = resp3.data.id;
									this.setState({ checkout_id: checkout_id, token: token_id });
									AdministrativoService.obtener_llave(bearer).then((resp4) => {
										var encodedStringAtoB = resp4.data;
										var decodedStringAtoB = atob(encodedStringAtoB);
										this.setState({public_key: decodedStringAtoB, disableBotonesModal : false, cargandoModalbtn: false, mostrarModalTelefono: false, mostrarModalPagar: true},()=>{
											if (this.state.mostrarModalPagar) {
												this.conektaframe();
											}
										});
									}, error => { });
								}, error => { });
							}, error => { });
						}).catch(error => {
							this.setState({ errorMessage: error.toString() });
						});
					});
				}
			}
		});
	}

	conektaframe() {
        const $this = this;
        let checkout = this.state.checkout_id;
        let key = this.state.public_key;
        window.ConektaCheckoutComponents.Card({
            targetIFrame: "#conektaModal",
            allowTokenization: true,
            checkoutRequestId: checkout, 
            publicKey: key, 
            options: {
                styles: {
                    inputType: 'rounded',
                    buttonType: 'rounded',
                    states: {
                        empty: {borderColor: '#FFAA00'},
                        invalid: { borderColor: '#FF00E0'},
                        valid: { borderColor: '#0079c1' }
                    }
                },
                languaje: 'es',
                button: { colorText: '#ffffff', backgroundColor: '#301007' },
                iframe: { colorText: '#65A39B', backgroundColor: '#FFFFFF' }
            },
            onCreateTokenSucceeded: function (token) {
				//if ((token.id+"").trim() === ($this.state.token+"").trim()) {
					$this.crearPago(token.id);
				//}
            },
            onCreateTokenError: function (error) { console.log(error); }
        });
    }

	crearPago(token2) {
		let datas = {
            token_conekta: token2,
            checkoutid: this.state.checkout_id,
            publicKey: this.state.public_key,
            id_usuario: this.state.id_usuario,
            total_pago: this.state.montoPagar,
			bearer: this.state.bearer, 
        }
        AdministrativoService.realizar_pago(datas).then((res) => {
			let respuesta = res.data.object;
            if (respuesta === "error") {
				this.setState({mostrarModalPagar : false});
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.data.details[0].message,
                });
                this.goBack();
            }
			if (respuesta === "order") {
				let datos_pagos = {
                    livemode: res.data.livemode,
                    amount: res.data.amount,
                    object: res.data.object,
                    payment_status: res.data.payment_status,
                    order_id: res.data.id,
                    charger_id: res.data.charges.data[0].id,
                    currency: res.data.charges.data[0].currency,
                    account_type: res.data.charges.data[0].payment_method.account_type,
                    brand: res.data.charges.data[0].payment_method.brand,
                    country: res.data.charges.data[0].payment_method.country,
                    exp_month: res.data.charges.data[0].payment_method.exp_month,
                    exp_year: res.data.charges.data[0].payment_method.exp_year,
                    last4: res.data.charges.data[0].payment_method.last4,
                    name_card: res.data.charges.data[0].payment_method.name,
                    type_card: res.data.charges.data[0].payment_method.type,
                    charges_status: res.data.charges.data[0].status,
                }
                let obj_unidos = Object.assign(datas, datos_pagos);
                AdministrativoService.agregarPagoBD(obj_unidos).then((response) => {
                    if (response.data == 1){                         
						var config = {
							method: 'post',
							maxBodyLength: Infinity,
							url: 'https://playcell.live.bssmovil.com/recharges',
							headers: { 
								'Content-Type': 'application/json', 
								'Accept': 'application/json', 
								'Authorization': 'Bearer ' + this.state.brer
							},
							data : {
								"identifier_value": this.state.telefonoRecargaSi+"",
								"identifier_type": "msisdn",
								"offer_id": parseInt(this.state.offerId),
								"schedule_date": "",
								"pos_id": ""
							}
						};
						axios(config).then(function (res45) {
							if (res45.status===200){
								if (res45.data !== undefined){
									this.setState({mostrarModalPagar : false});
									swal.fire({
										icon: 'success',
										title: 'Realizado',
										text: "La recarga se realizó exitosamente",
									});
								}
							}
						}).catch(function (error45) {
							swal.fire({
								icon: 'error',
								title: 'Error',
								text: "No se pudo realizar la recarga, consulte a soporte técnico",
							});
						});
                    } else {
                        this.setState({mostrarModalPagar : false});
						swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Error al procesar la recarga',
                        });
                    }
                });
            }
			
        });
    }

	crearLineaPago() {
		let datas = {
			monto_pagar : this.state.montoPagar,
			telefono : this.state.telefonoRecargaSi,
            checkoutid: this.state.checkout_id,
            publicKey: this.state.public_key,
            id_usuario: this.state.id_usuario,
            total_pago: this.state.montoPagar,
			bearer: this.state.bearer, 
        }
        AdministrativoService.realizarLineaPago(datas).then((res) => {
			let respuesta = res.data.object;
            if (respuesta === "error") {
				this.setState({mostrarModalPagar : false});
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.data.details[0].message,
                });
                //this.goBack();
            }
			if (respuesta === "checkout") {
				let datosInfo = {
					name : res.data.name,
					email : res.data.metadata.email,
                    url : res.data.url
                }
				AdministrativoService.enviarCorreoLineaPago(datosInfo).then((response) => {
					this.setState({mostrarModalPagar : false});
					if (response){
						AdministrativoService.enviarWhatsappLineaPago(this.state.telefonoRecargaSi, this.state.montoPagar, res.data.url).then((res11) => {
							if (res11.status===200){
								swal.fire({
									icon: 'success',
									title: 'Enviado correctamente',
									text: "La linea de pago se le envió a su correo electrónico y por mensaje de whatsapp.",
								});
							} else {
								swal.fire({
									icon: 'error',
									title: 'Error',
									text: "No se pudo obtener la información del usuario",
								});
							}
						});
					} else {
						swal.fire({
							icon: 'error',
							title: 'Error',
							text: "Error al enviar la linea de pago a su correo electrónico.",
						});
					}
                });
            }
        });
    }

    render(){
        return(
            <div>
				<section className=''>
					<div className="flex items-center justify-end mx-10 mt-5 mb-5">
						<div className="ml-5">
							<Link to="/app" className="inline-flex items-center text-sm text-white dark:text-gray-400 ">
							<svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
								 Home
							</Link>
						</div>
						<div className="ml-5">
							<Link to="/app/InvPrincipal" className="inline-flex items-center text-sm text-gray-200 dark:text-gray-400 ">
							<svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
								Recargas
							</Link>
						</div>
					</div>


					<div className='text-center font-bold text-white text-3xl uppercase tracking-widest title-section'>
					NUEVA RECARGA
					</div>
					<div>
						{false && (
							<button
								className='mr-1 bg-color1 hover:bg-blue-900 text-white font-bold border-b-4 border-blue-700 hover:border-blue-500 hover:bg-red focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 background-transparent'
								title='Regresar'>
								<NavLink
									to='/app/casa'
									className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-1 py-1 bg-blue hover:bg-red focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110'>
									<svg
										className='h-6 w-6 text-white'
										viewBox='0 0 24 24'
										fill='none'
										stroke='currentColor'
										strokeWidth='2'
										strokeLinecap='round'
										strokeLinejoin='round'>
										<polygon points='19 20 9 12 19 4 19 20' />{" "}
										<line x1='5' y1='19' x2='5' y2='5' />
									</svg>
								</NavLink>
							</button>
						)}
					</div>
					<div className=' px-2 md:px-10 pt-4 md:pt-3  overflow-y-auto '>
						<div className='grid grid-cols-3 gap-10'>
							{this.state.listaPaquetes.length>0 ? (
								this.state.listaPaquetes.map((item, i)=>{
									if (item.commercial_name.trim().slice(-7) === "Recarga"){
										return <div key={i} className=' shadow-2xl rounded-md bg-secondary_2  relative py-5'>
											<div className="absolute w-full top-3 h-10 flex justify-end items-center">
												<div className="w-5 h-5 bg-activo rounded-full mr-7 border"></div>
											</div>
											<div className='flex justify-center py-2'>
												<h1 className='text-1xl sm:text-4xl font-black counter text-center'>
													{item.commercial_name}
												</h1>
											</div>
											<h1 className='text-2xl flex justify-center' style={{"textDecoration": "line-through"}}>
												Precio normal: {item.retail_price}
											</h1><br/>
											<h1 className='text-2xl flex justify-center'>
												Precio especial: {item.price}
											</h1>
											<div className='flex justify-center pt-3'>
												<button className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 ' onClick={()=>{ this.setState({montoPagar : item.price, mostrarModalTelefono : true, offerId : item.id, telefonoRecarga2 : "", telefonoRecargaSi : "" }); }}>
													<p className='text-sm font-medium leading-none text-white text-center'>
														Pagar
													</p>
												</button>
											</div>
										</div>;
									}
								})
							) : (this.state.obteniendoInformacion ? (
								<div className="col-span-3 relative flex justify-center items-center w-full font-bold text-2xl text-center text-white" style={{"height":"60vh"}} >Obteniendo...</div>
							) : (
								<div className="col-span-3 relative flex justify-center items-center w-full font-bold text-2xl text-center text-white" style={{"height":"60vh"}} >No hay registros</div>
							))}

						</div>
					</div>

				</section>

				{this.state.mostrarModalPagar && (
					<Fragment>
						<div className="overflow-y-hidden">
							<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
								<div className='relative w-auto my-6 mx-auto max-w-2xl z-20'>
									<div className='rounded-3xl relative flex flex-col w-full outline-none focus:outline-none shadow-sm2 bg-white pt-4'>
										<div className=' rounded-t-3xl'>
											<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Recarga de ${this.state.montoPagar}<br/>Detalles de la tarjeta</p>
										</div>
										<div className='flex justify-center pt-3'>
											<button className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 ' onClick={()=>{ this.crearLineaPago(); }}>
												<p className='text-sm font-medium leading-none text-white text-center'>
													Línea de pago
												</p>
											</button>
										</div>
										<div className='relative flex-auto'>
											<div className='flex justify-center items-center pb-10'>
												<div id="conektaModal" className="w-full" style={{height:"500px"}}></div>
											</div>
										</div>
										<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
											<div>
												<button type="button" onClick={()=>{ this.setState({mostrarModalPagar:false});}} className="focus:outline-none focus:ring-2 w-auto bg-blue-700 hover:bg-blue-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" >Cancelar</button>
											</div>
										</div>
									</div>
								</div>
								<div onClick={ ()=> {this.setState({mostrarModalPagar:false}); }} className={' cursor-pointer opacity-25 fixed inset-0 bg-black'}></div>
							</div>
						</div>
					</Fragment>
				)}
				
				{this.state.mostrarModalTelefono && (
					<Fragment>
						<div className="overflow-y-hidden">
							<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
								<div className='relative w-auto my-6 mx-auto max-w-2xl z-20'>
									<div className='rounded-3xl relative flex flex-col w-full outline-none focus:outline-none shadow-sm2 bg-white pt-4'>
										<div className=' rounded-t-3xl'>
											<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Recarga de ${this.state.montoPagar}<br/>¿A que teléfono se le aplicará la recarga? </p>
										</div>
										<div className='relative flex-auto'>
											<div className='flex justify-center items-center pb-10 pl-10 pr-10'>
												
												<div className="grid grid-cols-1 w-full">
													<label htmlFor="telefonoRecarga" className="md:text-sm text-xs text-light font-semibold">
														Teléfono: <span className="text-red-500 text-xl">*</span>
													</label>
													<select
														id="telefonoRecarga"
														className="mt-1 rounded-lg p-2 border outline-none bg-white focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk"
														name="telefonoRecarga"
														defaultValue={this.state.telefonoRecarga}
														onChange={(e)=>{ this.setState({telefonoRecarga : e.target.value}); }}
														required
														disabled={this.state.disableBotonesModal}>
														{this.state.telefonosUsuario.Telefono1!=="" && (
															<option key={1} value={this.state.telefonosUsuario.Telefono1}>{this.state.telefonosUsuario.Telefono1}</option>
														)}
														{this.state.telefonosUsuario.Telefono2!=="" && (
															<option key={2} value={this.state.telefonosUsuario.Telefono2}>{this.state.telefonosUsuario.Telefono2}</option>
														)}
														<option key={0} value={"otro"}>Otro</option>
													</select>

													{this.state.telefonoRecarga==="otro" && (
														<div className="grid grid-cols-1 w-full">
															<label htmlFor="telefonoRecarga2" className="md:text-sm text-xs text-light font-semibold">Escribir teléfono:</label>
															<input
																className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk"
																type="tel"
																name="telefonoRecarga2"
																minLength="1"
																maxLength="20"
																placeholder="Teléfono al cual se le hará la recarga"
																value={this.state.telefonoRecarga2}
																onChange={(e)=>{ this.setState({telefonoRecarga2 : e.target.value}); }}
																pattern="[0-9+]{1,20}"
																disabled={this.state.disableBotonesModal} />
														</div>
													)}
												</div>
											</div>
										</div>
										<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
											{this.state.cargandoModalbtn && (
												<img className="w-10 h-10 mr-5" src={ImgCargando} alt=">"/>
											)}
											<div>
												<button type="button" className="focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 mr-3" disabled={this.state.disableBotonesModal} onClick={(e)=>{ this.validarTelefonoRecarga();}}>Validar teléfonos</button>
												<button type="button" onClick={()=>{ this.setState({mostrarModalTelefono:false});}} className="focus:outline-none focus:ring-2 w-auto bg-blue-700 hover:bg-blue-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" disabled={this.state.disableBotonesModal} >Cancelar</button>
											</div>
										</div>
									</div>
								</div>
								<div onClick={ ()=> {this.setState({mostrarModalTelefono:false}); }} className={' cursor-pointer opacity-25 fixed inset-0 bg-black'}></div>
							</div>
						</div>
					</Fragment>
				)}

			</div>
		
        )
    }
}
export default withRouter(Recargas) 