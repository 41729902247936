import axios from 'axios';
import { APIURL } from './Host';
import AdministrativoService from './AdministrativoService';


class CambioMSISDNService{

    loginPlayCell = async() =>{
        return await AdministrativoService.loginPlaycel().then((res1) => {
            return res1.data?.token;
		}).catch(err =>{
            return "";
        });
    }

    cambioMSSIDN = async(msisdn, nir) => {
        const brer = await this.loginPlayCell();
        const data = {
            nir,
            msisdn_type: "1",
        }
		return await axios.put(`https://playcell.live.bssmovil.com/subscribers/${msisdn}/msisdn`, data,{
			headers: {
				Accept: "application/json",
				Authorization: 'Bearer ' + brer,
		}}) 
	}

    checkImei = async(imeiOrICC, imeiBy) =>{
        const brer = await this.loginPlayCell();
		return await axios.get(`https://playcell.live.bssmovil.com/profile/${imeiOrICC}/${imeiBy}`,{
			headers: {
				Accept: "application/json",
				Authorization: 'Bearer ' + brer,
		}}) 
    }

    nirsAvailable = async() =>{
        const brer = await this.loginPlayCell();
		return await axios.get(`https://playcell.live.bssmovil.com/nirs/available?paginate=false`,{
			headers: {
				Accept: "application/json",
				Authorization: 'Bearer ' + brer,
		}}) 
    }

    ListarCambiosMSISDN(id_usuario){
        console.log("Dentro del service MSI id recibido:"+id_usuario);
        return axios.post(`${APIURL}/cambiosMSISDNList/${id_usuario}`);
    }

    CambioMSISDNById(idCambioMSISDN){
        return axios.post(`${APIURL}/cambiosMSISDN/${idCambioMSISDN}`);
    }

    InsertarCambioMSISDN(cambiosMSISDN){
        const data = {
            "folio": cambiosMSISDN.folio,
            "msisdn_anterior": cambiosMSISDN.msisdn_anterior,
            "msisdn_nuevo":cambiosMSISDN.msisdn_nuevo,
            "effective_date": cambiosMSISDN.effective_date,
            "register_by": cambiosMSISDN.register_by,
			"status": cambiosMSISDN.status,
        }
        return axios.post(`${APIURL}/cambiosMSISDN/insert`, data);
    }

    ActualizarStatusCambioMSISDN(cambiosMSISDN){
        const data = {
            "status": cambiosMSISDN.status,
            "id_cambio_msisdn": cambiosMSISDN.id_cambio_msisdn
        }
        return axios.post(`${APIURL}/cambiosMSISDN/update/status`, data);
    }


}

export default new CambioMSISDNService();