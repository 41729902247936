/** @format */

export default {
	SUPER_ADMIN: "SUPER_ADMIN",
	SOCIO_MARCA: "SOCIO_MARCA",
	SOCIO_COMERCIAL:"SOCIO_COMERCIAL",
	DISTRIBUIDOR:"DISTRIBUIDOR",
	ASESOR_TEL: "ASESOR_TEL",
	CLIENTE: "CLIENTE",
	INVERSOR:"INVERSOR"
	
}
