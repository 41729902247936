import React, { Component } from "react"
import image from "../images/error.png"
import { Link } from 'react-router-dom';

export default class Error404 extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div className='h-screen w-full flex relative justify-center items-center grid grid-cols-2 gap-4'>
				<img src={image} class="w-3/5 m-auto" />
				<div class="justify-center text-primary_2 w-full mb-10 pr-20" style={{"margin-left": "-50px"}}>
					<div class="text-center font-barlow text-bold text-5xl tracking-wide w-full">Error 404</div>
					<div class="text-center font-barlow text-bold text-3xl tracking-wide w-full ">Pagina no encontrada</div>
					<div class="text-center font-barlow text-bold text-3xl tracking-wide w-full ">Lo sentimos, no hemos podido encontrar la página que buscas</div>
					<br/><br/>
					<div class="w-full items-center justify-center text-center">
						<Link to='/app' rel="noopener noreferrer" className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-center justify-center px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 h-9' style={{"width":"120px"}}>
							<p className='text-sm font-medium leading-none text-white'><i className='fas fa-chevron-left'></i>  Regresar</p>
						</Link>
					</div>
				</div>
			</div>
		)
	}
}
