import React from 'react'
import InventarioService from '../services/InventarioService';
import ProveedoresService from '../services/ProveedoresService';
import SucursalesService from '../services/SucursalesService';
import sweetalert from "sweetalert2"

class ProveedorDetalles extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      proveedor: {
        direccion: "",
        email: "",
        nombre: "",
        proveedor_clave: "",
        rfc: "",
        status: 1,
        telefono: "",
      }
    }
  }

  componentDidMount(){
    if(this.props.proveedorSelect != 0) this.getProveedor(this.props.proveedorSelect); 
  }

  getProveedor = (idProveedor) =>{
    ProveedoresService.ProveedorById(idProveedor).then(response =>{
        this.setState({proveedor: response.data})
        console.log(response.data)
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
        console.log("Error en getProveedor ", err);
    })
  }


  actualizarProveedor = (event) =>{
    const usuario = sessionStorage.getItem("id");
    event.preventDefault();
    if(this.props.proveedorSelect == 0){
        const data = {
            ...this.state.proveedor, 
            register_by: usuario,

            status: 1
        };
        console.log(data)
        ProveedoresService.InsertarProveedor(data).then(response =>{
          console.log("Insertado con exito, ", response);
          this.props.getProveedores();
          this.props.cerrarModal();
        }).catch(err =>{
          sweetalert.fire({
            text: "Error al actualizar los datos",
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          console.log("Error en InsertarProveedor dentro de actualizarProveedor, ", err);
        });
    }else{
        const data = {...this.state.proveedor, lu_by: usuario};
        console.log(data)
        ProveedoresService.ActualizarProveedor(data).then(response =>{
          console.log("Actualizado con exito, ", response);
          this.props.getProveedores();
          this.props.cerrarModal();
        }).catch(err =>{
          sweetalert.fire({
            text: "Error al actualizar los datos",
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          console.log("Error en actualizarProveedor dentro de actualizarProveedor, ", err);
        });
    }
    
    
  }

  onChangeInput = (event, input ) =>{
    this.setState({proveedor:{...this.state.proveedor, [input]: event.target.value}})
  }

  cerrarModal = () =>{
    this.props.cerrarModal();
  }
  
  render(){
    return (
        <div className='fixed inset-0 z-50 flex flex-wrap justify-center'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            <form className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white overflow-auto'
                onSubmit={(event) => this.actualizarProveedor(event)}
            >

                <p className='text-xl text-center font-bold w-full'>{`${this.props.proveedorSelect == 0 ? "Nuevo proveedor" : "Detalles del proveedor"}`}</p>
                
                <div className='w-5/12 mt-2'>
                    <label>Nombre</label>
                    <input 
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.proveedor.nombre}
                      onChange={ event => this.onChangeInput(event, 'nombre') }
                      required
                      />
                </div>

                <div className='w-full mx-16 mt-2'>
                    <label>RFC</label>
                    <input 
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.proveedor.rfc}
                      onChange={ event => this.onChangeInput(event, 'rfc') }
                      required
                    />
                </div>
                <div className='w-5/12 mt-2'>
                    <label>Clave</label>
                    <input 
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.proveedor.proveedor_clave}
                      onChange={ event => this.onChangeInput(event, 'proveedor_clave') }
                      required
                    />
                </div>
                
                <div className='w-5/12 mt-2'>
                    <label>Telefono</label>
                    <input 
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.proveedor.telefono}
                      onChange={ event => this.onChangeInput(event, 'telefono') }
                    />
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Email</label>
                    <input 
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.proveedor.email}
                      onChange={ event => this.onChangeInput(event, 'email') }
                      type="email"
                    />
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Direccion</label>
                    <input 
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.proveedor.direccion}
                      onChange={ event => this.onChangeInput(event, 'direccion') }
                      />
                </div>

                <div className='w-full flex gap-2 justify-center items-center mt-5'>
                <button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Guardar</button>
                <button className='focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' onClick={() => this.cerrarModal()}>Cancelar</button>
                </div>

                
            </form>
            <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
        </div>
    )
  }
  
}

export default ProveedorDetalles;
