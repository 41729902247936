import React from 'react'

class InversionistasModal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      inversionistas: [],
      inversionistasResultantes: [],
      itemSelect: 0,
      key: '',
    }
  }

  cerrarModal = () =>{
    this.props.cerrarModalInversionistas();
  }

  componentDidMount(){
    this.setState({inversionistas: this.props.inversionistas, inversionistasResultantes: this.props.inversionistas})
  }


  itemSeleccionado(item){
    this.props.seleccionarInversionistas(item);
    this.cerrarModal();
  }

  buscarInversionista = (event) =>{
    let key = event.target.value || '';
    key = key.trim().toLowerCase();
    let keys = key.split(' ');
    keys = keys.filter(key => key != '');
    if(keys.length === 0) return this.setState({inversionistasResultantes: this.state.inversionistas});
    let inversionistaNombre, inversionistaAppPat, inversionistaAppMat, stringTemp = '';
    let inversionstaInfo = [];
    const inversionistasFiltrados = this.state.inversionistas?.filter(inversionista =>{
      inversionistaNombre = inversionista.nombre?.toLowerCase();
      inversionistaAppPat = inversionista.app_paterno?.toLowerCase();
      inversionistaAppMat = inversionista.app_materno?.toLowerCase();
      inversionstaInfo = [inversionistaNombre, inversionistaAppPat, inversionistaAppMat];
      stringTemp = '';
      for(let i = 0; i < keys.length; i++){
        for(let j = 0; j < inversionstaInfo.length; j++){
          stringTemp = inversionstaInfo[j].substring(0, keys[i].length);
          if(keys[i] == stringTemp) return true;
        }
      }
      return false;

    })
    this.setState({inversionistasResultantes: inversionistasFiltrados});
  }



  render(){
    return (
      <>
      <div className='fixed inset-0 z-50 flex flex-wrap justify-center'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            
            <div className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white overflow-auto'>
              <div className='w-full p-2'>
              <div className='w-5/12 mt-2'>
                    <label>Buscar: </label>
                    <input 
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.key}
                      onChange={ event => {this.buscarInversionista(event); this.setState({key: event.target.values})} }/>
                </div>
                <div className='flex flex-row gap-1'>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Nombre:</h4>
                </div>
                {
                  this.state.inversionistasResultantes.map((item, index) =>{
                    return (
                      <div key={item.id_inversionista} className='text-black flex flex-row gap-1 w-full mt-5 cursor-pointer hover:bg-gray-500 rounded-2xl bg-primary_2' onClick={ () => this.itemSeleccionado(item) }>
                        <p className={`w-full text-center mt-1 p-2 bg-transparent`} >
                          {`${item.nombre} ${item.app_paterno} ${item.app_materno}`}
                        </p>
                      </div>
                    )
                  })
                }
                
              </div>
            </div>

            <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
        </div>
      </>
      
    );  
  }
  
}

export default InversionistasModal;
