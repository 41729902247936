import axios from 'axios';
import { APIURL } from './Host';


class AprovisionamientoLogService{


    ListarAprovisionamientoLog(){
        return axios.post(`${APIURL}/aprovisionamientos_log`);
    }

    AprovisionamientoLogById(idAprovisionamientoLog){
        return axios.post(`${APIURL}/aprovisionamiento_log/${idAprovisionamientoLog}`);
    }

    AprovisionamientoLogByUsuarioId(idUsuario){
        return axios.post(`${APIURL}/aprovisionamiento_log/byUsuarioId/${idUsuario}`);
    }

    InsertarAprovisionamientoLog(aprovisionamiento_log){
        const data = {
            "usuario_id": aprovisionamiento_log.usuario_id,
            "saldo": aprovisionamiento_log.saldo,
            "register_by": aprovisionamiento_log.register_by,
            "status": aprovisionamiento_log.status,
        }
        return axios.post(`${APIURL}/aprovisionamiento_log/insert`, data);
    }

    ActualizarAprovisionamientoLog(aprovisionamiento_log){
        const data = {
            "usuario_id": aprovisionamiento_log.usuario_id,
            "saldo": aprovisionamiento_log.saldo,
            "register_by": aprovisionamiento_log.register_by,
            "lu_by": aprovisionamiento_log.lu_by,
            "status": aprovisionamiento_log.status,
            "id_aprovisionamiento_log": aprovisionamiento_log.id_aprovisionamiento_log,
        }
        return axios.post(`${APIURL}/aprovisionamiento_log/update`, data);
    }

    ActualizarStatusAprovisionamientoLog(aprovisionamiento_log){
        const data = {
            "status": aprovisionamiento_log.status,
            "id_aprovisionamiento_log": aprovisionamiento_log.id_aprovisionamiento_log
        }
        return axios.post(`${APIURL}/aprovisionamiento_log/update/status`, data);
    }

    ActualizarSaldoAprovisionamientoLog(aprovisionamiento_log){
        const data = {
            "saldo": aprovisionamiento_log.saldo,
            "usuario_id": aprovisionamiento_log.usuario_id
        }
        return axios.post(`${APIURL}/aprovisionamiento_log/update/saldo`, data);
    }

}

export default new AprovisionamientoLogService();