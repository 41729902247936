import React from 'react'

class ModalCantidad extends React.Component {

  constructor(props){
    super(props);
    this.state = {
        cantidad: this.props.cantidad,
    }
  }

  componentDidMount(){
    document.getElementById('inputCantidad').select();
  }
  cerrarModal = () =>{
    this.props.cerrarModal();
  }

  cambiarCantidad = (event) =>{
    event.preventDefault();
    this.props.cambiarCantidad(this.state.cantidad);
    this.props.cerrarModal();
  }




  render(){
    return (
      <>
      <div className='fixed inset-0 z-50 flex flex-wrap justify-center items-center'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            
            <form onSubmit={event => this.cambiarCantidad(event)} className='h-64 z-100 relative flex flex-col justify-center items-center bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white'>
                <label className='text-3xl mb-2'>Cantidad: </label>
                {/* TODO CAMBIAR EL VALUE */}
                <input type="number" id='inputCantidad' onChange={ event => this.setState({cantidad: event.target.value})} value={this.state.cantidad}  className={`rounded-lg p-2 text-black text-3xl text-center py-5`}/>
                <div className='flex gap-1 mt-3'>
                <button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Guardar</button>
                <button className='focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' onClick={() => this.cerrarModal()}>Cancelar</button>
                </div>
            </form>



            <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
        </div>
      </>
      
    );  
  }
  
}

export default ModalCantidad;

