import axios from 'axios';
import { APIURL } from './Host';


class ProveedoresService{


    ListarProveedores(){
        return axios.post(`${APIURL}/proveedores`);
    }

    ProveedorById(idProveedor){
        return axios.post(`${APIURL}/proveedores/${idProveedor}`);
    }

    InsertarProveedor(proveedor){
        const data = {
            "usuario_id": proveedor.usuario_id,
            "tipo_persona": proveedor.tipo_persona,
            "estado_fiscal":proveedor.estado_fiscal,
            "mun_fiscal":proveedor.mun_fiscal,
            "localidad_fiscal":proveedor.localidad_fiscal,
			"razon_social": proveedor.razon_social,
            "status":proveedor.status,
			"register_by":proveedor.register_by,
        }
        return axios.post(`${APIURL}/proveedores/insert`, data);
    }

    ActualizarProveedor(proveedor){
        const data = {
            "usuario_id": proveedor.usuario_id,
            "tipo_persona": proveedor.tipo_persona,
            "estado_fiscal":proveedor.estado_fiscal,
            "mun_fiscal":proveedor.mun_fiscal,
            "localidad_fiscal":proveedor.localidad_fiscal,
			"razon_social": proveedor.razon_social,
            "status":proveedor.status,
			"register_by":proveedor.register_by,
            "lu_by": proveedor.lu_by,
            "id_proveedor": proveedor.id_proveedor
        }
        return axios.post(`${APIURL}/proveedores/update`, data);
    }

    ActualizarStatusProveedor(proveedor){
        const data = {
            "status": proveedor.status,
            "id_proveedor": proveedor.id_proveedor
        }
        return axios.post(`${APIURL}/proveedores/update/status`, data);
    }

    listProveedores(id_admin) {
		console.log("****ID recibido Service****"+id_admin);
		return axios.post(APIURL + "/proveedores/list/"+id_admin);
	}

	updateProveedor(data) {
		return axios.put(APIURL + "/proveedor/update", data)
	}

	updateStatusProveedorUsuario(data) {
		return axios.put(APIURL + "/proveedor/update/status", data)
	}

	proveedorById(id_admin){
		return axios.get(APIURL+"/proveedorById/"+id_admin);
	}

    ListarTipoClientes(){
        return axios.post(`${APIURL}/tipo_personas`);
    }

}

export default new ProveedoresService();