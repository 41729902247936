import { InventarioModal } from 'components';
import RecargasModal from 'components/RecargasModal';
import React, { createRef } from 'react'
import AdministrativoService from 'services/AdministrativoService';
import AprovisionamientoLogService from 'services/AprovisionamientoLogService';
import DetallesVentasService from 'services/DetallesVentasService';
import InventarioService from 'services/InventarioService';
import SucUsRelacion from 'services/SucUsRelacion';
import UsuariosService from 'services/UsuariosService';
import VentasService from 'services/VentasService';
import sweetalert from "sweetalert2"
import ModalCantidad from './modalCantidad';
import ModalVenta from './modalVenta';
import ModalValidar from './modalValidar';
import PortabilidadModal from "../PortabilidadModal";
import ActivacionesService from 'services/ActivacionesService';
import ModalShoot from './modalShoot';
import swal from 'sweetalert2';
import { resolveOnChange } from 'antd/lib/input/Input';

class PuntoVentaPrincipal extends React.Component {

  constructor(){
    super();
    this.state = {
        key: '',
        inventarioResultate: [],
        inventario: [],
        sucursalId: 1,
        sucursalAsignada: false,
        modalInventario: false,
        modalCantidad: false,
        modalVenta: false,
        modalRecargas: false,
        modalValidar: false,
        modalShoot: false,
        esActivacion: false,
        showPortabilidadModal : false,
        modalActivaciones: false,
        itemsSeleccionados: [],
        itemSeleccionado: null,
        cantidadItemSeleccionado: 0,
        total: 0,
        usuario: {nombre: '', app_paterno: '', app_materno: ''},
        fecha: new Date(),
        folio: '',
        recargas: [],
        activaciones: [],
        aprovisionamientoLog: {},
        saldoFinal: null,
        infoLoginBssmovil: {},
        sucus:{},
        nombre_sucursal:"",
    }
  }

  

  componentDidMount(){
    let folio = this.getFolio();
    this.setState({folio})
    this.getUsuario(sessionStorage.getItem("id"));
    this.getSucursalesUsuario();
    this.getInventario();
    this.getAprovisionamientoLog();
    this.loginBssmovil();
  }

  loginBssmovil = async() =>{
		return AdministrativoService.loginPlaycel().then((res1) => {
			if (res1.status===200){ 
				this.setState({infoLoginBssmovil: res1.data})
        return res1.data;
			} else {
				sweetalert.fire({
					icon: 'error',
					title: 'Error',
					text: "No se pudo concetar con el sistema de Playcel",
				});
			}
		});
	}

  getInventario = () =>{
    InventarioService.ListarInventario({b:""}).then(response =>{
      this.setState({inventario: response.data})
      console.log(response.data);
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log('Error en listarInventario ', err)
    })
  }

  getSucursalesUsuario(){
    SucUsRelacion.ListarSucUsRelacionesByUsuario(sessionStorage.getItem("id")).then(response =>{
      console.log({sucus: response.data});
      console.log("id que se va en el mount///"+sessionStorage.getItem("id"));
      if(response.data?.length > 0){
        //this.setState({sucursalAsignada: true});
        this.setState({sucursalId: response.data[0].sucursal_id,nombre_sucursal: response.data[0].nombre_sucursal,sucursalAsignada: true})
        console.log("Suc del response////:"+this.state.nombre_sucursal);
      }else{
        this.setState({sucursalAsignada: false});
      }
    }).catch(err =>{
      this.setState({sucursalAsignada: false});
      console.log("Error en getSucursalesUsuario ", err);
    })
  }

  getFolio(){
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result= ' ';
    for ( let i = 0; i < 4; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    result += this.state.fecha.getTime().toString();
    return result;
  }

  getUsuario = (idUsuario) =>{
    UsuariosService.ObtenerDatosUsuarioPerfil(idUsuario).then(response =>{
        this.setState({usuario: response.data});
    }).catch(err => {
        console.log("Error en getUsuario, ", err);
    })
  }

  getAprovisionamientoLog = () =>{
    const usuario = sessionStorage.getItem('id');
    console.log("const usuario:"+usuario);
    AprovisionamientoLogService.AprovisionamientoLogByUsuarioId(usuario).then(responseAprov =>{
      this.setState({aprovisionamientoLog: responseAprov.data, saldoFinal: responseAprov.data?.saldo})
    }).catch(err => console.log("Error en getAprovisionamientoLog", err));
  }

  onChangeInput = (event) =>{
    const key = event.target.value.trim();
    this.buscarItem(key);
    this.setState({key: event.target.value})
  }

  buscarItem = async(key) =>{
    if(key.length === 0){
        this.setState({inventarioResultate: []});
    }else{
        InventarioService.InventarioLikeIdentificador(key).then(response =>{
            if(this.state.key.length != 0){
                console.log({inventarioResultate: response.data})
                this.setState({inventarioResultate: response.data});
            }
        }).catch(err =>{
            console.log("Error en onChangeInput, ", err);
        })
    }
  }

  onKeyUp = (event) =>{
    if(event.key == "Enter"){
        if(this.state.inventarioResultate.length > 0){
            this.agregarItem(this.state.inventarioResultate[0]);
        }else{
            this.abrirModalInventario();
        }
    }
  }
  abrirModalInventario = () =>{
    this.setState({modalInventario: true});
  }

  cerrarModalInventario = () =>{
    this.setState({modalInventario: false})
  }
  cerrarModalCantidad = () =>{
    this.setState({modalCantidad: false})
  }
  cerrarModalVenta = () =>{
    this.setState({modalVenta: false})
  }
  cerrarModalRecargas = () =>{
    this.setState({modalRecargas: false})
  }

  cerrarModalValidar = () =>{
    this.setState({modalValidar: false})
  }

  cerrarModalShoot = () =>{
    this.setState({modalShoot: false})
  }

  nuevaPortabilidad=()=>{
		this.setState({showPortabilidadModal : true});
	}

	cerrarPortabilidadModal = () =>{
		this.setState({showPortabilidadModal: false});
	}
  agregarItem = (inventario) =>{
    if(inventario) {
      const indexItem = this.state.itemsSeleccionados.findIndex(item =>{
          return item.item?.id_inventario === inventario?.id_inventario
      })
      console.log({indexItem})
      let nuevosItems = [];
      if(indexItem !== -1){
          nuevosItems = this.state.itemsSeleccionados.map((item, index) =>{
              if(index === indexItem){
                  return {...item, cantidad: ++item.cantidad};
              }else return item;
          })
      }else{
          nuevosItems = [...this.state.itemsSeleccionados ,{cantidad: 1, item: inventario}];
      }
      this.calcularTotal(nuevosItems);
      this.setState({itemsSeleccionados: nuevosItems})
    }
  }

  calcularTotal = (itemsSeleccionados) =>{
    let total = 0;
    itemsSeleccionados.forEach(item =>{
        total += item.cantidad * item.item?.precio_venta;
    })
    this.setState({total});
  }

  abrirModalCantidad = (index, cantidad) =>{
    this.setState({modalCantidad: true, itemSeleccionado: index, cantidadItemSeleccionado: cantidad});
  }
  abrirModalVenta = () =>{
    if(this.state.itemsSeleccionados.length > 0)  this.setState({modalVenta: true});
  }
  abrirModalRecargas = (esActivacion) =>{
    this.setState({modalRecargas: true, esActivacion: esActivacion});
  }

  abrirModalValidar = () =>{
    this.setState({modalValidar: true});
  }

  abrirModalShoot = () =>{
    this.setState({modalShoot: true});
  }
  cambiarCantidad = (cantidad) =>{
    let nuevosItems = this.state.itemsSeleccionados.map((item, index) =>{
        if(index === this.state.itemSeleccionado) return {...item, cantidad}
        else return item
    })
    this.calcularTotal(nuevosItems);
    this.setState({itemsSeleccionados: nuevosItems});
  }

  eliminarItem = (index) =>{
    let nuevasRecargas = [];
    let nuevasActivaciones = [];
    let nuevosItems = this.state.itemsSeleccionados.filter((item, i) =>{
      
      nuevasRecargas = this.state.recargas.filter(recarga =>{
        if(recarga.offerId == item.item?.identificador){
          this.setState({saldoFinal: this.state.saldoFinal + recarga.montoPagar})
          return false;
        }else{
          return true;
        }
      })
      nuevasActivaciones = this.state.activaciones.filter(activacion =>{
        if(activacion.offerId == item.item?.identificador){
          this.setState({saldoFinal: this.state.saldoFinal + activacion.montoPagar})
          return false;
        }else{
          return true;
        }
      })
      
      
      return i !== index;
    });
    
    this.calcularTotal(nuevosItems);
    this.setState({itemsSeleccionados: nuevosItems, recargas: nuevasRecargas, activaciones: nuevasActivaciones});
  }



  reiniciarVenta = () =>{
    sweetalert
      .fire({
        text: `Seguro quiere reiniciar la venta`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
            let folio = this.getFolio();
            this.setState({
                key: '',
                inventarioResultate: [],
                modalInventario: false,
                modalCantidad: false,
                modalVenta: false,
                modalValidar: false,
                modalShoot: false,
                itemsSeleccionados: [],
                itemSeleccionado: null,
                cantidadItemSeleccionado: 0,
                total: 0,
                fecha: new Date(),
                folio,
            });
        }
      });
    
  }

  cobrarVenta = (clienteId, tipoPagoId, referenciaVenta) =>{
    console.log("sucursal", this.state.sucursalId)
    sweetalert
    .fire({
      text: `Seguro quiere cerrar la venta`,
      showCancelButton:true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "red",
      allowOutsideClick: true,
      allowEscapeKey: true
    })
    .then(resultado => {
      if (resultado.isConfirmed){
        //TODO: Descomentar para agregar recargas y cambios de saldo
        this.realizarRecargas();
        let usuario = sessionStorage.getItem("id");
        let data = {
            folio: this.state.folio,
            cliente_id: clienteId,
            sucursal_id: this.state.sucursalId?.sucursal_id ? this.state.sucursalId?.sucursal_id : 1,
            tipo_pago_id: tipoPagoId,
            en_espera: 0,
            total: this.state.total,
            referencia: referenciaVenta,
            register_by: usuario,
            status: 1,
        }
          VentasService.InsertarVenta(data).then(response =>{
            this.state.itemsSeleccionados.forEach(item =>{
                let detalleVenta = {
                    inventario_id: item.item?.id_inventario,
                    venta_id: response.data?.id_venta,
                    cantidad: item.cantidad,
                    precio_unitario: item.item?.precio_venta,
                    importe: item.item?.precio_venta * item.cantidad,
                    register_by: usuario,
                    status: 1,
                }
                let itemActualizado = {
                  ...item.item,
                  stock_actual: item.item?.stock_actual - item.cantidad,
                }
                InventarioService.ActualizarInventario(itemActualizado).then(response =>{
                  
                }).catch(err =>{
                  console.log("Error al actualizar el item", err);
                })
                DetallesVentasService.InsertarDetallesVenta(detalleVenta).then(response =>{
                    console.log(response.data);
                }).catch(err =>{
                    console.log("Error en cobrarVenta ", err);
                })
            })
            let folio = this.getFolio();
            this.setState({
                key: '',
                inventarioResultate: [],
                modalInventario: false,
                modalCantidad: false,
                modalVenta: false,
                modalValidar: false,
                modalShoot: false,
                itemsSeleccionados: [],
                itemSeleccionado: null,
                cantidadItemSeleccionado: 0,
                total: 0,
                fecha: new Date(),
                folio,
            });
            console.log(response.data);
          }).catch(err =>{
            console.log("Error en cobrarVenta ", err);
            sweetalert.fire({
                text: "Error al realizar la operacion",
                allowOutsideClick: false,
                allowEscapeKey: false
              })
          })
      }
    });
  }

  agregarRecarga = (recarga) =>{
    console.log({recarga})
    let saldo = this.state.saldoFinal
    saldo = saldo - recarga.montoPagar;
    if(saldo > 0 && saldo != undefined){
      if(this.state.esActivacion){
        this.setState({activaciones: [...this.state.activaciones, recarga], saldoFinal: saldo});
      }else{
        this.setState({recargas: [...this.state.recargas, recarga], saldoFinal: saldo});
      }
      const inventario = this.state.inventario.find(inventario =>{
        return inventario.identificador == recarga.offerId
      });
      if(inventario){
        this.agregarItem(inventario);
      }else{
        sweetalert.fire({
          icon: 'error',
          title: 'Error',
          text: "No se ha encontrado la recarga en el inventario, agrega el registro de la recarga o verifica los identificadores",
        });  
      }
    }else {
      sweetalert.fire({
        icon: 'error',
        title: 'Error',
        text: "No se cuenta con el suficiente saldo, solicita aprovisionamiento",
    });
    }
  }

  realizarRecargas = () =>{
    let recargaData = {};
    let aprovisionamientoLogData = {};
    const usuario = sessionStorage.getItem('id');
    let saldoNuevo = this.state.aprovisionamientoLog?.saldo;

    this.state.recargas.forEach(recarga =>{
      saldoNuevo = saldoNuevo - recarga.montoPagar;
      if(saldoNuevo > 0 && saldoNuevo != undefined){
        recargaData = {
          telefonoRecargaSi: recarga.telefonoRec,
          offer_id: recarga.offerId,
          brer: this.state.infoLoginBssmovil?.token,
        }
        AdministrativoService.realizarRecargaPlaycell(recargaData).then(response =>{
          console.log("RecargaData"+recargaData);
          aprovisionamientoLogData = {
            saldo: saldoNuevo,
            usuario_id: usuario
          }
          swal.fire({
            icon: 'success',
            title: 'Realizado',
            text: "Recarga Exitosa",
          });
          AprovisionamientoLogService.ActualizarSaldoAprovisionamientoLog(aprovisionamientoLogData).then(response =>{
            console.log("Saldo actualizado");
          }).catch(err =>{
            console.log("Ha ocurrido un error al actualizar el saldo", err);
          });
        }).catch(err =>{
          console.log("Hubo un error al realizar la recarga", err);
          sweetalert.fire({
              icon: 'error',
              title: 'Error',
              text: "Hubo un error al realizar la recarga",
          });
        });
      }else{
        sweetalert.fire({
            icon: 'error',
            title: 'Error',
            text: "No se cuenta con el suficiente saldo, solicita aprovisionamiento",
        });
      }
    });
    this.realizarActivaciones(saldoNuevo);
      
  }

  realizarActivaciones = (saldoRestante) =>{
    let recargaData = {};
    let aprovisionamientoLogData = {};
    const usuario = sessionStorage.getItem('id');
    let saldoNuevo = saldoRestante;

    this.state.activaciones.forEach(activacion =>{
      saldoNuevo = saldoNuevo - activacion.montoPagar;
      if(saldoNuevo > 0 && saldoNuevo != undefined){
        recargaData = {
          identifier_value: activacion.telefonoRec,
          offerId: activacion.offerId,
          brer: this.state.infoLoginBssmovil?.token,
        }
        ActivacionesService.realizarActivacionBSSMovil(recargaData).then(response =>{
          if (response.status===201){
            if (response.data !== undefined){
              ActivacionesService.guardarRegistroActivacionBD(response.data).then((res1) => {
                sweetalert.fire({
                  icon: 'success',
                  title: 'Activacion realizada',
                  text: "Tu numero es: " + response.data?.msisdn,
                });
              });
            }
          }
          aprovisionamientoLogData = {
            saldo: saldoNuevo,
            usuario_id: usuario
          }
          AprovisionamientoLogService.ActualizarSaldoAprovisionamientoLog(aprovisionamientoLogData).then(response =>{
            console.log("Saldo actualizado");
          }).catch(err =>{
            console.log("Ha ocurrido un error al actualizar el saldo", err);
          });
        }).catch(err =>{
          console.log("Hubo un error al realizar la activación", err);
          sweetalert.fire({
              icon: 'error',
              title: 'Error',
              text: "Hubo un error al realizar la activación",
          });
        });
      }else{
        sweetalert.fire({
            icon: 'error',
            title: 'Error',
            text: "No se cuenta con el suficiente saldo, solicita aprovisionamiento",
        });
      }
    });
      
  }

  



  render(){

    if(!this.state.sucursalAsignada){
      return (
        <div className='bg-secondary_2 bg-opacity-30 text-white p-10' >
          <h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
              Punto de Venta
            </h1>
    
            <div className="flex justify-start mx-10 mt-4">
              <nav class="flex" aria-label="Breadcrumb">
                <ol class="inline-flex items-center space-x-1 md:space-x-3">
                  <li class="inline-flex items-center">
                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                      <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                      Home
                    </a>
                  </li>
                  
                  <li aria-current="page">
                    <div class="flex items-center">
                      <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                      <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Punto de venta</span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div>NO TIENE UNA SUCURSAL ASIGNADA, CONSULTE AL ADMINISTRADOR</div>
        </div>
      );

    }else{

      return (
        <div className='bg-secondary_2 bg-opacity-30 text-white p-10' >
          <h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
              Punto de Venta "{this.state.nombre_sucursal}"
            </h1>
    
            <div className="flex justify-start mx-10 mt-4">
              <nav class="flex" aria-label="Breadcrumb">
                <ol class="inline-flex items-center space-x-1 md:space-x-3">
                  <li class="inline-flex items-center">
                    <a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                      <svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                      Home
                    </a>
                  </li>
                  
                  <li aria-current="page">
                    <div class="flex items-center">
                      <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                      <span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Punto de venta</span>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
          <div className='h-11/12 w-full p-2'>
              {/* Seccion botones de acciones */}
              <section className='flex h-16 w-full justify-start items-center'>
                  <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirModalVenta() }>Cobrar</button>
                  <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirModalInventario() }>Buscar</button>
                  {/* <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => console.log("Prueba") }>Eliminar</button> */}
                  <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.reiniciarVenta() }>Reiniciar</button>
                  <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirModalRecargas(false) }>Agregar Recarga</button>
                  <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirModalRecargas(true) }>Activar numero</button>
                  <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.nuevaPortabilidad()}>Portar Número</button>
                  <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirModalValidar() }>validar IMEI</button>
                  <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirModalShoot() }>Trouble Shooting</button>
                  {/* <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => console.log("Prueba") }>Cambiar Cantidad</button> */}
              </section>
              {/* Seccion datos de venta */} 
              <section className='flex h-12 w-full justify-evenly items-center '>
                  <div>
                      <label className='mr-1'>Usuario: </label>
                      {/* TODO CAMBIAR EL VALUE */}
                      <input type="text" disabled value={this.state.usuario.nombre}  className={`rounded-lg p-2`}/>
                  </div>
                  <div>
                      <label className='mr-1'>Fecha: </label>
                      {/* TODO CAMBIAR EL VALUE */}
                      <input type="date" disabled value={`${this.state.fecha.getFullYear()}-${(this.state.fecha.getMonth() + 1).toString().length > 1 ? this.state.fecha.getMonth() + 1: "0" + (this.state.fecha.getMonth() + 1)}-${(this.state.fecha.getDate()).toString().length > 1 ? this.state.fecha.getDate() : "0" + this.state.fecha.getDate() + ""}`}  className={`rounded-lg p-2`}/>
                  </div>
                  <div>
                      <label className='mr-1'>Folio: </label>
                      <input type="text" disabled value={this.state.folio} className={`rounded-lg p-2`}/>
                  </div>
              </section>
              {/* Seccion buscador y descripcion de producto */}
              <section className='flex w-full gap-1 items-center'>
                  <div className='w-1/2 flex flex-col'>
                      <label className='mr-1 text-lg font-bold'>Identificador: </label>
                      {/* TODO CAMBIAR EL VALUE */}
                      <input type="search" list='listaItems' value={this.state.key}  className={`rounded-lg p-2 text-black text-xl z-10`} onChange={ event => this.onChangeInput(event)} onKeyUp={event => this.onKeyUp(event)}/>
                      <datalist id="listaItems" onInput={() =>console.log("hola")}>
                        {
                          this.state.inventarioResultate.map(item =>{
                            return(
                              <option key={item.id_inventario} value={item.identificador}/>
                            )
                          })
                        }
                      </datalist>
                  </div>
                  <div className='w-1/2 flex flex-col'>
                      <label className='mr-1 text-lg font-bold'>Descripcion: </label>
                      {/* TODO CAMBIAR EL VALUE */}
                      <input type="text" disabled  className={`rounded-lg p-2 text-xl `} value={this.state.inventarioResultate.length > 0 ? this.state.inventarioResultate[0]?.identificador: ''}/>
                  </div>
              </section>
              {/* Tabla de productos seleccionados */}
              <section className='mt-2 w-full border-2 border-white h-80 rounded-lg overflow-auto relative'>
                  <div className='w-full flex gap-1 mb-1 sticky top-0 z-39'>
                      <div className='sticky text-center bg-secondary_2 p-1 rounded-tl w-1/12'>
                          Cantidad
                      </div>
                      <div className='sticky text-center bg-secondary_2 p-1 w-6/12'>
                          Descripcion producto
                      </div>
                      <div className='sticky text-center bg-secondary_2 p-1 w-2/12'>
                          Precio Unitario
                      </div>
                      <div className='sticky text-center bg-secondary_2 p-1 rounded-tr w-2/12'>
                          Importe
                      </div>
                      <div className='sticky text-center bg-secondary_2 p-1 rounded-tr w-1/12'>
                          
                      </div>
                  </div>
                  {
                      this.state.itemsSeleccionados.map((item, index) =>{
                          return(
                              <div key={item.item?.id_inventario} className='w-full flex gap-1 mb-1'>
                                  <div className='sticky text-center bg-primary_2 p-1 py-2 rounded-tl w-1/12 text-black hover:bg-gray-400 cursor-pointer'
                                       onClick={(event) => this.abrirModalCantidad(index, item.cantidad)}>
                                      {item.cantidad}
                                  </div>
                                  <div className='sticky text-center bg-primary_2 p-1 py-2 w-6/12 text-black'>
                                      {item.item?.descripcion}
                                  </div>
                                  <div className='sticky text-center bg-primary_2 p-1 py-2 w-2/12 text-black'>
                                      {item.item?.precio_venta}
                                  </div>
                                  <div className='sticky text-center bg-primary_2 p-1 py-2 rounded-tr w-2/12 text-black'>
                                      {item.item?.precio_venta * item.cantidad}
                                  </div>
                                  <div className='sticky text-center bg-primary_2 p-1 py-2 rounded-tr w-1/12 text-black flex items-center justify-center'>
                                      <button title='Eliminar item' className='bg-red-500 rounded p-1 px-2 mr-2' onClick={ () => this.eliminarItem(index)} >D</button>
                                      <button title='Editar item' onClick={ () => this.abrirModalCantidad(index, item.cantidad) }><svg
                                                                          class='h-6 w-6 text-black'
                                                                          width='24'
                                                                          height='24'
                                                                          viewBox='0 0 24 24'
                                                                          xmlns='http://www.w3.org/2000/svg'
                                                                          fill='none'
                                                                          stroke='currentColor'
                                                                          stroke-width='2'
                                                                          stroke-linecap='round'
                                                                          stroke-linejoin='round'>
                                                                          {" "}
                                                                          <path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7' />{" "}
                                                                          <path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z' />
                                                                      </svg>
                                      </button>
                                  </div>
                              </div>
                          )
                      })
                  }
                  
                  
              </section>
              {/* Seccion total venta */}
              <section className='flex mt-2 w-full justify-evenly items-center'>
                  <div>
                      <label className='mr-1 text-4xl'>Total: </label>
                      {/* TODO CAMBIAR EL VALUE */}
                      <input type="text" disabled value={`$ ${this.state.total}`}  className={`rounded-lg p-2 text-4xl text-center h-20`}/>
                  </div>
              </section>
    
              {
              this.state.modalInventario && (
                <InventarioModal cerrarModal={this.cerrarModalInventario} cambiarItemInventario={this.agregarItem} inventario={this.state.inventario} />
              )
              }
              {
              this.state.modalCantidad && (
                <ModalCantidad cerrarModal={this.cerrarModalCantidad} cantidad={this.state.cantidadItemSeleccionado} cambiarCantidad={this.cambiarCantidad} />
              )
              }
              {
              this.state.modalVenta && (
                <ModalVenta cerrarModal={this.cerrarModalVenta} itemsSeleccionados={this.state.itemsSeleccionados} total={this.state.total} usuario={this.state.usuario} cobrarVenta={this.cobrarVenta} folio={this.state.folio}/>
              )
              }
              {
              this.state.modalRecargas && (
                <RecargasModal cerrarModal={this.cerrarModalRecargas} agregarRecarga={this.agregarRecarga} esActivacion={this.state.esActivacion}/>
              )
              }
              {
              this.state.modalValidar && (
                <ModalValidar cerrarModal={this.cerrarModalValidar}/>
              )
              }
              {this.state.showPortabilidadModal && (
					      <PortabilidadModal cerrarModal={this.cerrarPortabilidadModal}/>
				      )}
              {
              this.state.modalShoot && (
                <ModalShoot cerrarModal={this.cerrarModalShoot}/>
              )
              }
          </div>
        </div>
      );  
    }

  }
  
}

export default PuntoVentaPrincipal;
