import { Component, createRef } from "react"
import { NavLink, Link } from "react-router-dom"
import AdministrativoService from "services/AdministrativoService"
import VinculacionService from "services/VinculacionService"
import SweetAlert from "sweetalert2"
import Puestos_AdmonService from "services/Puestos_AdmonService"
import CatalogoDireccionService from "services/CatalogoDireccionService"
import { withRouter } from "react-router-dom"
import close from '../images/cancel.svg'
import ClientesService from "services/ClientesService"


class MisVinculaciones extends Component {
	refModalDet
	refModalAct
	buscador

	constructor(props) {
		super(props)
		this.refModalDet = createRef()
		this.refModalAct = createRef()
		this.buscador = ""
		this.state = {
			modalDetalles: false,
			modalActualizar: false,
			lAdmonTabla: [],
            modalVisible: false,
            inverSelect: 0,
			dn:9517160038
        }
            
	}
	cerrarModal = () =>{
        this.setState({modalVisible: false})
      }
    
	
	componentDidMount() {
		
		VinculacionService.ConsultabyMSISDN(this.state.dn);

		
	}
abrirDettallesVinculados(id_cliente){
    this.setState({modalVisible:true,inverSelect:id_cliente})
}

	render() {
		return (
			<>
				<div className="bg-secondary_2 text-white h-auto py-10 bg-opacity-50">
					
					<h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
						Mis Vinculaciónes
					</h1>

					<div className="flex justify-start mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
							</ol>
						</nav>
					</div>
                    
					<section>
						<div className="px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto" >
							<div className="w-full ">
								<div className="w-full text-sm grid grid-cols-11 text-gray-800 font-semibold">

								    <div className="font-semibold text-center col-span-3 ml-3 px-6 py-3 text-white">
										<div className="flex justify-start items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Número</label><br />
											</div>
										</div>
									</div>
                                    <div className="font-semibold text-center col-span-3 ml-3 px-6 py-3 text-white">
										<div className="flex justify-start items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Plan Áctivo</label><br />
											</div>
										</div>
									</div>
									<div className="font-semibold text-center col-span-3 ml-3 px-6 py-3 text-white">
										<div className="flex justify-center  items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest " htmlFor="">Fecha Última Compra</label><br />
												
											</div>
										</div>
									</div>

									<div className="font-semibold text-center col-span-2 ml-3 px-6 py-3 text-white">
										<div className="flex justify-center items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Estátus</label><br />
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
                    </section>
				</div>
			</>

		)
        {

        }
	}
}

export default withRouter(MisVinculaciones);