import React from 'react'
import sweetalert from "sweetalert2"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReportesService from 'services/ReportesService';
import SucUsRelacion from 'services/SucUsRelacion';

class ReportesPrincipal extends React.Component {

  constructor(props){
    super(props);
    this.state = {
		fecha_inicio: '',
		fecha_fin: '',
		filasReporte: [],
		filasReporteClientes: [],
		filasReporteSocios: [],
		totalVentas: "",
		tipoDeReporte: 1,
		sucursalId:1,
		nombre_sucursal:"",

    }
  }

  componentDidMount(){
	this.initDates();
	this.getSucursalesUsuario();
  }

  initDates = () =>{
	const dateNow = new Date();
	const dateString = `${dateNow.getFullYear()}-${this.fechaDosDigitos(dateNow.getMonth() + 1)}-${this.fechaDosDigitos(dateNow.getDate())}`
	this.setState({fecha_inicio: dateString, fecha_fin: dateString, tipoDeReporte: 1});
  }

  fechaDosDigitos = (string = '') => string.toString().length == 1 ? "0" + string : string;
  

  onChangeInput = (event, input ) =>{
    this.setState({[input]: event.target.value})
  }

  getSucursalesUsuario(){
    SucUsRelacion.ListarSucUsRelacionesByUsuario(sessionStorage.getItem("id")).then(response =>{
      console.log({sucus: response.data});
      console.log("id que se va en el mount///"+sessionStorage.getItem("id"));
      if(response.data?.length > 0){
        //this.setState({sucursalAsignada: true});
        this.setState({sucursalId: response.data[0].sucursal_id,nombre_sucursal: response.data[0].nombre_sucursal,sucursalAsignada: true})
        console.log("Suc del response////:"+this.state.nombre_sucursal);
      }else{
        this.setState({sucursalAsignada: false});
      }
    }).catch(err =>{
      this.setState({sucursalAsignada: false});
      console.log("Error en getSucursalesUsuario ", err);
    })
  }

  cargarReporte = () => {
	const id_usuario = sessionStorage.getItem("id");
	const data = {
		"id_usuario": id_usuario,
		"fecha_inicio": this.state.fecha_inicio,
        "fecha_fin": this.state.fecha_fin,
		"suc_id":this.state.sucursalId
	}
	if(this.state.tipoDeReporte == 1){
		ReportesService.ReporteByDate(data).then(response =>{
			console.log({response: response.data})
			const reporte = response.data;
			let total = 0;
			reporte.forEach(fila =>{
				total += fila.importe;
			})
			console.log({total})
			this.setState({filasReporte: response.data, totalVentas: total});
		}).catch(err =>{
			console.log("Error en cargarReporte", err);
		})
	}else if(this.state.tipoDeReporte == 2){
		ReportesService.ReporteClientesByDate(data).then(response =>{
			console.log({responseCliente: response.data})
			this.setState({filasReporteClientes: response.data});
		}).catch(err =>{
			console.log("Error en cargarReporte", err);
		})
	}else if(this.state.tipoDeReporte == 3){
		ReportesService.ReporteSociosByDate(data).then(response =>{
			console.log({responseSocios: response.data});
			this.setState({filasReporteSocios: response.data})
		}).catch(err =>{
			console.log("Error en cargarReporte", err);
		})
	}
  }

  cambiarTipoReporte = (tipo) =>{
	this.setState({tipoDeReporte: tipo});
  }

  

  render(){
    return (
      <div className='bg-secondary_2 bg-opacity-30 text-white p-10'>
        <h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
						Reportes {this.state.nombre_sucursal}
					</h1>

					<div className="flex justify-start mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Reportes</span>
									</div>
								</li>
							</ol>
						</nav>
					</div>
        <div className='flex justify-between items-center p-3'>
			<div className='flex gap-2 p-3'>
				<div className=''>
					<label>Fecha de Inicio</label>
					<input 
						className={`w-full p-1 text-12pt border-radius-7px text-black`} 
						value={this.state.fecha_inicio}
						onChange={ event => this.onChangeInput(event, 'fecha_inicio') }
						required
						type='date'
					/>
				</div>
				<div className=''>
					<label>Fecha final</label>
					<input 
						className={`w-full p-1 text-12pt border-radius-7px text-black`} 
						value={this.state.fecha_fin}
						onChange={ event => this.onChangeInput(event, 'fecha_inicio') }
						required
						type='date'
					/>
				</div>
			</div>
			<button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' 
				onClick={ () => this.cargarReporte() }>
				Cargar Reporte
			</button>
        </div>
        <div className='h-11/12 w-full p-2'>

			<div className='flex justify-around mb-5'>
				<button className={`h-12 p-6 py-3 ${this.state.tipoDeReporte === 1 ? 'bg-color1': 'bg-gray-400'} hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4`} 
					onClick={ () => this.cambiarTipoReporte(1) }>
					Productos
				</button>
				<button className={`h-12 p-6 py-3 ${this.state.tipoDeReporte === 2 ? 'bg-color1': 'bg-gray-400'} hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4`} 
					onClick={ () => this.cambiarTipoReporte(2) }>
					Clientes
				</button>
				<button className={`h-12 p-6 py-3 ${this.state.tipoDeReporte === 3 ? 'bg-color1': 'bg-gray-400'} hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4`} 
					onClick={ () => this.cambiarTipoReporte(3) }>
					Socios
				</button>
			</div>

			<div>
			<ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="h-12 p-6 py-3 bg-green-700 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4"
                    table="tablaReporte"
                    filename={`Reporte-${this.state.fecha_inicio}-${this.state.fecha_fin}`}
                    sheet={`Reporte-${this.state.fecha_inicio}-${this.state.fecha_fin}`}
                    buttonText="Descargar Excel"/>

			</div>


			<table id="tablaReporte" className='w-full'>
				{
					this.state.tipoDeReporte == 1 ? (
						<>
							<tr>
								<th>Total de Ventas: </th>
								<th>${this.state.totalVentas} </th>
							</tr>
							<tr>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg' >Usuario</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Cliente</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Sucursal</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Identificador Item</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Item</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Modelo</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Categoria</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Cantidad</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Precio U.</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Importe</th>
							</tr>
							{
								this.state.filasReporte.map((fila, index) =>{
									return (
										<>
										<tr key={fila.item_identificador} className={`w-full mt-5 rounded-2xl md:w-full h-auto px-5 py-5 ${index % 2 == 0 ? "bg-secondary_2" : "bg-green-800"}`}>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.usuario}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.cliente}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.sucursal}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.item_identificador}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.item}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.modelo}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.categoria}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.cantidad}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.precio_Unitario}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.importe}</td>
										</tr>
										</>
									)
								})
							}
						</>
					):  this.state.tipoDeReporte == 2 ? (
						<>
							<tr>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg' >Nombres</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Apellido Paterno</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Apellido Materno</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Tipo de Cliente</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Curp</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Rfc</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Sexo</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Fecha de Nacimiento</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Razón social</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Estado</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Municipio</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Localidad</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Correo electronico</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Teléfono móvil</th>
								<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Teléfono fijo</th>
							</tr>
							{
								this.state.filasReporteClientes.map((fila, index) =>{
									return (
										<>
										<tr key={fila.item_identificador} className={`w-full mt-5 rounded-2xl md:w-full h-auto px-5 py-5 ${index % 2 == 0 ? "bg-secondary_2" : "bg-green-800"}`}>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.nombre}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.app_paterno}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.app_materno}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.tipo_cliente_descripcion}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.curp}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.rfc}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.sexo}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.fecha_nacimiento}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.razon_social}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.entidad_federativa}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.nombre_municipio}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.nombre_asentamiento}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.correo_electronico}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.telefono1}</td>
											<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.telefono2}</td>
										</tr>
										</>
									)
								})
							}
						</>
					): this.state.tipoDeReporte == 3 ? (
					<>
						<tr>
							<th className='flex-1 text-center mt-1 p-2 font-bold text-lg' >ID Socio</th>
							<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Nombre Socio</th>
							<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Fecha de Registro</th>
							<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Registrado por</th>
							<th className='flex-1 text-center mt-1 p-2 font-bold text-lg'>Ventas</th>
						</tr>
						{
							this.state.filasReporteSocios.map((fila, index) =>{
								return (
									<>
									<tr key={fila.id_usuario} className={`w-full mt-5 rounded-2xl md:w-full h-auto px-5 py-5 ${index % 2 == 0 ? "bg-secondary_2" : "bg-green-800"}`}>
										<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.id_usuario}</td>
										<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.nombre_socio}</td>
										<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.date_reg}</td>
										<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.register_by_nombre}</td>
										<td className={`text-center mt-1 p-2 bg-transparent`} >{fila.ventas}</td>
									</tr>
									</>
								)
							})
						}
					</>
				): null
			}
				
			</table>
          
        </div>
      </div>
    );  
  }
  
}

export default ReportesPrincipal;
