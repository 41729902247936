import React from 'react'
import InventarioService from '../services/InventarioService';
import sweetalert from "sweetalert2"
import ProveedoresService from '../services/ProveedoresService';
import ResguardosService from '../services/ResguardosService';
import SucursalesService from '../services/SucursalesService';
import InventarioModal from './InventarioModal';
import UsuariosModal from './usuariosModal';
import ClientesService from 'services/ClientesService';

class ClientesDetalles extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      proveedores: [],
      usuarios: [],
      cliente: {
        id_cliente: 0,
        usuario_id: 0,
        usuario: {nombre: ''},
        distribuidor: 0,
        email: '',
        status: 0,
      }
    }
  }

  componentDidMount(){
    this.getProveedores();
    if(this.props.clienteSelect != 0) this.getCliente(this.props.clienteSelect); 
  }

  getProveedores = () =>{
    ProveedoresService.ListarProveedores().then(response =>{
      this.setState({proveedores: response.data});
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log("Error en getProveedores ", err);
    })
  }

  getCliente = (idCliente) =>{
    ClientesService.ClienteById(idCliente).then(response =>{
        this.setState({cliente: response.data})
        console.log(response.data)
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
        console.log("Error en getCliente ", err);
    })
  }


  actualizarCliente = (event) =>{
    const usuario = sessionStorage.getItem("id");
    event.preventDefault();
    if(this.props.clienteSelect == 0){
        const data = {
            ...this.state.cliente, 
            register_by: usuario,
            status: 1,
        };
        console.log(data)
        ClientesService.InsertarCliente(data).then(response =>{
          console.log("Insertado con exito, ", response);
          this.props.getClientes();
          this.props.cerrarModal();
        }).catch(err =>{
          sweetalert.fire({
            text: "Error al actualizar los datos",
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          console.log("Error en actualizarCliente, ", err);
        });
    }else{
        const data = {...this.state.cliente, lu_by: usuario};
        console.log(data)
        ClientesService.ActualizarCliente(data).then(response =>{
          console.log("Actualizado con exito, ", response);
          this.props.getClientes();
          this.props.cerrarModal();
        }).catch(err =>{
          sweetalert.fire({
            text: "Error al actualizar los datos",
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          console.log("Error en actualizarCliente, ", err);
        });
    }
    
    
  }

  onChangeInput = (event, input ) =>{
    this.setState({cliente:{...this.state.cliente, [input]: event.target.value}})
  }

  cerrarModal = () =>{
    this.props.cerrarModal();
  }

  cerrarModalUsuarios = () =>{
    this.setState({modalUsuarios: false})
  }

  abrirUsuarioModal(usuario_aSelect) {
    this.setState({modalUsuarios:true, usuario_aSelect })
  }

  cambiarUsuario = (usuario) =>{
    this.setState({cliente: {...this.state.cliente, usuario_id: usuario.id_usuario, usuario: usuario}})

  }
  


  render(){
    return (
        <div className='fixed inset-0 z-50 flex flex-wrap justify-center'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            <form className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white overflow-auto'
                onSubmit={(event) => this.actualizarCliente(event)}
            >

                <p className='text-xl text-center font-bold w-full'>{`${this.props.clienteSelect == 0 ? "Nuevo Cliente" : "Detalles del cliente"}`}</p>
                <div className='w-5/12 mt-2'>
                    <label>Usuario</label>
                    <input
                      onClick={() => this.abrirUsuarioModal(false)}
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.cliente.usuario?.nombre}
                      required
                    />
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Distribuidor</label>
                    <select
                        className={`w-full p-1 text-12pt border-radius-7px text-black`}
                        value={this.state.cliente.distribuidor}
                        onChange={ event => this.onChangeInput(event, 'distribuidor') }
                        required
                        >
                           <option >{"---"}</option>
                            {
                            this.state.proveedores.map(proveedor =>{
                                return <option key={proveedor.id_proveedor} value={proveedor.id_proveedor} >{proveedor.nombre}</option>
                            })
                            
                            }

                    </select>
                </div>
                
                <div className='w-full mx-16 mt-2'>
                    <label>Email</label>
                    <input 
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      type="email"
                      value={this.state.cliente.email}
                      onChange={ event => this.onChangeInput(event, 'email') }
                      required
                    />
                </div>
                
                <div className='w-full flex gap-2 justify-center items-center mt-5'>
                <button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Guardar</button>
                <button className='focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' onClick={() => this.cerrarModal()}>Cancelar</button>
                </div>

                
            </form>
            <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
            {
            this.state.modalUsuarios && (
              <UsuariosModal cerrarModal={this.cerrarModalUsuarios} cambiarUsuario={this.cambiarUsuario} />
            )
            }
        </div>
    )
  }
  
}

export default ClientesDetalles;
