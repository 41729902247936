import axios from 'axios';
import { APIURL } from './Host';


class ClientesService{


    ListarDetallesVentas(){
        return axios.post(`${APIURL}/detallesVentas`);
    }

    DetallesVentaById(idDetallesVenta){
        return axios.post(`${APIURL}/detallesVenta/${idDetallesVenta}`);
    }

    InsertarDetallesVenta(detallesVenta){
        const data = {
            "inventario_id": detallesVenta.inventario_id,
            "venta_id": detallesVenta.venta_id,
            "cantidad": detallesVenta.cantidad,
            "precio_unitario": detallesVenta.precio_unitario,
            "importe": detallesVenta.importe,
            "register_by": detallesVenta.register_by,
            "status": detallesVenta.status,
            
        }
        return axios.post(`${APIURL}/detallesVenta/insert`, data);
    }

    ActualizarDetallesVenta(detallesVenta){
        const data = {
            "inventario_id": detallesVenta.inventario_id,
            "venta_id": detallesVenta.venta_id,
            "cantidad": detallesVenta.cantidad,
            "precio_unitario": detallesVenta.precio_unitario,
            "importe": detallesVenta.importe,
            "register_by": detallesVenta.register_by,
            "lu_by": detallesVenta.lu_by,
            "status": detallesVenta.status,
            "id_detalles_ventas": detallesVenta.id_detalles_ventas,
        }
        return axios.post(`${APIURL}/detallesVenta/update`, data);
    }

    ActualizarStatusDetallesVenta(detallesVenta){
        const data = {
            "status": detallesVenta.status,
            "id_detalles_ventas": detallesVenta.id_detalles_ventas
        }
        return axios.post(`${APIURL}/detallesVenta/update/status`, data);
    }

}

export default new ClientesService();