import { Component, createRef } from "react"
import { withRouter } from "react-router-dom"
class TroubleShooting extends Component {


    render() {
        return (
          <>
            <div className='fixed inset-0 z-50 flex flex-wrap justify-center items-center'>
              <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer'></div>
              <div className="bg-white z-50 max-w-4xl max-h-full overflow-y-auto rounded-lg">

              </div>
            </div>
          </>
        );
       
     }
}

export default withRouter(TroubleShooting);