import { Link, withRouter } from "react-router-dom";
import subNav from "partials/subNav";
const { Component } = require("react-image-crop");

class NvoCliente extends Component{
    constructor(props){
        super(props);
        this.state={
            nombre: "",
		    apellido_pat: "",
		    apellido_mat: "",
		    rfc: "",
		    f_nac: "",
		    nacionalidad: "",
		    nac_estado: 0,
		    nac_municipio: 0,
		    sexo: "",
		    correo: "",
		    telefonoMov: "",
		    telefonoFijo: "",
			status: 1,
			dni : "",
			option_nac_estado: [],
		    option_nac_municipio: [],
		    option_nac_localidad: [],
		    reg_by: parseInt(sessionStorage.getItem("id")),
		    errors : {},
		    ocultarForm :true,
		    bloquearBotones : false,
		    ocultarSeccionLugarNac : true,
		    tipo_user:"Cliente"
        }
    }

    render(){
        return(
            <div>
                <section>
                    <div className="flex items-center justify-end mx-10 mt-5 mb-5">
						<div className="ml-5">
							<Link to="/app" className="inline-flex items-center text-sm text-white dark:text-gray-400 ">
							<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
								 Home
							</Link>
						</div>
						<div className="ml-5">
							<Link to="/app/GestionDeUsarios" className="inline-flex items-center text-sm text-gray-200 dark:text-gray-400 ">
							<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
								Gestión de Clientes
							</Link>
						</div>
						 <div className="ml-5">
							<Link to="/app/NvoCliente" className="inline-flex items-center text-sm text-gray-400 dark:text-gray-400 ">
							<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                            Alta de Cliente
							</Link>
						</div> 
					</div> 
                <div className='col-span-6'>
									<div className='bg-secondary_2 rounded-3xl shadow-sm2 p-5 bg-base text-white '>
										<div className='row'>
											<span className='w-full'>
												<label htmlFor='nombre' className='text-12pt text-white'>
													Nombre(s)*
												</label>
												<input
													name='nombre'
													value={this.state.nombre}
													onChange={event => this.asignarValor(event) }
													onBlur={event => {this.asignarValor(event); this.blurNombre();}}
													maxLength="50"
													className='w-full text-12pt border-radius-7px text-black'
													/>
													{this.state.errors.nombre && this.state.errors.nombre !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nombre}</p> : <p></p> }
											</span>
											<span className='w-full mt-10'>
												<label htmlFor='apellido_pat' className='text-12pt'>
													Apellido Paterno*
												</label>
												<input
													name='apellido_pat'
													value={this.state.apellido_pat}
													onChange={event => this.asignarValor(event) }
													onBlur={event => {this.asignarValor(event); this.blurApellidoPat();}}
													maxLength="50"
													className='w-full text-12pt border-radius-7px text-black'
													/>
													{this.state.errors.apellido_pat && this.state.errors.apellido_pat !="" ? <p style={this.styleErrorCampos}>{this.state.errors.apellido_pat}</p> : <p></p> }
											</span>
											<span className='w-full mt-10'>
												<label htmlFor='apellido_mat' className='text-12pt'>
													Apellido Materno
												</label>
												<input
													name='apellido_mat'
													value={this.state.apellido_mat}
													onChange={event => this.asignarValor(event) }
													onBlur={event => {this.asignarValor(event); this.blurApellidoMat();}}
													maxLength="50"
													className='w-full text-12pt border-radius-7px text-black' />
													{this.state.errors.apellido_mat && this.state.errors.apellido_mat !="" ? <p style={this.styleErrorCampos}>{this.state.errors.apellido_mat}</p> : <p></p> }
											</span>
											<div className='grid grid-cols-1'>
												{ }
												<span className='w-full mt-10'>
													<label htmlFor='nacionalidad' className='text-12pt'>
														Nacionalidad*
													</label>
													<select
														onChange={event => {
															this.asignarValor(event);
															this.setState({ curp : "" });
															delete this.state.errors.curp;
															if (event.target.value === "Extranjera"){
																this.setState({ocultarSeccionLugarNac:false, nac_estado: 88});
															} else {
																this.setState({ocultarSeccionLugarNac:true, nac_estado: 0});
															}
														}}
														onBlur={event => {this.asignarValor(event); this.blurNacionalidad(); }}
														name='nacionalidad'
														maxLength={15}
														className='w-full cursor-pointer text-12pt border-radius-7px bg-white text-black'>
														<option>---</option>
														<option value='Mexicana'>Mexicana</option>
														<option value='Extranjera'>Extranjera</option>
													</select>
													{this.state.errors.nacionalidad && this.state.errors.nacionalidad !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nacionalidad}</p> : <p></p> }
												</span>
											</div>
										</div>
									</div>
								</div>
                </section>
            </div>
        );
    }

}
export default withRouter (NvoCliente);