
import { withRouter } from "react-router-dom";
import PortabilidadesService from "services/PortabilidadesService";
import PortabilidadModal from "./PortabilidadModal";
import GeneralService from "services/GeneralService";
const { Component } = require("react-image-crop");

class Portabilidades extends Component{

	constructor(props) {
		super(props);
		this.state = {
			id_usuario : sessionStorage.getItem("id"),
			portabilidades:[],
			portabilidadesAux:[],
			showPortabilidadModal : false,
			fecha_inicio: '',
			fecha_fin: ''
		};
	}
	componentDidMount(){
		this.getPortabilidades();
	}	

	getPortabilidades = () =>{
		const id_usuario=GeneralService.permutaIdUsuario(sessionStorage.getItem("id"));
		console.log("id que se va"+id_usuario);
		PortabilidadesService.ListarPortabilidades(id_usuario).then(response =>{
			this.setState({portabilidades: response.data});
			this.setState({portabilidadesAux: response.data});
			this.setState({fecha_inicio: ""});
			this.setState({fecha_fin: ""});
		}).catch(err =>{
			console.log("Error en getPortabilidades", err);
		})
	}

	downloadTxtFile = () => {
		
		const filename = 'reportePortablidades.txt';
	
		// Crear el contenido del archivo
		const textoFormateado = this.state.portabilidades.map(variable => `${variable.num_transitorio? variable.num_transitorio:"0000000000"}||${variable.telefono? variable.telefono:"0000000000"}||||||Y`).join('\n');
	
		// Crear un objeto Blob con el contenido
		const blob = new Blob([textoFormateado], { type: 'text/plain' });
	
		const link = document.createElement('a');
		link.href = URL.createObjectURL(blob);
		link.download = filename;
	
		link.click();
		URL.revokeObjectURL(link.href);
	  }
 
	filtrarPorFechas(fechaIni,fechaFinal){
		  const fechaInicio = new Date(fechaIni); // Fecha de inicio del rango
		  const fechaFin = new Date(fechaFinal); // Fecha de fin del rango
		  
		  const fechasFiltradasPorRangoFechas = this.state.portabilidadesAux.filter(item => {
			const fecha = new Date(item.date_portabilidad.split(" ")[0]);
			return fecha >= fechaInicio && fecha <= fechaFin; // Verificar si la fecha está dentro del rango
		  });
		  this.setState({portabilidades: fechasFiltradasPorRangoFechas});	  
	}

	onChangeInput = (event, input ) =>{
		this.setState({[input]: event.target.value});
		if(input==='fecha_inicio' && this.state.fecha_fin!==""){
			this.filtrarPorFechas(event.target.value,this.state.fecha_fin);
		}
		if(input==='fecha_fin' && this.state.fecha_inicio!==""){
			this.filtrarPorFechas(this.state.fecha_inicio,event.target.value);
		}
		
	}

	nuevaPortabilidad=()=>{
		this.setState({showPortabilidadModal : true});
	}

	cerrarMortabilidadModal = () =>{
		this.setState({showPortabilidadModal: false});
	}

render(){
		return (
		  <div className='bg-secondary_2 bg-opacity-30 text-white p-10'>
			<h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
			PORTABILIDADES
						</h1>
	
						<div className="flex justify-start mx-10 mt-4">
							<nav class="flex" aria-label="Breadcrumb">
								<ol class="inline-flex items-center space-x-1 md:space-x-3">
									<li class="inline-flex items-center">
										<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
											<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
											Home
										</a>
									</li>
									
									<li aria-current="page">
										<div class="flex items-center">
											<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
											<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Portabilidades</span>
										</div>
									</li>
								</ol>
							</nav>
						</div>
			<div className='flex justify-end p-3'>
			<div className='flex gap-2 p-3'>
				<div className=''>
					<label>Inicio de Fecha</label>
					<input 
						className={`ml-2 p-1 text-12pt border-radius-7px text-black`} 
						value={this.state.fecha_inicio}
						onChange={ event => this.onChangeInput(event, 'fecha_inicio') }
						required
						type='date'
					/>
				</div>
				<div className=''>
					<label>Final de Fecha</label>
					<input 
						className={`ml-2 p-1 text-12pt border-radius-7px text-black`} 
						value={this.state.fecha_fin}
						onChange={ event => this.onChangeInput(event, 'fecha_fin') }
						required
						type='date'
					/>
				</div>
			</div>
			<button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.getPortabilidades() }><i class="fas fa-sync-alt"></i></button>
			<button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={this.downloadTxtFile}><i class='fas fa-plus-circle'></i>Descargar Reporte</button>
			  <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.nuevaPortabilidad() }><i class='fas fa-plus-circle'></i> NUEVA PORTABILIDAD</button>
	
			</div>
			<div className='h-11/12 w-full p-2'>
			  <div className='flex flex-row'>
				<h4 className='w-1/6 flex-1 text-center mt-1 p-2 font-bold text-lg'>ID</h4>
				<h4 className='w-1/6 flex-1 text-center mt-1 p-2 font-bold text-lg'>Cliente</h4>
				<h4 className='w-1/6 flex-1 text-center mt-1 p-2 font-bold text-lg'>Telefono</h4>
				<h4 className='w-1/6 flex-1 text-center mt-1 p-2 font-bold text-lg'>Fecha Portabilidad</h4>
				<h4 className='w-1/6 flex-1 text-center mt-1 p-2 font-bold text-lg'>IMEI</h4>
				<h4 className='w-1/6 flex-1 text-center mt-1 p-2 font-bold text-lg'>ICCID</h4>
				<h4 className='w-1/6 flex-1 text-center mt-1 p-2 font-bold text-lg'>STATUS</h4>
			  </div>
			  {
				this.state.portabilidades.map((item, index) =>{
				  return (
					<div className='flex flex-row w-full mt-5 rounded-2xl md:w-full h-autopy-5 py-5 bg-secondary_2'>
						<h1 className="w-1/6 text-white text-center pl-3 md:pl-5 text-lg font-barlow ">{item.id_portabilidad}</h1>
						<h1 className="w-1/6 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.cliente_nombre}</h1>
						<h1 className="w-1/6 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.telefono}</h1>
						<h1 className="w-1/6 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.date_portabilidad}</h1>
						<h1 className="w-1/6 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.imei}</h1>
						<h1 className="w-1/6 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.iccid}</h1>
						<h1 className="w-1/6 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.status == 0 ? "CANCELADO" : item.status == 1 ? "REALIZADO" : item.status == 2 ? "PENDIENTE" : "DESCONOCIDO"}</h1>
			  		</div>
				  )
				})
			  }
			  
			
			  
			</div>
			
			{this.state.showPortabilidadModal && (
					<PortabilidadModal cerrarModal={this.cerrarMortabilidadModal} getPortabilidades={this.getPortabilidades}/>
				)}

			</div>
		);  
	}
}
export default withRouter(Portabilidades) 