import React from 'react'
import CatIdentificadoresService from 'services/CatIdentificadoresService';
import InventarioService from '../services/InventarioService';
import ProveedoresService from '../services/ProveedoresService';
import SucursalesService from '../services/SucursalesService';
import VinculacionService from 'services/VinculacionService';
import sweetAlert from "sweetalert2";
import CategoriasService from 'services/CategoriasService';

class VinculacionDetalles extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      datas:[]
    }
  }

  componentDidMount(){
   this.getDetallesVinculacion(this.props.itemSelect);
    //if(this.props.itemSelect != 0) this.getInventarioItem(this.props.itemSelect); 
  }

  getDetallesVinculacion = async(idInventario) =>{
    VinculacionService.VinculacionByID(idInventario).then(response =>{
        this.setState({datas: response.data})
        console.log(response.data);
      }).catch(err =>{
        sweetAlert.fire({
          text: "Error al obtener los datos",
          allowOutsideClick: false,
          allowEscapeKey: false
        })
        console.log('Error en listarInventario ', err)
      })
    }
 

cerrarModal = () =>{
    this.props.cerrarModal();
  }
  


  render(){
    return (
        <div className='fixed inset-0 z-50 flex flex-wrap justify-center text-black'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            <form className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-primary rounded-3xl shadow-sm2 p-5 text-white overflow-auto'
                onSubmit={(event) => this.actualizarInventarioItem(event)}
            >

                <p className='text-xl text-center font-bold w-full'>{`${this.props.itemSelect == 0 ? "Detalles" : "Detalles de la Vinculación"}`}</p>
                
                <div className='w-5/12 mt-2'>
                 {/* <label>Id_Vinculación: {this.state.data[0]?.ID_Vinculacion}</label> */}
                 <h1 >ID: {this.state.datas[0]?.ID_Vinculacion}</h1>
                </div>
                
                <div className='w-5/12 mt-2'>
                <h1 >Registrante: {this.state.datas[0]?.vNombre}</h1>                   
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Email de Cliente:   {this.state.datas[0]?.cEmail}</label>
                    
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Telefono Asociado: {this.state.datas[0]?.msisdn}</label>
                    
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Nombre de Inversor:  {this.state.datas[0]?.iNombre} {this.state.datas[0]?.iApp} {this.state.datas[0]?.iApm}</label>
                    
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Email de Inversor: {this.state.datas[0]?.iEmail}</label>
                    
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Nombre Cliente: {this.state.datas[0]?.cNombre} {this.state.datas[0]?.cApp} {this.state.datas[0]?.cApm}</label>
                    
                </div>
                <div className='w-5/12 mt-2'>
                    <label>Fecha de Registro: {this.state.datas[0]?.vDate_Reg}</label>
                    
                </div>
                
                <div className={'w-full flex gap-2 justify-center items-center mt-5'}>
                <button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Aceptar</button>
                <button className='focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' onClick={() => this.cerrarModal()}>Cancelar</button>
                </div>

                
            </form>
            <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
        </div>
    )
  }
  
}

export default VinculacionDetalles;
