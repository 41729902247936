import axios from 'axios';
import { APIURL } from './Host';


class ResguardosService{


    ListarResguardos(){
        return axios.post(`${APIURL}/resguardos`);
    }

    ResguardoById(idResguardo){
        return axios.post(`${APIURL}/resguardos/${idResguardo}`);
    }

    InsertarResguardo(resguardo){
        const data = {
            "inventario_id": resguardo.inventario_id,
            "usuario_r_id": resguardo.usuario_r_id,
            "usuario_a_id":resguardo.usuario_a_id,
			"suc_id_origen": resguardo.suc_id_origen,
            "suc_id_destino": resguardo.suc_id_destino,
            "status": resguardo.status,
            "register_by":resguardo.register_by,
			"firma":resguardo.firma,
        }
        return axios.post(`${APIURL}/resguardos/insert`, data);
    }

    ActualizarResguardo(resguardo){
        const data = {
            "inventario_id": resguardo.inventario_id,
            "usuario_r_id": resguardo.usuario_r_id,
            "usuario_a_id": resguardo.usuario_a_id,
			"suc_id_origen": resguardo.suc_id_origen,
            "suc_id_destino": resguardo.suc_id_destino,
            "status": resguardo.status,
            "register_by": resguardo.register_by,
            "lu_by": resguardo.lu_by,
			"firma": resguardo.firma,
            "id_resguardo": resguardo.id_resguardo,
        }
        return axios.post(`${APIURL}/resguardos/update`, data);
    }

    ActualizarStatusResguardo(resguardo){
        const data = {
            "status": resguardo.status,
            "id_resguardo": resguardo.id_resguardo
        }
        return axios.post(`${APIURL}/resguardos/update/status`, data);
    }

}

export default new ResguardosService();