import axios from 'axios';
import { APIURL } from './Host';


class ReportesService{



    ReporteByDate(especificaciones){
        const data = {
            "fecha_inicio": especificaciones.fecha_inicio,
            "fecha_fin": especificaciones.fecha_fin,
            "suc_id":especificaciones.suc_id
        }
        return axios.post(`${APIURL}/reporte/byDate`, data);
    }
    ReporteClientesByDate(especificaciones){
        const data = {
            "id_usuario": especificaciones.id_usuario,
            "fecha_inicio": especificaciones.fecha_inicio,
            "fecha_fin": especificaciones.fecha_fin,
        }
        return axios.post(`${APIURL}/reporte/clientes/byDate`, data);
    }

    ReporteSociosByDate(especificaciones){
        const data = {
            "id_usuario": especificaciones.id_usuario,
            "fecha_inicio": especificaciones.fecha_inicio,
            "fecha_fin": especificaciones.fecha_fin,
        }
        return axios.post(`${APIURL}/reporte/socios/byDate`, data);
    }

    ReporteActivacionesByDate(especificaciones){
        const data={
            "tipo_usuario":especificaciones.tipo_usuario,
            "id_usuario": especificaciones.id_usuario,
            "fecha_inicio": especificaciones.fecha_inicio,
            "fecha_fin": especificaciones.fecha_fin,
        }
        return axios.post(`${APIURL}/reporte_activaciones`, data);
        }


}

export default new ReportesService();