import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom"
const { Component } = require("react-image-crop");
const { cjsPostCssConfigFile } = require("tailwindcss/lib/constants");

class subNav extends Component{
    constructor(props){
        super(props)
        this.state={
            path: props.path,
            tipo_usuario_id: props.tipo_usuario_id
        }
    }
    render(){
        return(
            <div className="flex items-center justify-end mx-10 mt-5 mb-5">
						<div className="ml-5">
							<Link to="/app" className="inline-flex items-center text-sm text-white dark:text-gray-400 ">
							<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
								 Home
							</Link>
						</div>
						<div className="ml-5">
							<Link to="/app/ListaUsuarios" className="inline-flex items-center text-sm text-gray-200 dark:text-gray-400 ">
							<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
								Usuario
							</Link>
						</div>
						<div className="ml-5">
							<Link to="/app/Form-Usuario" className="inline-flex items-center text-sm text-gray-400 dark:text-gray-400 ">
							<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
								{this.state.tipo_usuario_id == 0 || this.state.tipo_usuario_id == 1 ? "Nuevo usuario" : (this.state.tipo_usuario_id == 2 ? "Nuevo Socio" : (this.state.tipo_usuario_id == 3 ? "Nuevo Cliente" : (this.state.tipo_usuario_id == 4 ? "Nuevo alumno" : (this.state.tipo_usuario_id == 6 ? "Nuevo agente de ventas" : (this.state.tipo_usuario_id == 7 ? "Nuevo prospecto" : (this.state.tipo_usuario_id == 8 ? "Nuevo tutor" : "Nuevo usuario"))))))}
							</Link>
						</div>
					</div>
        );
    }
}
export default withRouter(subNav);