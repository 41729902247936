import React from 'react'
import ClientesService from 'services/ClientesService';
import InversionistaService from 'services/InversionistaService';
import VinculacionService from 'services/VinculacionService';
import sweetAlert from "sweetalert2";
import ClientesModal from './ClientesModal';
import InversionistasModal from './InversionistasModal';


class NuevasVinculaciones extends React.Component {

    constructor(props){
        super(props);
        this.state = {
        vinculaciones: [],
        modalClientes: false,
        modalInversionistas: false,
        clientes: [],
        inversionistas: [],
        inversionista: {},
        }
    }

    componentDidMount(){
        this.getClientes();
        this.getInversionistas();
    }

    getClientes = async() =>{
        const clientes = await ClientesService.listClientes(1)
            .then(resultado => resultado.data)
            .catch(error => []);
        console.log({clientes})
        this.setState({clientes: clientes});
    }
    getInversionistas = async() =>{
        const inversionistas = await InversionistaService.listInversionista(1)
			.then(resultado => resultado.data)
			.catch(error => []);
        console.log({inversionistas})
		this.setState({ inversionistas: inversionistas });
    }

    crearNuevasVinculaciones = async (event) =>{
        event.preventDefault();
        const usuario = sessionStorage.getItem("id");
        
        await this.state.vinculaciones.forEach(async vinculacion =>{
            const data = {
                inver_id: this.state.inversionista?.id_inversionista,
                cliente_id: vinculacion.id_cliente,
                register_by: usuario,
                status: 1,
            }
            await VinculacionService.InsertarVinculacion(data).then(response =>{
            console.log("Insertado con exito, ", response.data)
            }).catch(err =>{
            console.log("Error en InsertarRelacion ", err);
            })
        })
        this.props.getLista();
        this.props.cerrarModal();
    }
 

    cerrarModal = () =>{
        this.props.cerrarModal();
     }

    abrirModalClientes = () =>{
        this.setState({modalClientes: true});
    }
    cerrarModalClientes = () =>{
        this.setState({modalClientes: false});
    }
    abrirModalInversionistas = () =>{
        this.setState({modalInversionistas: true});
    }
    cerrarModalInversionistas = () =>{
        this.setState({modalInversionistas: false});
    }

    agregarCliente = (cliente) =>{
        let clienteEncontrado = this.state.vinculaciones.find(vinculacion =>{
            return vinculacion == cliente;
          })
          if(!clienteEncontrado){
              this.setState({vinculaciones: [...this.state.vinculaciones, cliente]});
          }
    }

    seleccionarInversionistas = (inversionista) =>{
        this.setState({inversionista});
    }

    eliminarVinculacion = (vinculacion, index) =>{
        let nuevasVinculaciones = this.state.vinculaciones.filter((vinculacion, ind) =>{
            return index != ind;
        })
        this.setState({vinculaciones: nuevasVinculaciones});

    }
  


  render(){
    return (
        <div className='fixed inset-0 z-50 flex flex-wrap justify-center text-black'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            <form className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white overflow-auto'
                onSubmit={(event) => this.crearNuevasVinculaciones(event)}
            >

                <p className='text-xl text-center font-bold w-full'>{`Nueva Vinculacion`}</p>
                
                <div className='w-5/12 mt-2'>
                 <label>Inversionista: </label>
                 <input
                      onClick={() => this.abrirModalInversionistas()}
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.inversionista.nombre}
                      required
                    />
                </div>
                
                <div className='w-11/12 mt-2'>
                    <label>Clientes vinculados:</label>
                    <button className='inline-block ml-2' type='button' onClick={() => this.abrirModalClientes()}>
                      <i className='fas fa-plus-circle'></i>
                    </button>
                    <div className='w-full h-24 p-1 text-12pt border-radius-7px bg-white flex flex-row flex-wrap justify-start items-start gap-1 overflow-y-auto'>
                      {
                        this.state.vinculaciones?.map((vinculacion, index) =>{
                          return(
                          <div key={vinculacion.id_cliente} className='bg-gray-200 w-auto h-auto border-radius-7px text-black p-1'>
                            {`${vinculacion.telefono1}`}
                            <button type='button' className='bg-red-600 rounded-full w-min h-min px-2 text-white inline-block ml-1'
                                    onClick={() => this.eliminarVinculacion(vinculacion, index)}
                            >
                              x
                            </button>
                          </div>
                          )
                        })
                      }
                    </div>
                </div>

                <div className={'w-full flex gap-2 justify-center items-center mt-5'}>
                <button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Guardar</button>
                <button className='focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' onClick={() => this.cerrarModal()}>Cancelar</button>
                </div>

                
            </form>
            <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
            {
					this.state.modalClientes && (
					<ClientesModal cerrarModalClientes={this.cerrarModalClientes} agregarCliente={this.agregarCliente} clientes={this.state.clientes} />
					)
			}
            {
					this.state.modalInversionistas && (
					<InversionistasModal cerrarModalInversionistas={this.cerrarModalInversionistas} seleccionarInversionistas={this.seleccionarInversionistas} inversionistas={this.state.inversionistas}/>
					)
			}
        </div>
    )
  }
  
}

export default NuevasVinculaciones;
