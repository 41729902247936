import axios from 'axios';
import { APIURL } from './Host';


class InventarioService{


    ListarInventario(info){
        return axios.post(`${APIURL}/inventario`,info);
    }

    InventarioById(idInventario){
        return axios.post(`${APIURL}/inventario/${idInventario}`);
    }

    ObtenerSucursal(id_usuario)
    {
        return axios.post(`${APIURL}/inventarioBySuc/${id_usuario}`)
    }

    InsertarInventario(inventario){
        const data = {
            "modelo": inventario.modelo,
            "marca": inventario.marca,
            "stock_actual": inventario.stock_actual,
            "min_stock": inventario.min_stock,
            "categoria_id": inventario.categoria_id,
            "identificador": inventario.identificador,
            "tipo_id": inventario.tipo_id,
            "proveedor_id":inventario.proveedor_id,
			"descripcion": inventario.descripcion,
            "suc_reg_id": inventario.suc_reg_id,
            "suc_venta_id": inventario.suc_venta_id,
            "register_by":inventario.register_by,
			"status": inventario.status,
            "precio_unitario": inventario.precio_unitario,
            "precio_venta": inventario.precio_venta,
			"precio_mayoreo":inventario.precio_mayoreo,
        }
        return axios.post(`${APIURL}/inventario/insert`, data);
    }

    ActualizarInventario(inventario){
        const data = {
            "modelo": inventario.modelo,
            "marca": inventario.marca,
            "stock_actual": inventario.stock_actual,
            "min_stock": inventario.min_stock,
            "categoria_id": inventario.categoria_id,
            "identificador": inventario.identificador,
            "tipo_id": inventario.tipo_id,
            "proveedor_id":inventario.proveedor_id,
			"descripcion": inventario.descripcion,
            "suc_reg_id": inventario.suc_reg_id,
            "suc_venta_id": inventario.suc_venta_id,
            "register_by":inventario.register_by,
            "lu_by": inventario.lu_by,
			"status": inventario.status,
            "precio_unitario": inventario.precio_unitario,
            "precio_venta": inventario.precio_venta,
			"precio_mayoreo":inventario.precio_mayoreo,
            "id_inventario": inventario.id_inventario
        }
        return axios.post(`${APIURL}/inventario/update`, data);
    }

    ActualizarStatusInventario(inventario){
        const data = {
            "status": inventario.status,
            "id_inventario": inventario.id_inventario
        }
        return axios.post(`${APIURL}/inventario/update/status`, data);
    }

    InventarioLikeIdentificador(identificador){
        return axios.post(`${APIURL}/inventario/likeIdentificador/${identificador}`);
    }

}

export default new InventarioService();