import React from "react";

class AddVinculacion extends React.Component{


    render(){
        return (
            <div className='fixed inset-0 z-50 flex flex-wrap justify-center'>
                <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
                <form className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white overflow-auto'
                    onSubmit={(event) => this.actualizarSucursal(event)}
                >
    
                    <p className='text-xl text-center font-bold w-full'>{`${this.props.sucursalSelect == 0 ? "Nueva sucursal" : "Detalles de la surucrsal"}`}</p>
                    
                    <div className='w-full mx-16 mt-2'>
                        <label>Nombre</label>
                        <input 
                          className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                          value={this.state.sucursal.nombre}
                          onChange={ event => this.onChangeInput(event, 'nombre') }
                          required
                        />
                    </div>
                    <div className='w-5/12 mt-2'>
                        <label>Estado</label>
                        <select
                            className={`w-full p-1 text-12pt border-radius-7px text-black`}
                            value={this.state.sucursal.estado_id}
                            onChange={ event => {
                              this.onChangeInput(event, 'estado_id');
                              this.setState({estadoSelec:event.target.value});
                              this.getDataMunicipio(event.target.value);
                            }}
                            required
                            >
                              <option>{"---"}</option>
                                {
                                this.state.estados?.map(estado =>{
                                  return <option key={estado.id_Estado} value={estado.id_Estado} >{estado.entidad_Federativa}</option>
                                })
                                
                                }
    
                        </select>
                    </div>
                    <div className='w-5/12 mt-2'>
                        <label>Municipio</label>
                        <select
                            className={`w-full p-1 text-12pt border-radius-7px text-black`}
                            value={this.state.sucursal.mun_id}
                            onChange={ event => {
                              this.onChangeInput(event, 'mun_id');
                              this.setState({municipioSelec: event.target.value});
                              this.getDataLocalidad(event.target.value);
                            }}
                            required
                            >
                              <option>{"---"}</option>
                                {
                                this.state.municipios?.map(municipio =>{
                                  return <option key={municipio.c_mnpio} value={municipio.c_mnpio} >{municipio.nombre_Municipio}</option>
                                })
                                
                                }
    
                        </select>
                    </div>
    
                    <div className='w-5/12 mt-2'>
                        <label>Colonia</label>
                        <select
                            className={`w-full p-1 text-12pt border-radius-7px text-black`}
                            value={this.state.sucursal.localidad_id}
                            onChange={ event => {
                              this.onChangeInput(event, 'localidad_id');
                              this.setState({localidadSelec: event.target.value});
                            }}
                            required
                            >
                              <option>{"---"}</option>
                                {
                                this.state.localidades?.map(localidad =>{
                                  return <option key={localidad.id_Localidad} value={localidad.id_Localidad} >{localidad.nombre}</option>
                                })
                                
                                }
    
                        </select>
                    </div>
    
                    <div className='w-5/12 mt-2'>
                        <label>Calle</label>
                        <input 
                          className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                          value={this.state.sucursal.calle}
                          onChange={ event => this.onChangeInput(event, 'calle') }
                          required
                        />
                    </div>
    
    
    
                    <div className='w-5/12 mt-2'>
                        <label>Numero</label>
                        <input 
                          className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                          value={this.state.sucursal.numero}
                          onChange={ event => this.onChangeInput(event, 'numero') }
                          required
                          type='number'/>
                    </div>
    
                    <div className='w-5/12 mt-2'>
                        <label>Observaciones</label>
                        <input 
                          className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                          value={this.state.sucursal.observaciones}
                          onChange={ event => this.onChangeInput(event, 'observaciones') }
                          required/>
                    </div>
    
                    <div className='w-11/12 mt-2'>
                        <label>Usuarios asignados a sucursal</label>
                        <button className='inline-block ml-2' type='button' onClick={() => this.abrirModalUsuarios()}>
                          <i className='fas fa-plus-circle'></i>
                        </button>
                        <div className='w-full h-24 p-1 text-12pt border-radius-7px bg-white flex flex-row flex-wrap justify-start items-start gap-1 overflow-y-auto'>
                          {
                            this.state.sucursalesUsuariosRelacion?.map((usuario, index) =>{
                              return(
                              <div key={usuario.id_usuario} className='bg-gray-200 w-auto h-auto border-radius-7px text-black p-1'>
                                {`${usuario.nombre} ${usuario.app_paterno}`}
                                <button type='button' className='bg-red-600 rounded-full w-min h-min px-2 text-white inline-block ml-1'
                                        onClick={() => this.eliminarUsuario(usuario, index)}
                                >
                                  x
                                </button>
                              </div>
                              )
                            })
                          }
                        </div>
                    </div>
    
                    
    
                    <div className='w-full flex gap-2 justify-center items-center mt-5'>
                    <button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Guardar</button>
                    <button className='focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' onClick={() => this.cerrarModal()}>Cancelar</button>
                    </div>
    
                    
                </form>
                <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
                {/* {
                this.state.modalUsuarios && (
                  <UsuariosModal cerrarModal={this.cerrarModalUsuarios} cambiarUsuario={this.agregarUsuario} />
                )
                } */}
            </div>
        )
      }



}

export default AddVinculacion;