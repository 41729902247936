import Roles from "./Roles"

//Images
import users from "../images/users.png"
import users_white from "../images/users_white.png"
import carga_academica from "../images/carga_academica.png"
import carga_academica_white from "../images/carga_academica_white.png"
import clientes_w from "../images/prospecto_white.png"
import clientes from "../images/prospecto.png"
import home from "../images/icono_home_white.png"
import lista_w from "../images/lista_white.png"
import lista from "../images/lista.png"
import puntoventa from "../images/timep.png"
import puntoventa_w from "../images/timep.png"
import proveedor from "../images/give-money.png"
import proveedor_w from "../images/give-money.png"
import sucursales from "../images/chain-store.png"
import sucursales_w from "../images/chain-store.png"
import recargas from "../images/coins.png"
import recargas_w from "../images/coins.png"
import reporte_ventas from "../images/informe-de-venta_white.png"
import reporte_venta_detalle from "../images/analitica_white.png"
import reporte_activaciones from "../images/lista_white.png"

// Components
import {
	Landing,
	ListaUsuarios,
	PerfilUsuario,
	DashboardAdmon,
	ListaAdministrativos,
	FormUsuario,
	GestionDeClientes,
	NvoCliente,
	TroubleShooting,
	subNav,
	Error404,
	InventarioPrincipal,
	ProveedoresPrincipal,
	ResguardosPrincipal,
	SucursalesPrincipal,
	PuntoVentaPrincipal,
	FormCliente,
	GestionDeProveedores,
	FormProveedor,
	Vinculacion,
	MisVinculaciones,
	InversionistasPrincipal,
	FormInversionistas,
	Recargas,
	Aprovisionamiento,
	Activaciones,
	Reportes,
	Portabilidades,
	CambiosMSISDN,
	ReportesMain,
	ReporteActivaciones
	
} from "components"
import { TextRotationAngledownSharp } from "@material-ui/icons"
import IdentificadoresPrincipal from "components/IdentificadoresPrincipal"
import ClientesPrincipal from "components/ClientesPrincipal"

const roles = JSON.parse(sessionStorage.getItem('roles'));


// eslint-disable-next-line import/no-anonymous-default-export
export default [
	{
		component: (roles!=null ? (roles[0]==="SUPER_ADMIN"||roles[0]==="SOCIO_COMERCIAL" ||roles[0]==="SOCIO_MARCA" ||roles[0]==="DISTRIBUIDOR" ? ListaUsuarios : Landing) : Landing),
		path: "/",
		title: "portada",
		visible: false,
		exact: true,
	},
	{
		component: ListaUsuarios,
		path: "/ListaUsuarios",
		visible: true,
		permission: [
					 Roles.ADMINISTRATIVO, 
					 Roles.SUPER_ADMIN,
					 Roles.SOCIO_MARCA,
					 Roles.SOCIO_COMERCIAL,
					 Roles.DISTRIBUIDOR
					],
		src: users,
		src_white: home,
		title: "Home",
		childrens: [],
	},
	{
		component: DashboardAdmon,
		path: "/DashboardAdmon",
		visible: false,
		permission: [
					 Roles.SUPER_ADMIN,
					 Roles.ASESOR_TEL,
					 Roles.DISTRIBUIDOR,
					 Roles.SOCIO_COMERCIAL,
					 Roles.SOCIO_MARCA
					
					],
		src: users,
		src_white: users_white,
		title: "Dashboard",
		childrens: [],
	},
	{
		component: PerfilUsuario,
		path: "/PerfilUsuario",
		title: "Mis datos",
		visible: false,
		permission: [
					 Roles.SUPER_ADMIN, 
					 Roles.ASESOR_TEL,
					 Roles.DISTRIBUIDOR,
					 Roles.SOCIO_MARCA,
					 Roles.SOCIO_COMERCIAL,
					 ],
		src: users,
		src_white: users_white,
		childrens: [],
	},
	
	{
		component: ListaAdministrativos,
		path: "/ListaAdmon",
		title: "Lista de administrativos",
		visible: false,
		permission: [ 
					 Roles.SUPER_ADMIN,
					 Roles.SOCIO_MARCA,
					 Roles.DISTRIBUIDOR,
					 Roles.SOCIO_COMERCIAL
					],
		src: "",
		childrens: [],
	},

	{
		component: Aprovisionamiento,
		path: "/Aprovisionamiento",
		title: "Asignación de Fondos",
		visible: true,
		permission: [ 
					 Roles.SUPER_ADMIN,
					 Roles.SOCIO_MARCA,
					 Roles.DISTRIBUIDOR,
					 Roles.SOCIO_COMERCIAL
					],
		src: recargas,
		src_white: recargas_w,
		childrens: [],
	},
	
	{
		//component : FormularioUsuarioForm,
		component: FormUsuario,
		path: "/FormUsuario",
		title: "Formulario de usuario",
		visible: false,
		permission: [
					  
					 Roles.SUPER_ADMIN,
					 Roles.SOCIO_MARCA,
					 Roles.SOCIO_COMERCIAL,
					 Roles.DISTRIBUIDOR
					],
		src: carga_academica,
		src_white: carga_academica_white,
		childrens: [],
	},
	{
		component: FormCliente,
		path: "/FormCliente",
		title: "Formulario de cliente",
		visible: false,
		permission: [
					  
					 Roles.SUPER_ADMIN,
					 Roles.SOCIO_MARCA,
					 Roles.SOCIO_COMERCIAL,
					 Roles.DISTRIBUIDOR, 
					 Roles.ASESOR_TEL,
					],
		src: clientes,
		src_white: clientes_w,
		childrens: [],
	},
	{
		component: FormProveedor,
		path: "/FormProveedor",
		title: "Formulario de proveedor",
		visible: false,
		permission: [
					  
					 Roles.SUPER_ADMIN,
					 Roles.SOCIO_MARCA,
					 Roles.DISTRIBUIDOR, 
					 Roles.ASESOR_TEL,
					],
		src: clientes,
		src_white: clientes_w,
		childrens: [],
	},
	{
		component: GestionDeClientes,
		path:"/GestionDeClientes",
		title: "Gestion de Clientes",
		visible:false,
		permission:[
			
			Roles.SUPER_ADMIN, 
			Roles.ASESOR_TEL,
			Roles.DISTRIBUIDOR,
			Roles.SOCIO_MARCA,
			Roles.SOCIO_COMERCIAL
		],
		src: clientes,
		src_white: clientes_w,
		childrens: [],
	},
	{
		component: GestionDeProveedores,
		path:"/GestionDeProveedores",
		title: "Gestion de Proveedores",
		visible:true,
		permission:[
			Roles.SUPER_ADMIN,
			Roles.SOCIO_MARCA
		],
		src: proveedor,
		src_white: proveedor_w,
		childrens: [],
	},
	{
		component:subNav,
		path:"/subNav",
		title: "sub",
		visible:false,
		permission:[
					Roles.ADMINISTRADOR,
					Roles.SUPER_ADMIN
		],
		childrens: [],
	},
	{
		component: InventarioPrincipal,
		path:"/InventarioPrincipal",
		title: "Inventario",
		visible:true,
		permission:[
			Roles.SUPER_ADMIN,
			Roles.SOCIO_MARCA
		],
		src: lista,
		src_white: lista_w,
		childrens: [],

	},
	{
		component: ResguardosPrincipal,
		path:"/ResguardosPrincipal",
		title: "Resguardos",
		visible:true,
		permission:[
			Roles.SUPER_ADMIN,
		],
		childrens: [],

	},
	{
		component: SucursalesPrincipal,
		path:"/SucursalesPrincipal",
		title: "Sucursales",
		visible:true,
		permission:[
			Roles.SUPER_ADMIN,
		],
		src: sucursales,
		src_white: sucursales_w,
		childrens: [],

	},
	{
		component: IdentificadoresPrincipal,
		path:"/IdentificadoresPrincipal",
		title: "Identificadores",
		visible:true,
		permission:[
			Roles.SUPER_ADMIN,
			Roles.SOCIO_MARCA
		],
		childrens: [],

	},
	{
		component: PuntoVentaPrincipal,
		path:"/PuntoVentaPrincipal",
		title: "Punto de Venta",
		visible:true,
		permission:[
			Roles.SUPER_ADMIN,
			Roles.SOCIO_MARCA,
			Roles.SOCIO_COMERCIAL,
			Roles.DISTRIBUIDOR
		],
		src: puntoventa,
		src_white: puntoventa_w,
		childrens: [],

	},
	{
		component: ReportesMain,
		path:"/Reportes",
		//path:"",
		title: "Reportes",
		visible:true,
		permission:[
			Roles.SUPER_ADMIN,
			Roles.SOCIO_MARCA,
			Roles.SOCIO_COMERCIAL,
			Roles.DISTRIBUIDOR
		],
		src: reporte_ventas,
		src_white: reporte_ventas,
		childrens: [
			{
				component: Reportes,
				path:"/ReportesSocios",
				//path:"",
				title: "Reportes",
				visible:true,
				permission:[
					Roles.SUPER_ADMIN,
					Roles.SOCIO_MARCA,
					Roles.SOCIO_COMERCIAL,
					Roles.DISTRIBUIDOR
				],
				src: reporte_ventas,
				src_white: reporte_venta_detalle,
				childrens: [
				],
		
			},
			{
				component: ReporteActivaciones,
		path:"/ReporteActivaciones",
		//path:"",
		title: "Reporte Activaciones",
		visible:true,
		permission:[
			Roles.SUPER_ADMIN,
			Roles.SOCIO_MARCA,
			Roles.SOCIO_COMERCIAL,
			Roles.DISTRIBUIDOR
		],
		src: reporte_ventas,
		src_white: reporte_activaciones,
		childrens: [
		],
			}
		],

	},
	{
		component: Reportes,
		path:"/ReportesSocios",
		//path:"",
		title: "Reportes",
		visible:false,
		permission:[
			Roles.SUPER_ADMIN,
			Roles.SOCIO_MARCA,
			Roles.SOCIO_COMERCIAL,
			Roles.DISTRIBUIDOR
		],
		src: reporte_ventas,
		src_white: "reporte_ventas",
		childrens: [
		],

	},
	{
		component: ReporteActivaciones,
		path:"/ReporteActivaciones",
		//path:"",
		title: "Reporte Activaciones",
		visible:false,
		permission:[
			Roles.SUPER_ADMIN,
			Roles.SOCIO_MARCA,
			Roles.SOCIO_COMERCIAL,
			Roles.DISTRIBUIDOR
		],
		src: "",
		src_white: reporte_activaciones,
		childrens: [
		],

	},
	{
		component: Vinculacion,
		path:"/Vinculacion",
		title: "Vinculacion",
		visible:true,
		permission:[
			Roles.SUPER_ADMIN,
		
		],
		src: clientes,
		src_white: clientes_w,
		childrens: [],

	},
	{
		component: MisVinculaciones,
		path:"/MisVinculacion",
		title: "Mis Vinculaciones",
		visible:true,
		permission:[
			Roles.SUPER_ADMIN,
			Roles.INVERSOR,
		
		],
		src: clientes,
		src_white: clientes_w,
		childrens: [],

	},
	{
		component: Activaciones,
			path: "/Activaciones",
			title: "Activaciones",
			visible: true,
			permission: [
						  
						 Roles.SUPER_ADMIN,
						 Roles.SOCIO_MARCA,
						 Roles.DISTRIBUIDOR
						],
			src: recargas,
			src_white: recargas_w,
		},
	{
		component: InversionistasPrincipal,
		path:"/InvPrincipal",
		title: "Gestión de Inversionistas",
		visible:true,
		permission:[
			Roles.SUPER_ADMIN,
		
		],
		src: clientes,
		src_white: clientes_w,
		childrens: [],

	},
	{
	component: FormInversionistas,
		path: "/FormInversionistas",
		title: "Formulario de Inversionistas",
		visible: false,
		permission: [
					  
					 Roles.SUPER_ADMIN,
					 
					],
		src: carga_academica,
		src_white: carga_academica_white,
		childrens: [],
	},
	{
	component: Recargas,
		path: "/Recargas",
		title: "Recargas",
		visible: true,
		permission: [
					  
					 Roles.SUPER_ADMIN,
					 Roles.CLIENTE,
					],
		src: recargas,
		src_white: recargas_w,
		childrens: [],
	},
	{
	component: Portabilidades,
		path: "/Portabilidades",
		title: "Portabilidades",
		visible: true,
		permission: [
					  
					 Roles.SUPER_ADMIN,
					 Roles.SOCIO_MARCA,
					 Roles.DISTRIBUIDOR
					],
		src: recargas,
		src_white: recargas_w,
		childrens: [],
	},
	{
	component: CambiosMSISDN,
		path: "/CambiosMSISDN",
		title: "Cambios de MSISDN",
		visible: true,
		permission: [
					  
					 Roles.SUPER_ADMIN,
					 Roles.SOCIO_MARCA,
					 Roles.CLIENTE,
					 Roles.DISTRIBUIDOR
					],
		src: recargas,
		src_white: recargas_w,
		childrens: [],
	},
	{
		component: TroubleShooting,
		path:"/TroubleShooting",
		title: "Trouble Shooting",
		visible:false,
		permission:[
			
			Roles.SUPER_ADMIN, 
			Roles.ASESOR_TEL,
			Roles.DISTRIBUIDOR,
			Roles.SOCIO_MARCA,
			Roles.SOCIO_COMERCIAL
		],
		//src: clientes,
		//src_white: clientes_w,
		childrens: [],
	},
	// ==========**********ESTE COMPONENTE DEBE ESTAR AL FINAL**********============
	{
		component: Error404,
		path: "*",
		title: "Error 404",
		visible: false
	},
	
	

]
