import React from 'react'
import sweetalert from "sweetalert2"
import InventarioService from '../services/InventarioService';
import ProveedoresService from '../services/ProveedoresService';
import InventarioDetalles from './InventarioDetalles';
import ProveedorDetalles from './ProveedorDetalles';

class ProveedoresPrincipal extends React.Component {

  constructor(){
    super();
    this.state = {
      proveedoresInicial: [],
      proveedoresModificado: [],
      modalVisible: false,
      proveedorSelect: 0,
    }
  }

  cerrarModal = () =>{
    this.setState({modalVisible: false})
  }

  componentDidMount(){
    this.getProveedores();
  }

  getProveedores = () =>{
    ProveedoresService.ListarProveedores().then(response =>{
      this.setState({proveedoresInicial: response.data, proveedoresModificado: response.data});
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log("Error en getProveedores ", err);
    })
  }


  onChangeInput = (event, index, input) =>{
    let proveedoresModificado = this.state.proveedoresModificado.map((proveedor, i) =>{
      if(index === i){
        return {...proveedor, [ input ]: event.target.value}
      }else{
        return proveedor;
      }
    })

    this.setState({proveedoresModificado: proveedoresModificado});
  }

  resetInventario = () =>{
    this.setState({proveedoresModificado: this.state.proveedoresInicial});
  }

  aplicarCambios = (event, index) =>{
    if(!(event.code === "Enter")) return;
    if(this.state.proveedoresInicial === this.state.proveedoresModificado) return;
    const usuario = sessionStorage.getItem("id");
    const data = {...this.state.proveedoresModificado[index], lu_by: usuario};
    ProveedoresService.ActualizarProveedor(data).then(response =>{
      console.log("Actualizado con exito, ", response);
      this.getProveedores();
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al actualizar los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log("Error en aplicarCambios, ", err);
    });
  }

  eliminarItem = (id_proveedor, descripcion) =>{
    sweetalert
      .fire({
        text:`Seguro quiere eliminar el proveedor: ${descripcion}`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const data = {
            status: 0,
            id_proveedor: id_proveedor
          }
          ProveedoresService.ActualizarStatusProveedor(data).then(response =>{
            this.getProveedores();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al eliminar el proveedor",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log(err);
          })
        }
      }); 
  }

  abrirDetallesProveedor(idProveedor){
    this.setState({modalVisible: true, proveedorSelect: idProveedor});
  }


  render(){
    return (
      <div className='bg-secondary_2 bg-opacity-30 text-white p-10'>
        <h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
						Proveedores
					</h1>

					<div className="flex justify-start mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Proveedores</span>
									</div>
								</li>
							</ol>
						</nav>
					</div>
        <div className='flex justify-end p-3'>
          <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirDetallesProveedor(0) }><i class='fas fa-plus-circle'></i> Agregar Proveedor</button>

        </div>
        <div className='h-11/12 w-full p-2'>
          <div className='flex flex-row gap-1'>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>RFC</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Clave</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Telefono</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Email</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Direccion</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Nombre</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Acciones</h4>
          </div>
          {
            this.state.proveedoresModificado.map((proveedor, index) =>{
              return (
                <div key={proveedor.id_proveedor} className='flex justify-center flex-row w-full mt-5 rounded-2xl md:w-full h-auto px-5 py-5 bg-secondary_2'>
                  <input 
                      className={`w-full text-center mt-1 p-2 bg-transparent`} 
                      value={proveedor.rfc}
                      onChange={ event => this.onChangeInput(event, index, "rfc")}
                      onBlur={() => this.resetInventario()}
                      onKeyUp={(event) => this.aplicarCambios(event, index)}/>
                  <input 
                      className={`w-full text-center mt-1 p-2 bg-transparent`} 
                      value={proveedor.proveedor_clave}
                      onChange={ event => this.onChangeInput(event, index, "proveedor_clave")}
                      onBlur={() => this.resetInventario()}
                      onKeyUp={(event) => this.aplicarCambios(event, index)}/>
                  <input 
                      className={`w-full text-center mt-1 p-2 bg-transparent`} 
                      value={proveedor.telefono }
                      onChange={ event => this.onChangeInput(event, index, "telefono")}
                      onBlur={() => this.resetInventario()}
                      onKeyUp={(event) => this.aplicarCambios(event, index)}/>
                  <input 
                      className={`w-full text-center mt-1 p-2 bg-transparent`} 
                      value={proveedor.email}
                      onChange={ event => this.onChangeInput(event, index, "email")}
                      onBlur={() => this.resetInventario()}
                      onKeyUp={(event) => this.aplicarCambios(event, index)}/>
                  <input 
                      className={`w-full text-center mt-1 p-2 bg-transparent`} 
                      value={proveedor.direccion}
                      onChange={ event => this.onChangeInput(event, index, "direccion")}
                      onBlur={() => this.resetInventario()}
                      onKeyUp={(event) => this.aplicarCambios(event, index)}/>
                  
                  <input 
                      className={`w-full text-center mt-1 p-2 bg-transparent`} 
                      value={proveedor.nombre}
                      onChange={ event => this.onChangeInput(event, index, "nombre")}
                      onBlur={() => this.resetInventario()}
                      onKeyUp={(event) => this.aplicarCambios(event, index)}/>
                  <div className={`w-full flex justify-evenly items-center p-2 mt-1 bg-transparent `}>
                    <button className='bg-red-500 rounded p-1 px-2 mr-2' onClick={ () => this.eliminarItem(proveedor.id_inventario, proveedor.nombre)} >D</button>
                    <button onClick={ () => this.abrirDetallesProveedor(proveedor.id_proveedor) }>
                      <svg
                          class='h-6 w-6 text-white'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          stroke='currentColor'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'>
                          {" "}
                          <path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7' />{" "}
                          <path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z' />
                      </svg>
                    </button>
                  </div>
                </div>
              )
            })
          }
          
          {
            this.state.modalVisible && (
              <ProveedorDetalles cerrarModal={this.cerrarModal} proveedorSelect={this.state.proveedorSelect} getProveedores={this.getProveedores}/>
            )
          }
        </div>
      </div>
    );  
  }
  
}

export default ProveedoresPrincipal;
