/** @format */
import axios from "axios";
import { APIURL } from "./Host";
class ActivacionesService{
    activaciones_lista(dato){
        console.log("en el servide id:"+dato);
        return axios.post(APIURL+"/activaciones_list/",dato);
    }
    
    guardarRegistroActivacionBD(dato){
        return axios.post(APIURL+"/guardar_registro_activacion_bd/",dato);
    }

    realizarActivacionBSSMovil(info){
        const data = {
            "identifier_value": info.identifier_value,
            "identifier_type": "iccid",
            "offer_id": parseInt(info.offerId),
            "schedule_date": ""
        }
        return axios.post( 'https://playcell.live.bssmovil.com/activations',data,{
			headers: {
			"Content-type": "application/json; charset=UTF-8",
            'Accept': 'application/json', 
			Authorization: 'Bearer ' + info.brer
		}});
        

    }



}

export default new ActivacionesService()