import axios from 'axios';
import { APIURL } from './Host';
import AdministrativoService from './AdministrativoService';


class PortabilidadesService{

    loginPlayCell = async() =>{
        return await AdministrativoService.loginPlaycel().then((res1) => {
            return res1.data?.token;
		}).catch(err =>{
            return "";
        });
    }

    realizarPortabilidad = async(info) => {
        const brer = await this.loginPlayCell();
        const data = {
            "number": info.number,
            "nip": info.nip,
            "client_name": info.client_name,
            "client_email": info.client_email,
            "client_contact_number": info.client_contact_number,
            "date": info.date,
        }
		return await axios.post("https://playcell.live.bssmovil.com/portability-requests/express",data,{
			headers: {
				Accept: "application/json",
				Authorization: 'Bearer ' + brer,
		}}) 
	}


    ListarPortabilidades(id_usuario){
        console.log("Listar porta service++"+id_usuario);
        const datas={
            "id_usuario":id_usuario
        }
        return axios.post(`${APIURL}/portabilidades`,datas);
    }

    PortabilidadById(idPortabilidad){
        return axios.post(`${APIURL}/portabilidades/${idPortabilidad}`);
    }

    InsertarPortabilidad(portabilidades){
        const data = {
            "cliente_id": portabilidades.id_cliente,
            "telefono": portabilidades.telefono,
            "date_portabilidad":portabilidades.date,
			"imei": portabilidades.imei,
            "iccid": portabilidades.iccid,
            "nip": portabilidades.nip,
            "user_id": portabilidades.user_id,
            "target_partner_id": portabilidades.target_partner_id,
            "id_portabilidad_altan": portabilidades.id_portabilidad_altan,
            "register_by": portabilidades.register_by,
			"status": portabilidades.status,
            "num_transitorio":portabilidades.num_transitorio
        }
        console.log({data})
        return axios.post(`${APIURL}/portabilidades/insert`, data);
    }

    ActualizarStatusPortabilidad(portabilidades){
        const data = {
            "status": portabilidades.status,
            "id_portabilidad": portabilidades.id_portabilidad
        }
        return axios.post(`${APIURL}/portabilidades/update/status`, data);
    }

    consultarIccid(iccid,token) { 
		return axios.get("https://playcell.live.bssmovil.com/profile/"+(iccid !== undefined ? iccid : 0)+"/iccid",{
		 	headers: {
		 		Accept: "application/json",
		 		Authorization: 'Bearer ' + (token !== undefined ? token : "")
		 	} 
		 });
	}

}

export default new PortabilidadesService();