import React from 'react'
import AdministrativoService from 'services/AdministrativoService';

class ModalValidar extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        imei: '',
        isValid: false,
        isLoading: false,
        mostrar:false,
        mensajerror:'',
        data:{}
      };
    }
  
    componentDidImei(){
      document.getElementById('inputImei').select();
    }
    cerrarModal = () =>{
      this.props.cerrarModal();
    }

    validarIMEI(imei) {
        const imeiRegex = /^\d{15}$/; // Expresión regular para verificar que el IMEI tiene exactamente 15 dígitos
        const esNumerico = /^\d+$/; // Expresión regular para verificar que el IMEI solo contiene números
    
        if (!imeiRegex.test(imei) || !esNumerico.test(imei)) {
          // El IMEI no cumple con los criterios de validación
          return false;
        }
    
        // El IMEI es válido
        return true;
    }
      
    handleChange(event) {
        const imei = event.target.value;
        const isValid = this.validarIMEI(imei);
        if(imei.length===0){
            this.setState({mensajerror: ""}); 
        }
        this.setState({
          imei: imei,
          isValid: isValid
        });
    }

    consultarIMEI(){
        this.setState({ isLoading: true });
        this.setState({ mostrar: false });
        this.setState({ mensajerror: "" });
        let token="";
		AdministrativoService.loginPlaycel().then(response =>{
	        token=response.data.token.split("|")[1];
            AdministrativoService.validarImei(this.state.imei,token).then(response =>{
                this.setState({ data: response.data });
                this.setState({ mostrar: true });
              }).catch(err =>{
                this.setState({ mostrar: false });
                this.setState({mensajerror:"IMEI no valido"});
              }).finally(() => {
                // Deshabilitar isLoading
                this.setState({ isLoading: false });
                
              });
		  })
    }
  
    render() {
        return (
          <>
            <div className='fixed inset-0 z-50 flex flex-wrap justify-center items-center'>
              <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}></div>
      
              <form onSubmit={event => this.consultarIMEI()} className='w-1/3 z-100 relative flex flex-col justify-center items-center bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white'>
              <h2 className="text-2xl font-bold mb-5 text-center">Validar IMEI</h2>
                <div className='w-full flex justify-center'>
                <div className='w-1/2 h-20 flex flex-col'>
                      <label className='mr-1 text-lg font-bold'>IMEI: </label>
                      {/* TODO CAMBIAR EL VALUE */}
                      <input type="text" id='inputInventarioModal' className={`rounded-lg p-2 text-black text-xl `} value={this.state.imei} onChange={event => this.handleChange(event)}/>
                      {this.state.imei.length>0 && !this.state.isValid && (
                        <span className="text-red-500" style={{ fontSize: '1.2rem' }}>IMEI no válido</span>
                        )}
                </div> 
                </div>
      
                <div className='w-full flex justify-center mt-5'>
                  <button className={`${this.state.isValid ?  'focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' : 'w-auto bg-gray-500 rounded-lg font-medium text-white px-4 py-2 cursor-not-allowed'}`} type='submit' disabled={!this.state.isValid}>
                  {this.state.isLoading ? (
                    'CARGANDO'
                    ) : (
                    'CONSULTAR'
                    )}
                  </button>
                </div>
                {/* Sección de etiquetas */}
                {this.state.mostrar ? (<div className='w-full flex flex-wrap justify-center mt-5' >
            {/* Etiquetas centradas */}
            <div className="w-full flex justify-center">
              <h1 className='text-center' style={{ fontSize: '1.8rem' }}>Resultado de la búsqueda</h1>
            </div>
            <div className="w-full flex justify-center">
            <h1 className='text-center' style={{ fontSize: '1.6rem' }}>IMEI {this.state.data.imei.imei} </h1>
            </div>
            <div className="w-full flex justify-center">
              <label className='text-center' style={{ fontSize: '1.2rem'}}>{this.state.data.imei.homologated}</label>
            </div>
            <div className="w-full flex justify-center">
              <label className='text-center' style={{ fontSize: '1.2rem'}}>{this.state.data.imei.sub_category}</label>
            </div>
            <div className="w-full flex justify-center">
              <label className='text-center' style={{ fontSize: '1.2rem'}}>Dispositivo</label>
            </div>
            <div className="w-full flex justify-center">
              <label className='text-center' style={{ fontSize: '1rem'}}>{this.state.data.imei.sub_category}</label>
            </div>
            <div className="w-full flex justify-center">
              <label className='text-center' style={{ fontSize: '1rem'}}>band28: {this.state.data.device_features.band28}</label>
            </div>
            <div className="w-full flex justify-center">
              <label className='text-center' style={{ fontSize: '1rem'}}>brand: {this.state.data.device_features.brand}</label>
            </div>
            <div className="w-full flex justify-center">
              <label className='text-center' style={{ fontSize: '1rem'}}>model: {this.state.data.device_features.model}</label>
            </div>
            <div className="w-full flex justify-center">
              <label className='text-center' style={{ fontSize: '1rem'}}>volteCapable: {this.state.data.device_features.volteCapable}</label>
            </div>
          </div>):(<div className="w-full flex justify-center">
              <h1 className='text-center' style={{ fontSize: '1.8rem' }}>{this.state.mensajerror}</h1>
            </div>)}       
              </form>
            </div>
          </>
        );
    }
      
    
  }
  
  export default ModalValidar;
  
  