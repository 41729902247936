import React from 'react'
import ClientesService from 'services/ClientesService';
import sweetalert from "sweetalert2"
import InventarioService from '../services/InventarioService';
import ProveedoresService from '../services/ProveedoresService';
import ResguardosService from '../services/ResguardosService';
import SucursalesService from '../services/SucursalesService';
import ClientesDetalles from './ClientesDetalles';
import InventarioDetalles from './InventarioDetalles';
import InventarioModal from './InventarioModal';
import ResguardoDetalles from './ResguardosDetalles';
import UsuariosModal from './usuariosModal';

class ClientesPrincipal extends React.Component {

  constructor(){
    super();
    this.state = {
      clientesInicial: [],
      clientesModificado: [],
      proveedores: [],
      usuarios: [],
      modalVisible: false,
      modalProvedores: false,
      modalUsuarios: false,
      usuario_aSelect: true,
      clienteSelect: 0,
      indexCliente: 0,
    }
  }

  cerrarModal = () =>{
    this.setState({modalVisible: false})
  }
  cerrarmodalProveedores = () =>{
    this.setState({modalProveedores: false})
  }
  cerrarModalUsuarios = () =>{
    this.setState({modalUsuarios: false})
  }

  componentDidMount(){
    this.getClientes();
    this.getProveedores();
  }

  getClientes = () =>{
    ClientesService.ListarClientes().then(response =>{
      this.setState({clientesInicial: response.data, clientesModificado: response.data})
      console.log(response.data);
    }).catch(err =>{
      console.log('Error en listarClientes ', err)
    })
  }

  getProveedores = () =>{
    ProveedoresService.ListarProveedores().then(response =>{
        this.setState({proveedores: response.data});
        console.log(response.data)
    }).catch(err =>{
        console.log("Error en getProveedores ", err);
    })
  }

  getProveedorNombre = (idProveedor) =>{
    let proveedor = this.state.proveedores.find(proveedor =>{
      return proveedor.id_proveedor === idProveedor;
    })

    if(proveedor) return proveedor.nombre;
    else return '';
  }

  cambiarProveedor = (event, index) =>{
    let clientesModificado = this.state.clientesModificado.map((item, i) =>{
      if(index === i){
        return {...item, distribuidor: event.target.value}
      }else{
        return item;
      }
    })

    this.setState({clientesModificado: clientesModificado});

    if(this.state.clientesInicial === clientesModificado) return;
    sweetalert
      .fire({
        text:`Seguro quiere cambiar el proveedor`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const usuario = sessionStorage.getItem("id");
          const data = {...clientesModificado[index], lu_by: usuario};
          ClientesService.ActualizarCliente(data).then(response =>{
            console.log("Actualizado con exito, ", response);
            this.getClientes();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al actualizar los datos",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log("Error en aplicarCambios, ", err);
          });
        }
      });  
  }

  cambiarSelect = (event, index, input, message) =>{
    let clientesModificado = this.state.clientesModificado.map((item, i) =>{
      if(index === i){
        return {...item, [input]: event.target.value}
      }else{
        return item;
      }
    })

    this.setState({clientesModificado: clientesModificado});

    if(this.state.clientesInicial === clientesModificado) return;
    sweetalert
      .fire({
        text:`Seguro quiere cambiar ${message}`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const usuario = sessionStorage.getItem("id");
          const data = {...clientesModificado[index], lu_by: usuario};
          ResguardosService.ActualizarResguardo(data).then(response =>{
            console.log("Actualizado con exito, ", response);
            this.getClientes();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al actualizar los datos",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log("Error en aplicarCambios, ", err);
          });
        }
      }); 
  }

  onChangeInput = (event, index, input) =>{
    let clientesModificado = this.state.clientesModificado.map((item, i) =>{
      if(index === i){
        return {...item, [ input ]: event.target.value}
      }else{
        return item;
      }
    })

    this.setState({clientesModificado: clientesModificado});
  }

  resetClientes = () =>{
    this.setState({clientesModificado: this.state.clientesInicial});
  }

  aplicarCambios = (event, index) =>{
    if(!(event.code === "Enter")) return;
    if(this.state.clientesInicial === this.state.clientesModificado) return;
    
    const usuario = sessionStorage.getItem("id");
    const data = {...this.state.clientesModificado[index], lu_by: usuario};
    ClientesService.ActualizarCliente(data).then(response =>{
      console.log("Actualizado con exito, ", response);
      this.getClientes();
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al actualizar los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log("Error en aplicarCambios, ", err);
    });
  }

  eliminarItem = (id_cliente) =>{
    sweetalert
      .fire({
        text:`Seguro quiere eliminar el cliente`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const data = {
            status: 0,
            id_cliente: id_cliente
          }
          ClientesService.ActualizarStatusCliente(data).then(response =>{
            this.getClientes();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al eliminar el resguardo",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log(err);
          })
        }
      }); 
  }

  abrirDetallesCliente(idCliente){
    this.setState({modalVisible: true, clienteSelect: idCliente});
  }

  abrirUsuariosModal(index, usuario_aSelect) {
    this.setState({modalUsuarios:true, indexResguardo: index, usuario_aSelect})
  }

  cambiarUsuario = (usuario) =>{
    let clientesModificado = this.state.clientesInicial.map((item, i) =>{
      if(this.state.indexCliente === i){
          return {...item, usuario_id: usuario.id_usuario, usuario: usuario}
      }else{
        return item;
      }
    })

    this.setState({clientesModificado: clientesModificado});

    if(this.state.clientesInicial == clientesModificado) return;
    sweetalert
      .fire({
        text:`Seguro quiere cambiar el usuario"}`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const usuario = sessionStorage.getItem("id");
          const data = {...clientesModificado[this.state.indexCliente], lu_by: usuario};
          ClientesService.ActualizarCliente(data).then(response =>{
            console.log("Actualizado con exito, ", response);
            this.getClientes();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al actualizar los datos",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log("Error en aplicarCambios, ", err);
          });
        }
      });
  }

  render(){
    return (
      <div className='bg-secondary_2 bg-opacity-30 text-white p-10'>
        <h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
        Clientes
					</h1>

					<div className="flex justify-start mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Clientes</span>
									</div>
								</li>
							</ol>
						</nav>
					</div>
        <div className='flex justify-end p-3'>
          <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirDetallesCliente(0) }><i class='fas fa-plus-circle'></i> Agregar Cliente</button>

        </div>
        <div className='h-11/12 w-full p-2'>
          <div className='flex flex-row gap-1'>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Usuario</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Distribuidor</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Email</h4>
            <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Acciones</h4>
          </div>
          {
            this.state.clientesModificado.map((item, index) =>{
              return (
                <div key={item.id_resguardo} className='flex justify-center flex-row w-full mt-5 rounded-2xl md:w-full h-auto px-5 py-5 bg-secondary_2'>
                  <p className={`w-full text-center mt-1 p-2 bg-transparent`} 
                     onClick={() =>  this.abrirUsuariosModal(index, false) }>
                    {`${item.usuario.nombre} ${item.usuario.app_paterno} ${item.usuario.app_materno}`}
                  </p>
                  <select
                      className={`w-full text-center mt-1 p-2 bg-transparent`}
                      value={item.suc_id_origen}
                      onChange={(event) => this.cambiarSelect(event, index, "suc_id_origen", "la sucursal de origen")}
                      onBlur={() => this.resetClientes()}
                      >
                        {
                          this.state.proveedores.map(proveedor =>{
                            return <option key={proveedor.id_proveedor} value={proveedor.id_proveedor} >{this.getProveedorNombre(proveedor.id_proveedor)}</option>
                          })
                        }

                  </select>
                  <input 
                      className={`w-full text-center mt-1 p-2 bg-transparent`} 
                      value={item.email}
                      onChange={ event => this.onChangeInput(event, index, "email")}
                      onBlur={() => this.resetClientes()}
                      onKeyUp={(event) => this.aplicarCambios(event, index)}/>
                  
                  <div className={`w-full flex justify-evenly items-center p-2 mt-1 bg-transparent `}>
                    <button className='bg-red-500 rounded p-1 px-2 mr-2' onClick={ () => this.eliminarItem(item.id_cliente)} >D</button>
                    <button onClick={ () => this.abrirDetallesCliente(item.id_cliente) }>
                      <svg
														class='h-6 w-6 text-white'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'>
														{" "}
														<path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7' />{" "}
														<path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z' />
											</svg>
                    </button>
                  </div>
  
                </div>
              )
            })
          }
          {
            this.state.modalVisible && (
              <ClientesDetalles cerrarModal={this.cerrarModal} clienteSelect={this.state.clienteSelect} getClientes={this.getClientes}/>
            )
          }
        
          {
            this.state.modalUsuarios && (
              <UsuariosModal cerrarModal={this.cerrarModalUsuarios} cambiarUsuario={this.cambiarUsuario} />
            )
          }
        </div>
      </div>
    );  
  }
  
}

export default ClientesPrincipal;
