import { Fragment } from "react";
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom"
import AdministrativoService from "services/AdministrativoService"
import swal from 'sweetalert2';
import ImgCargando from "../images/icono_cargando.svg"
import axios from "axios"
import ActivacionesService from "services/ActivacionesService";
import GeneralService from "services/GeneralService";
import { sweetAlert } from "sweetalert2/dist/sweetalert2";
const { Component } = require("react-image-crop");

class Activaciones extends Component{

	constructor(props) {
		super(props);
		this.state = {
			id_usuario : sessionStorage.getItem("id"),
			rol: sessionStorage.getItem("roles"),
			mostrarModalPagar : false,
			activacionesLista:[],
			mostrarModalActivacion : false,
			listaPaquetes : [],
			brer : "",
			montoPagar: 0,
			paquete: 0,
			telefonosUsuario : "",
			identificadorNumero : "",
			cargandoModalbtn : false,
			brer : "",
			activarBoton:false
		};
	}
	listActivaciones= () =>{
		AdministrativoService.loginPlaycel().then((res1) => {
			if (res1.status===200){
				AdministrativoService.obtenerPaquetesPlaycel(res1.data).then((res2) => {
					if (res2.status===200){
						this.setState({listaPaquetes : res2.data, obteniendoInformacion : false, brer : (res1.data.token !== undefined ? res1.data.token : "")},()=>{
							this.state.id_usuario=GeneralService.permutaIdUsuario(this.state.id_usuario)
							ActivacionesService.activaciones_lista({id_usuario:this.state.id_usuario}).then(response =>{
								if (response.lenght ===0)
								{
									sweetAlert.fire({
										text:"No hay activaciones ",
										allowOutsideClick: false,
										allowEscapeKey: false
									})
								}
								this.setState({activacionesLista:response.data})
								console.log(response.data);
							}).catch(err =>{
								sweetAlert.fire({
									text:"Error al cargar los datos",
									allowOutsideClick: false,
									allowEscapeKey: false
								})
								console.log("Error-->",err);
							});
						});
					} else {
						swal.fire({
							icon: 'error',
							title: 'Error',
							text: "No se pudo obtener los paquetes de Playcel",
						});
					}
				});
			} else {
				swal.fire({
					icon: 'error',
					title: 'Error',
					text: "No se pudo concetar con el sistema de Playcel",
				});
			}
		});
	}
	componentDidMount(){
		this.listActivaciones();
	}	


	abrirDetallesCliente=(id)=>{
		this.setState({mostrarModalActivacion : true});
	}

	realizarActivacion=()=>{
		
		console.log("SCOT: ",this.state.identificadorNumero);
		this.setState({activarBoton:true});
		if (this.state.identificadorNumero!==""){
			console.log("mi activarboton : ",this.state.activarBoton);
			let tthis = this;
			var config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: 'https://playcell.live.bssmovil.com/activations',
				headers: { 
					'Content-Type': 'application/json', 
					'Accept': 'application/json', 
					'Authorization': 'Bearer ' + this.state.brer
				},
				data : {
					"identifier_value": this.state.identificadorNumero+"",
					"identifier_type": "iccid",
					"offer_id": parseInt(this.state.paquete),
					"schedule_date": ""
				}
			};
			axios(config).then(function (res45) {
				if (res45.status===201){
					if (res45.data !== undefined){
						ActivacionesService.guardarRegistroActivacionBD(res45.data).then((res1) => {
							swal.fire({
								icon: 'success',
								title: 'Realizado',
								text: "La activación se realizó exitosamente",
							});
							this.setState({activarBoton:false});
							this.listActivaciones();
						});
					}
				}
			}).catch(err =>{
                this.setState({ activarBoton: false });
                if(err.response.status==400){
					swal.fire({
						icon: 'error',
						title: 'Error',
						text: `${err.response.data.error}`,
					});
				}else{
					swal.fire({
						icon: 'error',
						title: 'Error',
						text: "No se pudo realizar la activación, consulte a soporte técnico",
					});
				}
              });
		} else {
			swal.fire({
				icon: 'error',
				title: 'Error',
				text: "Debe agregar un número de identificación del número celular",
			});
			this.setState({disableBotonesModal : false, cargandoModalbtn: false,activarBoton:false});
		}
	}

render(){
		return (
		  <div className='bg-secondary_2 bg-opacity-30 text-white p-10'>
			<h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
			ACTIVACIONES
						</h1>
	
						<div className="flex justify-start mx-10 mt-4">
							<nav class="flex" aria-label="Breadcrumb">
								<ol class="inline-flex items-center space-x-1 md:space-x-3">
									<li class="inline-flex items-center">
										<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
											<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
											Home
										</a>
									</li>
									
									<li aria-current="page">
										<div class="flex items-center">
											<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
											<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Activaciones</span>
										</div>
									</li>
								</ol>
							</nav>
						</div>
			<div className='flex justify-end p-3'>
			  <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirDetallesCliente(0) }><i class='fas fa-plus-circle'></i> NUEVA ACTIVACION</button>
	
			</div>
			<div className='h-11/12 w-full p-2'>
			  <div className='flex flex-row gap-1'>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>ID</h4>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>TIPO</h4>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>NUMERO</h4>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>STATUS</h4>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>OFERTA_ID</h4>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>ACTUALIZACION</h4>
				<h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>SOLICTUD</h4>
			  </div>
			  {
				this.state.activacionesLista.map((item, index) =>{
				  return (
					<div className='flex justify-around flex-row w-full mt-5 rounded-2xl md:w-full h-auto px-5 py-5 bg-secondary_2'>
						<h1 className="w-1/6 text-white text-left pl-3 md:pl-5 text-lg font-barlow ">{item.ID_Activaciones}</h1>
						<h1 className="w-1/6 text-white text-center pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.identifier_type}</h1>
						<h1 className="w-1/6 text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.msisdn}</h1>
						<h1 className="w-1/6 text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.status}</h1>
						<h1 className="w-1/6 text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.offer_id}</h1>
						<h1 className="w-1/6 text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.updated_at}</h1>
						<h1 className="w-1/6 text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{item.created_at}</h1>
			  </div>
				  )
				})
			  }
			  
			
			  
			</div>
			
			{this.state.mostrarModalActivacion && (
					<Fragment>
						<div className="overflow-y-hidden text-color3">
							<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
								<div className='relative w-auto my-6 mx-auto max-w-2xl z-20'>
									<div className='rounded-3xl relative flex flex-col w-full outline-none focus:outline-none shadow-sm2 bg-white pt-4'>
										<div className=' rounded-t-3xl'>
											<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Activacion</p>
										</div>
										<div className='relative flex-auto'>
											<div className='justify-center items-center pb-10 pl-10 pr-10'>
												<div className="grid grid-cols-1 w-full">
													<label htmlFor="paquete" className="md:text-sm text-xs text-light font-semibold">
														Seleccionar Activación: <span className="text-red-500 text-xl">*</span>
													</label>
													<select
														id="paquete"
														className="mt-1 rounded-lg p-2 border outline-none bg-white focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk"
														name="paquete"
														defaultValue={this.state.paquete}
														onChange={(e)=>{ this.setState({paquete : e.target.value, montoPagar : e.target.montoPagar}); }}
														required >
														<option key={0} value={""} montoPagar={""}></option>
														{this.state.listaPaquetes.map((gg,hh)=>{
															if (gg.commercial_name.trim().slice(-6) === "Activa"){
																return <option key={hh} value={gg.id} montoPagar={gg.price}>{gg.commercial_name}, Precio normal: {gg.retail_price}, Precio especial: {gg.price}</option>
															}
														})}
													</select>
												</div>
												<div className="grid grid-cols-1 w-full">
													<label htmlFor="identificadorNumero" className="md:text-sm text-xs text-light font-semibold">Escribir identificador:</label>
													<input
														className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk"
														type="tel"
														name="identificadorNumero"
														placeholder="Identificador del número telefónico."
														value={this.state.identificadorNumero}
														onChange={(e)=>{ this.setState({identificadorNumero : e.target.value}); }}/>
												</div>
											</div>
										</div>
										<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
											<div>
												<button type="button" disabled={this.state.activarBoton} className={`${this.state.activarBoton ? 'w-auto bg-green-800 rounded-lg font-medium text-white px-4 py-2 cursor-not-allowed mr-3':  'focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer mr-3'}`} onClick={(e)=>{ this.realizarActivacion();}}>
													{this.state.activarBoton ? (
														'Cargando'
														) : (
														'Activar teléfonos'
														)}
													</button>
												<button type="button" onClick={()=>{ this.setState({mostrarModalActivacion:false});}} className="focus:outline-none focus:ring-2 w-auto bg-blue-700 hover:bg-blue-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" >Cancelar</button>
											</div>
										</div>
									</div>
								</div>
								<div onClick={ ()=> {this.setState({mostrarModalActivacion:false}); }} className={' cursor-pointer opacity-25 fixed inset-0 bg-black'}></div>
							</div>
						</div>
					</Fragment>
				)}

			</div>
		);  
	}
}
export default withRouter(Activaciones) 