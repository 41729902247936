import { Fragment } from "react";
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom"
import AdministrativoService from "services/AdministrativoService"
import GeneralService from "services/GeneralService"
import clientes from "../images/prospecto_white.png"
import recarga_140 from "../images/140.png"
import recarga_300 from "../images/300.png"
import recarga_420 from "../images/420.png"
import recarga_550 from "../images/550.png"
import sucursales from "../images/chain-store.png"
import swal from 'sweetalert2';
import ImgCargando from "../images/icono_cargando.svg"
import axios from "axios"
import ActivacionesService from "services/ActivacionesService";

const { Component } = require("react-image-crop");

class RecargasModal extends Component{

	constructor(props) {
		super(props);
		this.state = {
			mostrarModalPagar : false,
			username : "",
			password : "",
			usuario : "",
			pass : "",
			montoPagar : 0,
			bearer : "",
			id_usuario : sessionStorage.getItem("id"),
			checkout_id : 0, 
			token : "",
			public_key : "",
			errorMessage : "",
			listaPaquetes: [],
			telefonosUsuario : [],
			telefonoRecarga : "",
			telefonoRecarga2 : "",
			telefonoRecargaSi : "",
			mostrarModalTelefono : false,
			disableBotonesModal : false,
			obteniendoInformacion : true,
			brer : "",
			cargandoModalbtn: false,
			cargando:false,
			offerId : 0,
			valido:false,
			recarga:[],
			listaPaquetes : [],
			obteniendoInformacion : false,
			brer : "",
			identificadorNumero : "",
			activarBoton:false
		};
	}

	componentDidMount() {
		AdministrativoService.obtenerInformacionUsuario({idUsu: this.state.id_usuario}).then((res) => {
			if (res.status===200){
				if (res.data !== undefined){
					this.setState({
						telefonosUsuario: (res.data[0] !== undefined ? res.data[0] : []),
						telefonoRecarga : (res.data[0] !== undefined ? (res.data[0].Telefono1 !== "" ? res.data[0].Telefono1 : (res.data[0].Telefono2 !== "" ? res.data[0].Telefono2 : "otro")) : "otro")
					},()=>{
						AdministrativoService.loginPlaycel().then((res1) => {
							if (res1.status===200){
								AdministrativoService.obtenerPaquetesPlaycel(res1.data).then((res2) => {
									console.log({res2: res2.data})
									if (res2.status===200){

										this.setState({listaPaquetes : res2.data, obteniendoInformacion : false, brer : (res1.data.token !== undefined ? res1.data.token : "")},()=>{
											
										});
									} else {
										swal.fire({
											icon: 'error',
											title: 'Error',
											text: "No se pudo obtener los paquetes de Playcel",
										});
									}
								});
							} else {
								swal.fire({
									icon: 'error',
									title: 'Error',
									text: "No se pudo concetar con el sistema de Playcel",
								});
							}
						});
					});
				} else {
					swal.fire({
						icon: 'error',
						title: 'Error',
						text: "No se pudo obtener la información del usuario",
					});
				}
			} else {
				swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "No se pudo obtener la información del usuario",
                });
			}
		});
	}
	
	validarTelefonoRecarga=(e)=>{
		let tthis = this;
		//this.state.listaPaquetes.filter(oferta => oferta.offer_classification === "recharge").filter()
		let datos=this.state.listaPaquetes.filter(oferta => oferta.id == this.state.offerId);
		this.setState({montoPagar : datos[0].price,telefonoRecargaSi : "" });
		this.setState({telefonoRecarga2 : e.target.value});
		if(GeneralService.validarLongitud(e.target.value,10) && GeneralService.validarNumeros(e.target.value)){
			this.setState({disableBotonesModal : true, cargandoModalbtn: true,cargando:true},()=>{
				if(this.props.esActivacion){
					if(this.state.telefonoRecarga2 != ''){
						const recarga = {
							montoPagar: tthis.state.montoPagar, 
							mostrarModalTelefono: tthis.state.mostrarModalTelefono,  
							offerId: tthis.state.offerId , 
							telefonoRecarga2: tthis.state.telefonoRecarga2,
							telefonoRecargaSi: tthis.state.telefonoRecargaSi, 
							telefonoRec: tthis.state.telefonoRecarga2,
						}
					   
						tthis.seleccionarRecarga(recarga);
						tthis.cerrarModal();
					}else{
						swal.fire({
							icon: 'error',
							title: 'Error',
							text: "Falta escribir un código de activación correcto",
						});
					}
				}else{
					
					let telefonoRec = this.state.telefonoRecarga2;
					if (telefonoRec!==""){
						axios.get("https://playcell.live.bssmovil.com/profile/"+(telefonoRec !== undefined ? telefonoRec : 0)+"/msisdn",{
							headers: {
								"Content-type": "application/json",
								Accept: "application/json",
								Authorization: 'Bearer ' + (this.state.brer !== undefined ? this.state.brer : "")
							} 
						}).then(res=>{
							
							
							if (res.status===200){
								if (res.data.mvno !== undefined ? (res.data.mvno.beId!==undefined?parseInt(res.data.mvno.beId)===205:false) : false){
									if (res.data.subscriber !== undefined ? (res.data.subscriber.status!==undefined?(res.data.subscriber.status.status!==undefined?res.data.subscriber.status.status==="Active":false):false) : false){
										tthis.setState({telefonoRecargaSi: telefonoRec}, ()=>{
											const recarga = {
												montoPagar: tthis.state.montoPagar, 
												mostrarModalTelefono: tthis.state.mostrarModalTelefono,  
												offerId: tthis.state.offerId , 
												telefonoRecarga2: tthis.state.telefonoRecarga2,
												telefonoRecargaSi: tthis.state.telefonoRecargaSi, 
												telefonoRec: telefonoRec,
											}
											this.setState({recarga : recarga});
											this.setState({ valido: true });
											this.setState({ cargando: false });
											//tthis.seleccionarRecarga();
											//tthis.cerrarModal();
											// tthis.abrirModalConekta();
										});
									} else {
										swal.fire({
											icon: 'error',
											title: 'Error',
											text: "El numero a recargar esta inactivo, consulte a soporte técnico",
										});
										this.setState({ mostrar: false });
										this.setState({disableBotonesModal : false, cargandoModalbtn: false});
									}
								} else {
									this.setState({ mostrar: false });
									swal.fire({
										icon: 'error',
										title: 'Error',
										text: "El número proporcionado no es operado por Playcell",
									});
									this.setState({disableBotonesModal : false, cargandoModalbtn: false});
								}
							} else {
								this.setState({ mostrar: false });
								swal.fire({
									icon: 'error',
									title: 'Error',
									text: "Error al validar el número teléfonico",
								});
								this.setState({disableBotonesModal : false, cargandoModalbtn: false});
							}
						}).catch(error=>{
							swal.fire({
								icon: 'error',
								title: 'Error',
								text: "Error al validar el número teléfonico, El número proporcionado no es operado por Playcell.",
							});
							this.setState({disableBotonesModal : false, cargandoModalbtn: false});
						});
					} else {
						swal.fire({
							icon: 'error',
							title: 'Error',
							text: "Falta escribir un número telefonico correcto",
						});
						this.setState({disableBotonesModal : false});
					}
				}
			});
		}
		else{
			console.log("datos incorrectos")
		}
		 
	}

	realizarActivacion=()=>{
		
		this.setState({activarBoton:true});
		if (this.state.identificadorNumero!==""){
			let tthis = this;
			var config = {
				method: 'post',
				maxBodyLength: Infinity,
				url: 'https://playcell.live.bssmovil.com/activations',
				headers: { 
					'Content-Type': 'application/json', 
					'Accept': 'application/json', 
					'Authorization': 'Bearer ' + this.state.brer
				},
				data : {
					"identifier_value": this.state.identificadorNumero+"",
					"identifier_type": "iccid",
					"offer_id": parseInt(this.state.offerId),
					"schedule_date": ""
				}
			};
			axios(config).then(function (res45) {
				if (res45.status===201){
					if (res45.data !== undefined){
						ActivacionesService.guardarRegistroActivacionBD(res45.data).then((res1) => {
							swal.fire({
								icon: 'success',
								title: 'Realizado',
								text: "La activación se realizó exitosamente",
							});
							this.setState({activarBoton:false});
							//this.listActivaciones();
						});
					}
				}
			}).catch(err =>{
                this.setState({ activarBoton: false });
                if(err.response.status==400){
					swal.fire({
						icon: 'error',
						title: 'Error',
						text: `${err.response.data.error}`,
					});
				}else{
					swal.fire({
						icon: 'error',
						title: 'Error',
						text: "No se pudo realizar la activación, consulte a soporte técnico",
					});
				}
              });
		} else {
			swal.fire({
				icon: 'error',
				title: 'Error',
				text: "Debe agregar un número de identificación del número celular",
			});
			this.setState({disableBotonesModal : false, cargandoModalbtn: false,activarBoton:false});
		}
	}
	abrirModalConekta=()=>{
		AdministrativoService.obtenerUsuarioConekta({idUsu: this.state.id_usuario}).then((res) => {
			if (res.status===200){
				if (res.data.length === 2){
					this.setState({
						username: res.data[0][0].usu,
						password: res.data[0][0].pass,
						usuario: res.data[1][0].Username,
						pass: res.data[1][0].Password,
					},() => {
						const user = {
							username: this.state.username,
							password: this.state.password
						};
						const userStr = JSON.stringify(user);
						
						AdministrativoService.autorizacion(userStr).then((res2) => {
							let bearer = res2.headers.authorization.split("Bearer ")[1];
							this.setState({ bearer: res2.headers.authorization.split("Bearer ")[1] });
							const user_data = {
								username: this.state.usuario,
								password: this.state.pass,
								id_usuario: this.state.id_usuario
							};
							const userStrdata = JSON.stringify(user_data);
							
							AdministrativoService.registrar_userjwt(bearer, userStrdata).then((resp3) => {
								AdministrativoService.token(bearer).then((resp3) => {
									let checkout_id = resp3.data.checkout.id;
									let token_id = resp3.data.id;
									this.setState({ checkout_id: checkout_id, token: token_id });
									AdministrativoService.obtener_llave(bearer).then((resp4) => {
										var encodedStringAtoB = resp4.data;
										var decodedStringAtoB = atob(encodedStringAtoB);
										this.setState({public_key: decodedStringAtoB, disableBotonesModal : false, cargandoModalbtn: false, mostrarModalTelefono: false, mostrarModalPagar: true},()=>{
											if (this.state.mostrarModalPagar) {
												this.conektaframe();
											}
										});
									}, error => { });
								}, error => { });
							}, error => { });
						}).catch(error => {
							this.setState({ errorMessage: error.toString() });
						});
					});
				}
			}
		});
	}

	conektaframe() {
        const $this = this;
        let checkout = this.state.checkout_id;
        let key = this.state.public_key;
        window.ConektaCheckoutComponents.Card({
            targetIFrame: "#conektaModal",
            allowTokenization: true,
            checkoutRequestId: checkout, 
            publicKey: key, 
            options: {
                styles: {
                    inputType: 'rounded',
                    buttonType: 'rounded',
                    states: {
                        empty: {borderColor: '#FFAA00'},
                        invalid: { borderColor: '#FF00E0'},
                        valid: { borderColor: '#0079c1' }
                    }
                },
                languaje: 'es',
                button: { colorText: '#ffffff', backgroundColor: '#301007' },
                iframe: { colorText: '#65A39B', backgroundColor: '#FFFFFF' }
            },
            onCreateTokenSucceeded: function (token) {
				//if ((token.id+"").trim() === ($this.state.token+"").trim()) {
					$this.crearPago(token.id);
				//}
            },
            onCreateTokenError: function (error) { console.log(error); }
        });
    }

	crearPago(token2) {
		let datas = {
            token_conekta: token2,
            checkoutid: this.state.checkout_id,
            publicKey: this.state.public_key,
            id_usuario: this.state.id_usuario,
            total_pago: this.state.montoPagar,
			bearer: this.state.bearer, 
        }
        AdministrativoService.realizar_pago(datas).then((res) => {
			let respuesta = res.data.object;
            if (respuesta === "error") {
				this.setState({mostrarModalPagar : false});
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.data.details[0].message,
                });
                this.goBack();
            }
			if (respuesta === "order") {
				let datos_pagos = {
                    livemode: res.data.livemode,
                    amount: res.data.amount,
                    object: res.data.object,
                    payment_status: res.data.payment_status,
                    order_id: res.data.id,
                    charger_id: res.data.charges.data[0].id,
                    currency: res.data.charges.data[0].currency,
                    account_type: res.data.charges.data[0].payment_method.account_type,
                    brand: res.data.charges.data[0].payment_method.brand,
                    country: res.data.charges.data[0].payment_method.country,
                    exp_month: res.data.charges.data[0].payment_method.exp_month,
                    exp_year: res.data.charges.data[0].payment_method.exp_year,
                    last4: res.data.charges.data[0].payment_method.last4,
                    name_card: res.data.charges.data[0].payment_method.name,
                    type_card: res.data.charges.data[0].payment_method.type,
                    charges_status: res.data.charges.data[0].status,
                }
                let obj_unidos = Object.assign(datas, datos_pagos);
                AdministrativoService.agregarPagoBD(obj_unidos).then((response) => {
                    if (response.data == 1){                         
						var config = {
							method: 'post',
							maxBodyLength: Infinity,
							url: 'https://playcell.live.bssmovil.com/recharges',
							headers: { 
								'Content-Type': 'application/json', 
								'Accept': 'application/json', 
								'Authorization': 'Bearer ' + this.state.brer
							},
							data : {
								"identifier_value": this.state.telefonoRecargaSi+"",
								"identifier_type": "msisdn",
								"offer_id": parseInt(this.state.offerId),
								"schedule_date": "",
								"pos_id": ""
							}
						};
						axios(config).then(function (res45) {
							console.log("jack: ENTRANDO PARA SUCCESS", res45.status);
							if (res45.status===201){
								if (res45.data !== undefined){
									this.setState({mostrarModalPagar : false});
									swal.fire({
										icon: 'success',
										title: 'Realizado',
										text: "La recarga se realizó exitosamente",
									});
								}
							}
						}).catch(function (error45) {
							swal.fire({
								icon: 'error',
								title: 'Error',
								text: "No se pudo realizar la recarga, consulte a soporte técnico",
							});
						});
                    } else {
                        this.setState({mostrarModalPagar : false});
						swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Error al procesar la recarga',
                        });
                    }
                });
            }
			
        });
    }

	cerrarModal = () =>{
		this.props.cerrarModal();
	}

	seleccionarRecarga(){
		this.props.agregarRecarga(this.state.recarga);
		this.cerrarModal();
	}

	

    render(){
        return(
			<div className='fixed inset-0 z-50 flex flex-wrap justify-center items-center h-full w-full'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            
            <div className="bg-white z-50 max-w-4xl max-h-full overflow-y-auto rounded-lg">
				<section className=''>

					<div className='px-5 py-5'>
						<div className='flex flex-wrap justify-center items-center overflow-y-auto'>
							{this.state.listaPaquetes.length>0 ? (
								this.state.listaPaquetes.map((item, i)=>{
									if (item.commercial_name.trim().slice(-7) === "Recarga" && this.props.esActivacion === false){
										return (
											<>
											  <div className="overflow-y-hidden">
															  <div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
																  <div className='relative w-auto my-6 mx-auto max-w-2xl z-20'>
																	  <div className='rounded-3xl relative flex flex-col w-full outline-none focus:outline-none shadow-sm2 bg-white pt-4'>
																		  <div className=' rounded-t-3xl'>
																			  <p class='text-primary text-3xl text-center my-5 font-bold uppercase'>RECARGA</p>
																		  </div>
																		  <div className='relative flex-auto'>
																			  <div className='flex justify-center items-center pb-10 pl-10 pr-10'>
																				  
																				  <div className="grid grid-cols-1 w-full">
																					  <label htmlFor="telefonoRecarga" className="md:text-sm text-xs text-black font-semibold">
																						  Seleccionar Recarga: <span className="text-red-500 text-xl">*</span>
																					  </label>
																					  <select
																						  onChange={ event => this.setState({ offerId: event.target.value }) }
																						  value={this.state.offerId}
																						  required
																						  id="telefonoRecarga"
																						  className="mt-1 rounded-lg p-2 border outline-none text-black bg-white focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk"
																						  name="telefonoRecarga"
																						   >
																<option disabled  value="0">Seleccione un opción</option>
																{
																  this.state.listaPaquetes.filter(oferta => oferta.offer_classification === "recharge").map((recarga) => (
																	<option key={recarga.id} value={recarga.id}>
																	{recarga.commercial_name} - ${recarga.price}
																  </option>
																  ))
																}
																					  </select>
																					  <label htmlFor="telefonoRecarga2" className="md:text-sm text-xs text-black font-semibold">
																						  Teléfono a recargar:
																					  </label>
																					  <input
																						className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk text-black"
																						type="tel"
																						name="telefonoRecarga2" 
																						minLength="1"
																						maxLength="20"
																						placeholder="Teléfono al cual se le hará la recarga"
																						value={this.state.telefonoRecarga2}
																						onChange={(e)=>{ this.validarTelefonoRecarga(e) }}
																						pattern="[0-9+]{1,20}"
																						disabled={this.state.disableBotonesModal} />
												
																				  </div>
																			  </div>
																		  </div>
																		<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
																			{this.state.cargando && (
																				<img className="w-10 h-10 mr-5" src={ImgCargando} alt=">"/>
																			)}
																			<div>
																				<button type="button" className={`${this.state.valido ?  'focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 mr-3' : 'w-auto bg-green-800 rounded-lg font-medium text-white px-4 py-2 cursor-not-allowed mr-3'}`} disabled={!this.state.valido} onClick={ () => this.seleccionarRecarga()}>Procesar Recarga</button>
																				<button type="button" onClick={()=>{ this.cerrarModal()}} className="focus:outline-none focus:ring-2 w-auto bg-blue-700 hover:bg-blue-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" disabled={this.state.disableBotonesModal} >Cancelar</button>
																			</div>
																		</div>
																		  
																	  </div>
																  </div>
																  <div  className={' cursor-pointer opacity-25 fixed inset-0 bg-black'}></div>
															  </div>
											  </div>
											</>
										  )
									}else if(item.commercial_name.trim().slice(-6) === "Activa" && this.props.esActivacion === true){
										return (
											<>
											<div className="overflow-y-hidden text-color3">
							<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
								<div className='relative w-auto my-6 mx-auto max-w-2xl z-20'>
									<div className='rounded-3xl relative flex flex-col w-full outline-none focus:outline-none shadow-sm2 bg-white pt-4'>
										<div className=' rounded-t-3xl'>
											<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Activacion</p>
										</div>
										<div className='relative flex-auto'>
											<div className='justify-center items-center pb-10 pl-10 pr-10'>
												<div className="grid grid-cols-1 w-full">
													<label htmlFor="paquete" className="md:text-sm text-xs text-light font-semibold">
														Seleccionar Activación: <span className="text-red-500 text-xl">*</span>
													</label>
													<select
														id="paquete"
														className="mt-1 rounded-lg p-2 border outline-none bg-white focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk"
														name="paquete"
														defaultValue={this.state.offerId}
														onChange={(e)=>{ this.setState({offerId : e.target.value, montoPagar : e.target.montoPagar}); }}
														required >
														<option key={0} value={""} montoPagar={""}></option>
														{this.state.listaPaquetes.map((gg,hh)=>{
															if (gg.commercial_name.trim().slice(-6) === "Activa"){
																return <option key={hh} value={gg.id} montoPagar={gg.price}>{gg.commercial_name}, Precio normal: {gg.retail_price}, Precio especial: {gg.price}</option>
															}
														})}
													</select>
												</div>
												<div className="grid grid-cols-1 w-full">
													<label htmlFor="identificadorNumero" className="md:text-sm text-xs text-light font-semibold">Escribir identificador:</label>
													<input
														className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk"
														type="tel"
														name="identificadorNumero"
														placeholder="Identificador del número telefónico."
														value={this.state.identificadorNumero}
														onChange={(e)=>{ this.setState({identificadorNumero : e.target.value}); }}/>
												</div>
											</div>
										</div>
										<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
											<div>
											<button type="button" disabled={this.state.activarBoton} className={`${this.state.activarBoton ? 'w-auto bg-green-800 rounded-lg font-medium text-white px-4 py-2 cursor-not-allowed mr-3':  'focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer mr-3'}`} onClick={(e)=>{ this.realizarActivacion();}}>
													{this.state.activarBoton ? (
														'Cargando'
														) : (
														'Activar teléfonos'
														)}
													</button>
												<button type="button" onClick={()=>{ this.cerrarModal()}} className="focus:outline-none focus:ring-2 w-auto bg-blue-700 hover:bg-blue-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" >Cancelar</button>
											</div>
										</div>
									</div>
								</div>
								<div onClick={ ()=> {this.setState({mostrarModalActivacion:false}); }} className={' cursor-pointer opacity-25 fixed inset-0 bg-black'}></div>
							</div>
						</div>
											</>
										)
									}
								})
							) : null
							}

							

						</div>
					</div>

				</section>

				{this.state.mostrarModalPagar && (
					<Fragment>
						<div className="overflow-y-hidden">
							<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
								<div className='relative w-auto my-6 mx-auto max-w-2xl z-20'>
									<div className='rounded-3xl relative flex flex-col w-full outline-none focus:outline-none shadow-sm2 bg-white pt-4'>
										<div className=' rounded-t-3xl'>
											<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Recarga de ${this.state.montoPagar}<br/>Detalles de la tarjeta</p>
										</div>
										<div className='relative flex-auto'>
											<div className='flex justify-center items-center pb-10'>
												<div id="conektaModal" className="w-full" style={{height:"500px"}}></div>
											</div>
										</div>
										<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
											<div>
												<button type="button" onClick={()=>{ this.setState({mostrarModalPagar:false});}} className="focus:outline-none focus:ring-2 w-auto bg-blue-700 hover:bg-blue-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" >Cancelar</button>
											</div>
										</div>
									</div>
								</div>
								<div onClick={ ()=> {this.setState({mostrarModalPagar:false}); }} className={' cursor-pointer opacity-25 fixed inset-0 bg-black'}></div>
							</div>
						</div>
					</Fragment>
				)}
				
				{this.state.mostrarModalTelefono && (
					<Fragment>
						<div className="overflow-y-hidden">
							<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
								<div className='relative w-auto my-6 mx-auto max-w-2xl z-20'>
									<div className='rounded-3xl relative flex flex-col w-full outline-none focus:outline-none shadow-sm2 bg-white pt-4'>
										<div className=' rounded-t-3xl'>
											<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Recarga de ${this.state.montoPagar}<br/> {`${this.props.esActivacion ? "Ingresa el codigo de activación" : "¿A que teléfono se le aplicará la recarga?"}`} </p>
										</div>
										<div className='relative flex-auto'>
											<div className='flex justify-center items-center pb-10 pl-10 pr-10'>
												
												<div className="grid grid-cols-1 w-full">
													{
														!this.props.esActivacion ? (
															<>
															<label htmlFor="telefonoRecarga" className="md:text-sm text-xs text-light font-semibold text-black">
																Teléfono: <span className="text-red-500 text-xl">*</span>
															</label>
															<select
																id="telefonoRecarga"
																className="mt-1 rounded-lg p-2 border outline-none bg-white focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk text-black"
																name="telefonoRecarga"
																defaultValue={this.state.telefonoRecarga}
																onChange={(e)=>{ this.setState({telefonoRecarga : e.target.value}); }}
																required
																disabled={this.state.disableBotonesModal}>
																{this.state.telefonosUsuario.Telefono1!=="" && (
																	<option key={1} value={this.state.telefonosUsuario.Telefono1}>{this.state.telefonosUsuario.Telefono1}</option>
																)}
																{this.state.telefonosUsuario.Telefono2!=="" && (
																	<option key={2} value={this.state.telefonosUsuario.Telefono2}>{this.state.telefonosUsuario.Telefono2}</option>
																)}
																<option key={0} value={"otro"}>Otro</option>
															</select>
															{this.state.telefonoRecarga==="otro" && (
																<div className="grid grid-cols-1 w-full">
																	<label htmlFor="telefonoRecarga2" className="md:text-sm text-xs text-light font-semibold">Escribir teléfono:</label>
																	<input
																		className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk text-black"
																		type="tel"
																		name="telefonoRecarga2"
																		minLength="1"
																		maxLength="20"
																		placeholder="Teléfono al cual se le hará la recarga"
																		value={this.state.telefonoRecarga2}
																		onChange={(e)=>{ this.setState({telefonoRecarga2 : e.target.value}); }}
																		pattern="[0-9+]{1,20}"
																		disabled={this.state.disableBotonesModal} />
																</div>
															)}
															</>
														):(
															<>
															<label htmlFor="telefonoRecarga2" className="md:text-sm text-xs text-light font-semibold">Codigo:</label>
															<input
																className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk text-black"
																type="text"
																name="telefonoRecarga2"
																minLength="1"
																maxLength="20"
																placeholder="Código de activación"
																value={this.state.telefonoRecarga2}
																onChange={(e)=>{ this.setState({telefonoRecarga2 : e.target.value}); }}
																 />
															</>
														)
													}

												</div>
											</div>
										</div>
										<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
											{this.state.cargandoModalbtn && (
												<img className="w-10 h-10 mr-5" src={ImgCargando} alt=">"/>
											)}
											<div>
												<button type="button" className="focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 mr-3" disabled={this.state.disableBotonesModal} onClick={(e)=>{ this.validarTelefonoRecarga();}}>Validar teléfonos</button>
												<button type="button" onClick={()=>{ this.setState({mostrarModalTelefono:false});}} className="focus:outline-none focus:ring-2 w-auto bg-blue-700 hover:bg-blue-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" disabled={this.state.disableBotonesModal} >Cancelar</button>
											</div>
										</div>
									</div>
								</div>
								<div onClick={ ()=> {this.setState({mostrarModalTelefono:false}); }} className={' cursor-pointer opacity-25 fixed inset-0 bg-black'}></div>
							</div>
						</div>
					</Fragment>
				)}

			</div>
        </div>
      
            
		
        )
    }
}
export default withRouter(RecargasModal) 




// return(
// 	<div className='fixed inset-0 z-50 flex flex-wrap justify-center items-center h-full w-full'>
// 	<div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
	
// 	<div className="bg-white z-50 max-w-4xl max-h-full overflow-y-auto rounded-lg">
// 		<section className=''>

// 			<div className='px-5 py-5'>
// 				<div className='flex flex-wrap justify-center items-center overflow-y-auto'>
// 					{this.state.listaPaquetes.length>0 ? (
// 						this.state.listaPaquetes.map((item, i)=>{
// 							if (item.commercial_name.trim().slice(-7) === "Recarga" && this.props.esActivacion === false){
// 								return (
// 									<>
// 										<div key={i} className=' shadow-2xl rounded-md bg-secondary_2 p-5 m-5 '>
// 											<div className='flex justify-center py-2'>
// 												<h1 className='text-xl sm:text-2xl font-black counter'>
// 													{item.commercial_name}
// 												</h1>
// 											</div>
// 											<h1 className='text-xl flex justify-center' style={{"textDecoration": "line-through"}}>
// 												Precio normal: {item.retail_price}
// 											</h1><br/>
// 											<h1 className='text-xl flex justify-center'>
// 												Precio especial: {item.price}
// 											</h1>
// 											<div className='flex justify-center pt-3'>
// 												<button className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 ' onClick={()=>{ this.setState({montoPagar : item.price, mostrarModalTelefono : true, offerId : item.id, telefonoRecarga2 : "", telefonoRecargaSi : "" }); }}>
// 													<p className='text-sm font-medium leading-none text-white text-center'>
// 														Seleccionar
// 													</p>
// 												</button>
// 											</div>
// 										</div>
										
// 									</>
// 								)
// 							}else if(item.commercial_name.trim().slice(-6) === "Activa" && this.props.esActivacion === true){
// 								return (
// 									<>
// 										<div key={i} className=' shadow-2xl rounded-md bg-secondary_2 p-5 m-5 '>
// 											<div className='flex justify-center py-2'>
// 												<h1 className='text-xl sm:text-2xl font-black counter'>
// 													{item.commercial_name}
// 												</h1>
// 											</div>
// 											<h1 className='text-xl flex justify-center' style={{"textDecoration": "line-through"}}>
// 												Precio normal: {item.retail_price}
// 											</h1><br/>
// 											<h1 className='text-xl flex justify-center'>
// 												Precio especial: {item.price}
// 											</h1>
// 											<div className='flex justify-center pt-3'>
// 												<button className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-color1 hover:bg-secondary focus:outline-none rounded transition transform duration-300 hover:translate-y-1 ' onClick={()=>{ this.setState({montoPagar : item.price, mostrarModalTelefono : true, offerId : item.id, telefonoRecarga2 : "", telefonoRecargaSi : "" }); }}>
// 													<p className='text-sm font-medium leading-none text-white text-center'>
// 														Seleccionar
// 													</p>
// 												</button>
// 											</div>
// 										</div>
										
// 									</>
// 								)
// 							}
// 						})
// 					) : null
// 					}

					

// 				</div>
// 			</div>

// 		</section>

// 		{this.state.mostrarModalPagar && (
// 			<Fragment>
// 				<div className="overflow-y-hidden">
// 					<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
// 						<div className='relative w-auto my-6 mx-auto max-w-2xl z-20'>
// 							<div className='rounded-3xl relative flex flex-col w-full outline-none focus:outline-none shadow-sm2 bg-white pt-4'>
// 								<div className=' rounded-t-3xl'>
// 									<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Recarga de ${this.state.montoPagar}<br/>Detalles de la tarjeta</p>
// 								</div>
// 								<div className='relative flex-auto'>
// 									<div className='flex justify-center items-center pb-10'>
// 										<div id="conektaModal" className="w-full" style={{height:"500px"}}></div>
// 									</div>
// 								</div>
// 								<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
// 									<div>
// 										<button type="button" onClick={()=>{ this.setState({mostrarModalPagar:false});}} className="focus:outline-none focus:ring-2 w-auto bg-blue-700 hover:bg-blue-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" >Cancelar</button>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 						<div onClick={ ()=> {this.setState({mostrarModalPagar:false}); }} className={' cursor-pointer opacity-25 fixed inset-0 bg-black'}></div>
// 					</div>
// 				</div>
// 			</Fragment>
// 		)}
		
// 		{this.state.mostrarModalTelefono && (
// 			<Fragment>
// 				<div className="overflow-y-hidden">
// 					<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
// 						<div className='relative w-auto my-6 mx-auto max-w-2xl z-20'>
// 							<div className='rounded-3xl relative flex flex-col w-full outline-none focus:outline-none shadow-sm2 bg-white pt-4'>
// 								<div className=' rounded-t-3xl'>
// 									<p class='text-primary text-3xl text-center my-5 font-bold uppercase'>Recarga de ${this.state.montoPagar}<br/> {`${this.props.esActivacion ? "Ingresa el codigo de activación" : "¿A que teléfono se le aplicará la recarga?"}`} </p>
// 								</div>
// 								<div className='relative flex-auto'>
// 									<div className='flex justify-center items-center pb-10 pl-10 pr-10'>
										
// 										<div className="grid grid-cols-1 w-full">
// 											{
// 												!this.props.esActivacion ? (
// 													<>
// 													<label htmlFor="telefonoRecarga" className="md:text-sm text-xs text-light font-semibold text-black">
// 														Teléfono: <span className="text-red-500 text-xl">*</span>
// 													</label>
// 													<select
// 														id="telefonoRecarga"
// 														className="mt-1 rounded-lg p-2 border outline-none bg-white focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk text-black"
// 														name="telefonoRecarga"
// 														defaultValue={this.state.telefonoRecarga}
// 														onChange={(e)=>{ this.setState({telefonoRecarga : e.target.value}); }}
// 														required
// 														disabled={this.state.disableBotonesModal}>
// 														{this.state.telefonosUsuario.Telefono1!=="" && (
// 															<option key={1} value={this.state.telefonosUsuario.Telefono1}>{this.state.telefonosUsuario.Telefono1}</option>
// 														)}
// 														{this.state.telefonosUsuario.Telefono2!=="" && (
// 															<option key={2} value={this.state.telefonosUsuario.Telefono2}>{this.state.telefonosUsuario.Telefono2}</option>
// 														)}
// 														<option key={0} value={"otro"}>Otro</option>
// 													</select>
// 													{this.state.telefonoRecarga==="otro" && (
// 														<div className="grid grid-cols-1 w-full">
// 															<label htmlFor="telefonoRecarga2" className="md:text-sm text-xs text-light font-semibold">Escribir teléfono:</label>
// 															<input
// 																className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk text-black"
// 																type="tel"
// 																name="telefonoRecarga2"
// 																minLength="1"
// 																maxLength="20"
// 																placeholder="Teléfono al cual se le hará la recarga"
// 																value={this.state.telefonoRecarga2}
// 																onChange={(e)=>{ this.setState({telefonoRecarga2 : e.target.value}); }}
// 																pattern="[0-9+]{1,20}"
// 																disabled={this.state.disableBotonesModal} />
// 														</div>
// 													)}
// 													</>
// 												):(
// 													<>
// 													<label htmlFor="telefonoRecarga2" className="md:text-sm text-xs text-light font-semibold">Codigo:</label>
// 													<input
// 														className="mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out border-black-jk text-black"
// 														type="text"
// 														name="telefonoRecarga2"
// 														minLength="1"
// 														maxLength="20"
// 														placeholder="Código de activación"
// 														value={this.state.telefonoRecarga2}
// 														onChange={(e)=>{ this.setState({telefonoRecarga2 : e.target.value}); }}
// 														 />
// 													</>
// 												)
// 											}

// 										</div>
// 									</div>
// 								</div>
// 								<div className='flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
// 									{this.state.cargandoModalbtn && (
// 										<img className="w-10 h-10 mr-5" src={ImgCargando} alt=">"/>
// 									)}
// 									<div>
// 										<button type="button" className="focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 mr-3" disabled={this.state.disableBotonesModal} onClick={(e)=>{ this.validarTelefonoRecarga();}}>Validar teléfonos</button>
// 										<button type="button" onClick={()=>{ this.setState({mostrarModalTelefono:false});}} className="focus:outline-none focus:ring-2 w-auto bg-blue-700 hover:bg-blue-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110" disabled={this.state.disableBotonesModal} >Cancelar</button>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 						<div onClick={ ()=> {this.setState({mostrarModalTelefono:false}); }} className={' cursor-pointer opacity-25 fixed inset-0 bg-black'}></div>
// 					</div>
// 				</div>
// 			</Fragment>
// 		)}

// 	</div>
// </div>