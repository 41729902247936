import axios from 'axios';
import { APIURL } from './Host';


class CategoriasService{


    ListarCategorias(){
        return axios.post(`${APIURL}/categorias`);
    }

    CategoriaById(idCategoria){
        return axios.post(`${APIURL}/categoria/${idCategoria}`);
    }

    InsertarCategoria(categoria){
        const data = {
            "nombre": categoria.nombre,
            "descripcion": categoria.descripcion,
            "register_by": categoria.register_by,
            "status": categoria.status,
        }
        return axios.post(`${APIURL}/categoria/insert`, data);
    }

    ActualizarCategoria(categoria){
        const data = {
            "nombre": categoria.nombre,
            "descripcion": categoria.descripcion,
            "register_by": categoria.register_by,
            "lu_by": categoria.lu_by,
            "status": categoria.status,
            "id_categoria": categoria.id_categoria,
        }
        return axios.post(`${APIURL}/categoria/update`, data);
    }

    ActualizarStatusCategoria(categoria){
        const data = {
            "status": categoria.status,
            "id_categoria": categoria.id_categoria
        }
        return axios.post(`${APIURL}/categoria/update/status`, data);
    }

}

export default new CategoriasService();