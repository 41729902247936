import React from 'react'
import InventarioService from '../services/InventarioService';
import sweetalert from "sweetalert2";

class InventarioModal extends React.Component {

  constructor(){
    super();
    this.state = {
      inventario: [],
      inventarioBusqueda: [],
      itemSelect: 0,
    }
  }

  cerrarModal = () =>{
    this.props.cerrarModal();
    
  }

  componentDidMount(){
    this.getInventario();
    document.getElementById('inputInventarioModal').select();
  }

  getInventario = () =>{
    InventarioService.ListarInventario({b:""}).then(response =>{
      this.setState({inventario: response.data, inventarioBusqueda: response.data})
      console.log(response.data);
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log('Error en listarInventario ', err)
    })
  }



  itemSeleccionado(item){
    this.props.cambiarItemInventario(item);
    this.cerrarModal();
  }

  onKeyUp = (event) =>{
    console.log("hla")
  }

  onChangeInput = (event) =>{
    const key = event.target.value.trim();
    if(key != ""){
        InventarioService.InventarioLikeIdentificador(key).then(response =>{
            console.log({inventarioBusqueda: response.data})
            this.setState({inventarioBusqueda: response.data});
        }).catch(err =>{
            console.log("Error en onChangeInput, ", err);
        })
    }else{
        this.setState({inventarioBusqueda: this.state.inventario});
    }
    this.setState({key: event.target.value})

  }


  render(){
    return (
      <>
      <div className='fixed z-50 inset-0 flex flex-wrap justify-center'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            
            <div className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white overflow-auto'>
              <div className='w-full p-2'>
                <div className='w-1/2 h-20 flex flex-col'>
                      <label className='mr-1 text-lg font-bold'>Buscar: </label>
                      {/* TODO CAMBIAR EL VALUE */}
                      <input type="text" id='inputInventarioModal' value={this.state.key}  className={`rounded-lg p-2 text-black text-xl `} onChange={ event => this.onChangeInput(event)} onKeyUp={event => this.onKeyUp(event)}/>
                </div>
                <div className='flex flex-row gap-1'>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Modelo</h4>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Marca</h4>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Categoria</h4>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Identificador</h4>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Stock</h4>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Min Stock</h4>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Precio U.</h4>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Precio Venta</h4>
                  <h4 className='w-1/5 flex-1 text-center mt-1 p-2 font-bold text-lg'>Precio Mayoreo</h4>
                </div>
                {
                  this.state.inventarioBusqueda.map((item, index) =>{
                    return (
                      <>
                        <div key={item.identificador} className='text-black flex flex-row gap-1 w-full mt-5 cursor-pointer hover:bg-gray-500 rounded-2xl bg-primary_2' onClick={ () => this.itemSeleccionado(item) }>
                          <p className={`w-full text-center mt-1 p-2 bg-transparent`} >
                            {item.modelo}
                          </p>
                          <p className={`w-full text-center mt-1 p-2 bg-transparent`} >
                            {item.marca}
                          </p>
                          <p className={`w-full text-center mt-1 p-2 bg-transparent`}>
                            {item.categoria_nombre }
                          </p>
                          <p className={`w-full text-center mt-1 p-2 bg-transparent`}>
                            {item.identificador }
                          </p>
                          <p className={`w-full text-center mt-1 p-2 bg-transparent`}>
                            {item.stock_actual}
                          </p>
                          <p className={`w-full text-center mt-1 p-2 bg-transparent`}>
                            {item.min_stock}
                          </p>
                          <p className={`w-full text-center mt-1 p-2 bg-transparent`}>
                            {item.precio_unitario}
                          </p>
                          <p className={`w-full text-center mt-1 p-2 bg-transparent`}>
                            {item.precio_venta}
                          </p>
                          <p className={`w-full text-center mt-1 p-2 bg-transparent`}>
                            {item.precio_mayoreo}
                          </p>
          
                        </div>
                      </>
                    )
                  })
                }
                
              </div>
            </div>



            <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
        </div>
      </>
      
    );  
  }
  
}

export default InventarioModal;
