import React from 'react'
import CatIdentificadoresService from 'services/CatIdentificadoresService';
import InventarioService from '../services/InventarioService';
import ProveedoresService from '../services/ProveedoresService';
import SucursalesService from '../services/SucursalesService';
import sweetalert from "sweetalert2";
import CategoriasService from 'services/CategoriasService';

class InventarioDetalles extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      proveedores: [],
      sucursales: [],
      identificadores:[],
      categorias: [],
      inventarioItem: {
        date_reg: "",
        descripcion: "",
        id_inventario: 0,
        categoria_id: null,
        identificador: "",
        last_update: "",
        lu_by: 0,
        marca: "",
        min_stock: 0,
        modelo: "",
        precio_mayoreo: 0,
        precio_unitario: 0,
        precio_venta: 0,
        proveedor_id: 0,
        register_by: 0,
        status: 0,
        stock_actual: 0,
        suc_reg_id: 0,
        suc_venta_id: 0,
        tipo_id: null
    }
    }
  }

  componentDidMount(){
    this.getProveedores();
    this.getSucursales();
    this.getCategorias();
    if(this.props.itemSelect != 0) this.getInventarioItem(this.props.itemSelect); 
  }

  getProveedores = () =>{
    ProveedoresService.listProveedores(1).then(response =>{
      this.setState({proveedores: response.data});
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al actualizar los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log("Error en getProveedores ", err);
    })
  }

  getCategorias = () =>{
    CategoriasService.ListarCategorias().then(response =>{
      console.log(response.data)
      this.setState({categorias: response.data});
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log("Error en getCategorias ", err);
    })
  }

  getIdentificadores = (idCategoria) =>{
    CatIdentificadoresService.IdentificadorByCategoria(idCategoria).then(response =>{
      this.setState({identificadores: response.data});
      this.setState({inventarioItem:{...this.state.inventarioItem, tipo_id: response.data[0]?.id_identificadores}})
    }).catch(err =>{
      sweetalert.fire({
        text: "Error obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log("Error en getIdentificadores ", err);
    })
  }

  getInventarioItem = (idInventario) =>{
    InventarioService.InventarioById(idInventario).then(response =>{
        this.setState({inventarioItem: response.data})
    }).catch(err =>{
        console.log("Error en getInventarioItem ", err);
    })
  }

  getProveedorNombre = (idProveedor) =>{
    let proveedor = this.state.proveedores.find(proveedor =>{
      return proveedor.id_proveedor === idProveedor;
    })

    if(proveedor) return proveedor.nombre;
    else return '';
  }

  getSucursalNombre = (idSucursal) =>{
    let sucursal = this.state.sucursales.find(sucursal =>{
      return sucursal.id_sucursal === idSucursal;
    })

    if(sucursal) return sucursal.nombre;
    else return '';
  }

  getSucursales = () =>{
    SucursalesService.ListarSucursales().then(response =>{
        this.setState({sucursales: response.data});
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
        console.log("Error en getSucursales ", err);
    })
  }

  actualizarInventarioItem = (event) =>{
    const usuario = sessionStorage.getItem("id");
    event.preventDefault();
    if(this.props.itemSelect == 0){
        //TODO: CAMBIAR LOS DATOS ESTATICOS
        const data = {
            ...this.state.inventarioItem, 
            register_by: usuario,
            suc_reg_id: 1,

            status: 1
        };
        console.log(data)
        InventarioService.InsertarInventario(data).then(response =>{
          console.log("Insertado con exito, ", response);
          this.props.getInventario();
          this.props.cerrarModal();
        }).catch(err =>{
          sweetalert.fire({
            text: "Error al actualizar los datos",
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          console.log("Error en actualizarInventarioItem, ", err);
        });
    }else{
        const data = {...this.state.inventarioItem, lu_by: usuario};
        console.log(data)
        InventarioService.ActualizarInventario(data).then(response =>{
          console.log("Actualizado con exito, ", response);
          this.props.getInventario();
          this.props.cerrarModal();
        }).catch(err =>{
          sweetalert.fire({
            text: "Error al actualizar los datos",
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          console.log("Error en actualizarInventarioItem, ", err);
        });
    }
    
    
  }

  onChangeInput = (event, input ) =>{
    this.setState({inventarioItem:{...this.state.inventarioItem, [input]: event.target.value}})
  }

  onChangeCategoria = (event) =>{
    this.getIdentificadores(event.target.value);
    this.setState({inventarioItem:{...this.state.inventarioItem, identificador: "", tipo_id: null, categoria_id: event.target.value}})
  }

  cerrarModal = () =>{
    this.props.cerrarModal();
  }
  


  render(){
    return (
          <div className="overflow-y-hidden">
						<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
							<div className='relative w-auto my-6 mx-auto max-w-6xl z-20'>
								<div className=''>
									<div className='relative flex-auto'>
										<div className='flex justify-center items-center'>
                    <form className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white overflow-auto'
                        onSubmit={(event) => this.actualizarInventarioItem(event)}
                    >
                        <p className='text-xl text-center font-bold w-full'>{`${this.props.itemSelect == 0 ? "Nuevo item" : "Detalles del item"}`}</p>
                        <div className='w-full mx-16 mt-2'>
                            <label>Descripcion</label>
                            <input 
                              className={`w-full p-1 text-12pt border-radius-7px text-black `} 
                              value={this.state.inventarioItem.descripcion}
                              onChange={ event => this.onChangeInput(event, 'descripcion') }
                              required
                            />
                        </div>
                        <div className='w-5/12 mt-2'>
                            <label>Modelo</label>
                            <input 
                              className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                              value={this.state.inventarioItem.modelo}
                              onChange={ event => this.onChangeInput(event, 'modelo') }
                              required
                            />
                        </div>
                        
                        <div className='w-5/12 mt-2'>
                            <label>Marca</label>
                            <input 
                              className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                              value={this.state.inventarioItem.marca}
                              onChange={ event => this.onChangeInput(event, 'marca') }
                              required
                            />
                        </div>

                        <div className='w-5/12 mt-2'>
                            <label>Categoria</label>
                            <select
                                className={`w-full p-1 text-12pt border-radius-7px text-black`}
                                value={this.state.inventarioItem.categoria_id}
                                onChange={ event => this.onChangeCategoria(event) }
                                required
                                >
                                    <option value={0}>{"---"}</option>
                                    {
                                    this.state.categorias.map(categoria =>{
                                        return <option key={categoria.id_categoria} value={categoria.id_categoria} >{categoria.nombre}</option>
                                    })
                                    }

                            </select>
                        </div>

                        <div className='w-5/12 mt-2'>
                            <label>Tipo de identificador</label>
                            <select
                                className={`w-full p-1 text-12pt border-radius-7px text-black`}
                                value={this.state.inventarioItem.tipo_id}
                                onChange={ event => this.onChangeInput(event, 'tipo_id') }
                                required
                                >
                                    <option value={0}>{"---"}</option>
                                    {
                                    this.state.identificadores.map(identificador =>{
                                        return <option key={identificador.id_identificadores} value={identificador.id_identificadores} >{identificador.descripcion}</option>
                                    })
                                    }

                            </select>
                        </div>

                        <div className='w-5/12 mt-2'>
                            <label>Identificador</label>
                            <input 
                              className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                              value={this.state.inventarioItem.identificador}
                              onChange={ event => this.onChangeInput(event, 'identificador') }
                              required
                            />
                        </div>

                        <div className='w-5/12 mt-2'>
                            <label>Sucursal de venta</label>
                            <select
                                className={`w-full p-1 text-12pt border-radius-7px text-black`}
                                value={this.state.inventarioItem.suc_venta_id}
                                onChange={ event => this.onChangeInput(event, 'suc_venta_id') }
                                required
                                >
                                    <option value={0}>{"---"}</option>
                                    {
                                    this.state.sucursales.map(sucursal =>{
                                        return <option key={sucursal.id_sucursal} value={sucursal.id_sucursal} >{this.getSucursalNombre(sucursal.id_sucursal)}</option>
                                    })
                                    }

                            </select>
                        </div>

                        <div className='w-5/12 mt-2'>
                            <label>Stock</label>
                            <input 
                              className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                              value={this.state.inventarioItem.stock_actual}
                              onChange={ event => this.onChangeInput(event, 'stock_actual') }
                              required
                              type='number'/>
                        </div>

                        <div className='w-5/12 mt-2'>
                            <label>Stock minimo</label>
                            <input 
                              className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                              value={this.state.inventarioItem.min_stock}
                              onChange={ event => this.onChangeInput(event, 'min_stock') }
                              required
                              type='number'/>
                        </div>
                        
                        <div className='w-5/12 mt-2'>
                            <label>Proveedor</label>
                            <select
                                className={`w-full p-1 text-12pt border-radius-7px text-black`}
                                value={this.state.inventarioItem.proveedor_id}
                                onChange={ event => this.onChangeInput(event, 'proveedor_id') }
                                >
                                  <option value={0}>{"---"}</option>
                                    {
                                    this.state.proveedores.map(proveedor =>{
                                        return <option key={proveedor.id_proveedor} value={proveedor.id_proveedor} >{`${proveedor.nombre} ${proveedor.app_paterno}`}</option>
                                    })
                                    }

                            </select>
                        </div>
                        
                        <div className='w-5/12 mt-2'>
                            <label>Precio unitario</label>
                            <input 
                              className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                              value={this.state.inventarioItem.precio_unitario}
                              onChange={ event => this.onChangeInput(event, 'precio_unitario') }
                              type='number'/>
                        </div>
                        
                        <div className='w-5/12 mt-2'>
                            <label>Precio Venta</label>
                            <input 
                              className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                              value={this.state.inventarioItem.precio_venta}
                              onChange={ event => this.onChangeInput(event, 'precio_venta') }
                              type='number'/>
                        </div>
                        
                        <div className='w-5/12 mt-2'>
                            <label>Precio Mayoreo</label>
                            <input 
                              className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                              value={this.state.inventarioItem.precio_mayoreo}
                              onChange={ event => this.onChangeInput(event, 'precio_mayoreo') }
                              type='number'/>
                        </div>

                        <div className={'w-full flex gap-2 justify-center items-center mt-5'}>
                        <button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Guardar</button>
                        <button className='focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' onClick={() => this.cerrarModal()}>Cancelar</button>
                        </div>

                        
                    </form>
										</div>
									</div>
								</div>
							</div>
							<div className='opacity-25 fixed inset-0 bg-black'></div>
						</div>
					</div>
    )
  }
  
}

export default InventarioDetalles;
