import { Component, createRef } from "react"
import { NavLink, Link } from "react-router-dom"
import AdministrativoService from "services/AdministrativoService"
import SweetAlert from "sweetalert2"
import Puestos_AdmonService from "services/Puestos_AdmonService"
import CatalogoDireccionService from "services/CatalogoDireccionService"
import { withRouter } from "react-router-dom"
import close from '../images/cancel.svg'
import ClientesService from "services/ClientesService"
import ProveedoresService from "services/ProveedoresService"


class GestionDeProveedores extends Component {
	refModalDet
	refModalAct
	buscador

	constructor(props) {
		super(props)
		this.refModalDet = createRef()
		this.refModalAct = createRef()
		this.buscador = ""
		this.state = {
			listAdministrativos: [],
			administrativo: null,
			modalDetalles: false,
			modalActualizar: false,
			option_nac_estado: [],
			option_nac_municipio: [],
			option_nac_localidad: [],
			option_fiscal_estado: [],
			option_fiscal_municipio: [],
			option_fiscal_localidad: [],
			option_tipo_clientes: [],
			option_puesto_administrativo: [],
			lAdmonTabla: [],
		}

		this.goBack = this.goBack.bind(this);
	}

	getListaAdmon = async () => {
		const arrayAux = await ProveedoresService.listProveedores(sessionStorage.getItem("id"))
			.then(resultado => resultado.data)
			.catch(error => [])
		this.setState({
			listAdministrativos: arrayAux,
			lAdmonTabla: arrayAux,
		})
	}

	viewData = data => {
		this.setState({ administrativo: data, modalDetalles: true }, () => {
			console.log("administrativo ", this.state.administrativo);
			this.refModalDet.current.focus()
		})
	}

	getListPuesto_Admon = async rol => {
		return (await Puestos_AdmonService.listaPuestos(!rol ? this.rol : rol)).data
	}

	getListNac_estado = async () => {
		return (await CatalogoDireccionService.getEstados()).data
	}

	callbackThen(data) {
		return data.data
	}

	putDataMunicipio = async optionValue => {
		this.setState(
			{
				option_nac_municipio: await CatalogoDireccionService.getMunicipios(
					optionValue
				).then(this.callbackThen.bind(this)),
			},
			() => this.asignLugar("nac_municipio")
		)
	}
	putDataMunicipioFiscal = async optionValue => {
		this.setState(
			{
				option_fiscal_municipio: await CatalogoDireccionService.getMunicipios(
					optionValue
				).then(this.callbackThen.bind(this)),
			},
			() => this.asignLugar("mun_fiscal")
		)
	}

	putDataLocalidad = async municipio => {
		this.setState(
			{
				option_nac_localidad: await CatalogoDireccionService.getLocalidades(
					this.state.administrativo.nac_estado,
					municipio
				).then(this.callbackThen.bind(this)),
			},
			() => this.asignLugar("nac_localidad")
		)
	}
	putDataLocalidadFiscal = async municipio => {
		this.setState(
			{
				option_fiscal_localidad: await CatalogoDireccionService.getLocalidades(
					this.state.administrativo.estado_fiscal,
					municipio
				).then(this.callbackThen.bind(this)),
			},
			() => this.asignLugar("localidad_fiscal")
		)
	}

	putDataSelects = event => {
		let selectIndex = event.target.selectedIndex
		let selectName = event.target.name
		let optionValue = event.target.options[selectIndex].value
		console.log(optionValue, selectName)
		switch (selectName) {
			case "nac_estado":
				this.putDataMunicipio(optionValue)
				break
			case "nac_municipio":
				this.putDataLocalidad(optionValue)
				break
			default:
				console.log("no hay nada que ejecutar")
				break
		}
		this.setlugarNac(selectName, optionValue)
	}
	putDataSelectsFiscal = event => {
		let selectIndex = event.target.selectedIndex
		let selectName = event.target.name
		let optionValue = event.target.options[selectIndex].value
		console.log(optionValue, selectName)
		switch (selectName) {
			case "estado_fiscal":
				this.putDataMunicipioFiscal(optionValue)
				break
			case "mun_fiscal":
				this.putDataLocalidadFiscal(optionValue)
				break
			default:
				console.log("no hay nada que ejecutar")
				break
		}
		this.setlugarNac(selectName, optionValue)
	}

	setlugarNac = (tipo, value) => {
		const administrativo = this.state.administrativo
		switch (tipo) {
			case "nac_estado":
				administrativo.nac_estado = parseInt(value)
				break
			case "nac_municipio":
				administrativo.nac_municipio = parseInt(value)
				break
			case "nac_localidad":
				administrativo.nac_localidad = parseInt(value)
				break
			default:
				break;
		}
		this.setState({ administrativo })
	}
	setDomicilioFiscal = (tipo, value) => {
		const administrativo = this.state.administrativo
		switch (tipo) {
			case "estado_fiscal":
				administrativo.estado_fiscal = parseInt(value)
				break
			case "mun_fiscal":
				administrativo.mun_fiscal = parseInt(value)
				break
			case "localidad_fiscal":
				administrativo.localidad_fiscal = parseInt(value)
				break
			default:
				break;
		}
		this.setState({ administrativo })
	}

	asignLugar = name => {
		try {
			const select = document.getElementsByName(name)[0]
			const options = select.options
			for (let i = 0; i < options.length; i++) {
				if (this.state.administrativo[name] === parseInt(options[i].value)) {
					options[i].selected = true
					break
				}
			}
		} catch (error) {
			console.log("");		
		}
	}

	updateData = data => {
		this.setState({ administrativo: data, modalActualizar: true }, () => {
			this.refModalAct.current.focus()
			this.putDataMunicipio(this.state.administrativo.nac_estado)
			this.putDataLocalidad(this.state.administrativo.nac_municipio)
			this.putDataMunicipioFiscal(this.state.administrativo.estado_fiscal)
			this.putDataLocalidadFiscal(this.state.administrativo.mun_fiscal)
			this.getListNac_estado().then(value =>
				this.setState({ option_nac_estado: value, option_fiscal_estado: value }, () =>{
					this.asignLugar("nac_estado")
					this.asignLugar("estado_fiscal")
				}
				)
			)

			const arrayf_nac = this.state.administrativo.fecha_nacimiento.split("-")
			let fecha_nacimiento = `${arrayf_nac[2]}-${arrayf_nac[1]}-${arrayf_nac[0]}`
			this.state.administrativo.fecha_nacimiento = fecha_nacimiento
			this.getListPuesto_Admon().then(value =>
				this.setState({ option_puesto_administrativo: value }, () =>
					this.asignLugar("puesto_id")
				)
			)
		})
	}

	componentDidMount = () => {
		this.getListaAdmon();
		this.getTiposPersonas().then(value => this.setState({ option_tipo_clientes: value }));
		this.getListPuesto_Admon("all").then(value =>
			this.setState({ option_puesto_administrativo: value })
		)
	}

	getTiposPersonas = async () =>{
		return (await ProveedoresService.ListarTipoClientes()).data
	}



	sendData = event => {
		event.preventDefault()
		ProveedoresService.updateProveedor(this.state.administrativo)
			.then(response => {
				console.log(response.data)
				SweetAlert.fire({
					icon: "success",
					text: "Datos actualizados con éxito",
				}).then(resultado => {
					if (resultado.isConfirmed) {
						this.setState({ modalActualizar: false })
					}
				})
			})
			.catch(error =>
				SweetAlert.fire({
					text: "No se pudo almacenar los datos a la base de datos",
					icon: "error",
				})
			)
	}

	actualizarStatus = (evento, administrativo) => {
		let selectIndex = evento.target.selectedIndex
		let optionValue = evento.target.options[selectIndex].value
		const arrayValue = optionValue.split("-")
		SweetAlert.fire({
			title: "Advertencia",
			showConfirmButton: true,
			showDenyButton: true,
			confirmButtonText: "Sí",
			denyButtonText: "No",
			confirmButtonColor: "#18253f",
			denyButtonColor: "#9b8818",
			text: "¿Está seguro de cambiar el estatus?",
			icon: "warning",
		}).then(response => {
			if (response.isConfirmed) {
				ProveedoresService.updateStatusProveedorUsuario({
					estatus: arrayValue[1],
					id_usuario: administrativo.id_usuario,
				})
					.then(response => {
						console.log(response.data)
						SweetAlert.fire({
							icon: "success",
							text: "Dato actualizado con éxito",
						})
						administrativo.estatus = arrayValue[0]
						this.forceUpdate()
					})
					.catch(error =>
						SweetAlert.fire({
							text: "No se pudo almacenar el dato a la base de datos",
							icon: "error",
						})
					)
			}
		})
	}

	goBack() {
		this.props.history.push("/app/ListaUsuarios");
	}

	actualizarDatos = (id_admin) => {
		this.setState({ modalDetalles: !this.state.modalDetalles });
		this.Administrativobyid(id_admin);
	}

	Administrativobyid(id_admin) {
		ProveedoresService.proveedorById(id_admin).then((res) => {
			this.setState({ administrativo: res.data, modalActualizar: true })
			console.log({proveedor: res.data})
			this.putDataMunicipio(this.state.administrativo.nac_estado)
			this.putDataLocalidad(this.state.administrativo.nac_municipio)
			this.putDataMunicipioFiscal(this.state.administrativo.estado_fiscal)
			this.putDataLocalidadFiscal(this.state.administrativo.mun_fiscal)
			this.getListNac_estado().then(value =>
				this.setState({ option_nac_estado: value, option_fiscal_estado: value }, () =>{
					this.asignLugar("nac_estado")
					this.asignLugar("estado_fiscal")
				}
				)
			)

			const arrayf_nac = this.state.administrativo.fecha_nacimiento.split("-")
			let fecha_nacimiento = `${arrayf_nac[2]}-${arrayf_nac[1]}-${arrayf_nac[0]}`
			this.state.administrativo.fecha_nacimiento = fecha_nacimiento
			this.getListPuesto_Admon().then(value =>
				this.setState({ option_puesto_administrativo: value }, () =>
					this.asignLugar("puesto_id")
				)
			)
		})
	}

	cambiarStatus(txt) {
		if (txt === 'A') {
			return "ACTIVO"
		}
		else if (txt === 'I') {
			return "INACTIVO"
		}
	}

	render() {
		return (
			<>
				<div className="bg-secondary_2 text-white h-auto py-10 bg-opacity-50">
					
					<h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
						Gestión de Proveedors
					</h1>

					<div className="flex justify-start mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<a onClick={this.goBack} class="ml-1 text-sm font-medium text-gray-200 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">usuarios</a>
									</div>
								</li>
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Gestión de Proveedores</span>
									</div>
								</li>
							</ol>
						</nav>
					</div>

					<section>

					<div className="col-span-2">
                    	<div className="flex justify-start ">
                        	<div class="xl:w-96 mt-8">
                            	<div class="ml-10 input-group relative flex flex-wrap items-stretch w-full mb-4 ">
									<input type="search"
										class="form-control relative flex-auto px-3 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none"
										placeholder="Buscar nombre"
										aria-label="Search"
										id="txtbuscar"
										name="txtbuscar"
										onKeyUp={event => {
											if (event.target.value !== "") {
												const array = this.state.listAdministrativos.filter(
													(value, posi) =>
														event.target.value ===
														value.nombre.substring(0, event.target.value.length)
													)
												this.setState({ lAdmonTabla: array })
											} else {
													this.setState({
													lAdmonTabla: this.state.listAdministrativos,
														})
													}
														}
									}
									aria-describedby="button-addon2" />

									<button>
										<svg class="h-6 w-6 text-white ml-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
									</button>
                            </div>
                        	</div>
                    	</div>

                   	 <div className='flex flex-row-reverse mr-10'>
						<NavLink to={{
							pathname: '/app/FormProveedor',
							state: { idTipoUsuarioSelect: 0 }
						}} rel="noopener noreferrer"
							className='inline-flex items-end justify-end h-10 px-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4'>
							<p className='text-sm font-medium leading-none'>
								<i class='fas fa-plus-circle'></i> Agregar Proveedor
							</p>
						</NavLink>
                    </div>
                </div>

						<div className="px-2 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto" >
							<div className="w-full ">
								<div className="w-full text-sm grid grid-cols-11 text-gray-800 font-semibold">

									<div className="font-semibold text-center col-span-3 ml-3 px-6 py-3 text-white">
										<div className="flex justify-start items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Nombre completo</label><br />
											</div>
										</div>
									</div>

									<div className="font-semibold text-center col-span-3 ml-3 px-6 py-3 text-white">
										<div className="flex justify-start items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Email</label><br />
											</div>
										</div>
									</div>

									<div className="font-semibold text-center col-span-3 ml-3 px-6 py-3 text-white">
										<div className="flex justify-center  items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest " htmlFor="">Status</label><br />
												<section className='flex justify-center width-100pr'>
													<select
														class='bg-purple-white shadow rounded border-0 p-1 transition-all duration-300 ease-in-out outline-none focus:ring-2 focus:ring-secondary text-secondary'
														name='status2'
														id='status2'
														onChange={event => {
															let selectIndex = event.target.selectedIndex
															console.log(
																selectIndex,
																event.target.options[selectIndex].value
															)
															let opcion = event.target.options[selectIndex].value
															const array = this.state.listAdministrativos.filter(
																(value, posi) => opcion === value.estatus
															)
															this.setState({ lAdmonTabla: array })
														}}>
														<option value={"A"}> A </option>
														<option value={"I"}> I </option>
													</select>
												</section>
											</div>
										</div>
									</div>

									<div className="font-semibold text-center col-span-2 ml-3 px-6 py-3 text-white">
										<div className="flex justify-center items-center">
											<div>
												<label className="text-lg font-barlow tracking-widest" htmlFor="">Mostrar</label><br />
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>

						{this.state.lAdmonTabla.map((value, index) => (
							<div className="w-full flex justify-center items-center">
								<div className="mt-5 rounded-2xl md:w-full lg:grid grid-cols-11 h-auto px-5 py-5 bg-secondary_2 relative">

									<div className="col-span-3 flex justify-start items-center">
										<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{value.nombre} {value.app_paterno} {value.app_materno}</h1>
									</div>

									<div className="col-span-3 flex justify-start items-center">
										<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{value.correo_electronico}</h1>
									</div>

									<div className="col-span-3 flex justify-center items-center">
										<section className='col-span-2 flex justify-center items-center'>
											<span
												className={
													"" + value.estatus === "A"
														? "bg-activo rounded-full px-3 py-1"
														: value.estatus === "Baja temporal"
															? "bg-baja-temporal rounded-full px-3 py-1"
															: value.estatus === "I"
																? "bg-inactivo rounded-full px-3 py-1"
																: value.estatus === "Baja definitiva"
																	? "bg-baja-definitiva rounded-full px-3 py-1"
																	: "bg-activo rounded-full px-3 py-1 text-white text-center uppercase tracking-wide"
												}>
												<h1 className="text-white text-left pl-3 md:pl-5 text-lg font-barlow tracking-widest">{this.cambiarStatus(value.estatus)}</h1>
											</span>
										</section>
										<div className="col-span-3 flex justify-start items-center">
											<select
												class='flex items-end py-1 px-2 rounded-lg border-2 mt-1 focus:outline-none focus:ring-2 focus:ring-secondary text-secondary focus:border-transparent cursor-pointer transition duration-300 ease-in-out shadow-inner2'
												name='status2'
												id='status2'
												onChange={evento => this.actualizarStatus(evento, value)}>
												<option value={"A-" + 1}> A </option>
												<option value={"I-" + 2}> I </option>
											</select>
										</div>
									</div>

									<div className="col-span-2 flex justify-center items-center">
										<div className='flex justify-center items-center col-span-1'>
											<div class='mt-2 ml-6 transform hover:text-purple-500 hover:scale-110'>
												<button
													onClick={() => this.viewData(value)}
													title='Detalles administrativo'>
													<svg
														class='h-7 w-7 text-white'
														viewBox='0 0 24 24'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'>
														{" "}
														<path d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z' />{" "}
														<circle cx='12' cy='12' r='3' />
													</svg>
												</button>
											</div>

											<div class='mt-2 ml-2 transform hover:text-purple-500 hover:scale-110'>
												<button
													hidden={this.state.ocultar}
													onClick={() => {
														this.updateData(value)
													}}
													title='Actualizar alumno'>
													<svg
														class='h-6 w-6 text-white'
														width='24'
														height='24'
														viewBox='0 0 24 24'
														xmlns='http://www.w3.org/2000/svg'
														fill='none'
														stroke='currentColor'
														stroke-width='2'
														stroke-linecap='round'
														stroke-linejoin='round'>
														{" "}
														<path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7' />{" "}
														<path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z' />
													</svg>
												</button>
											</div>
										</div>
									</div>
								</div>

							</div>
						))}

						{this.state.modalDetalles ? (
							<div div class='overflow-y-auto h-32 bg-black'>
								<div className='blur-sm justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
									<div
										className='relative w-auto my-6 mx-auto max-w-3xl'
										tabIndex={1}
										ref={this.refModalDet}
										onKeyDown={event => {
											if (event.code === "Escape") {
												console.log(event.code)
												this.setState({ modalDetalles: false })
											}
										}}>

										<div className='rounded-3xl  bg-black bg-opacity-90 relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2 z-20'>

											<div className='rounded-t-3xl relative bg-black'>
												<div className='flex items-center justify-end p-1 absolute -top-3 -right-3'>
													<button
														className='text-black-500 bg-black font-bold uppercase px-3 py-3 rounded-full text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 bg-red-500'
														type='button'
														onClick={() => this.setState({ modalDetalles: false })}>
														<img src={close} className="w-6" />
													</button>
												</div>
												<h3 className='text-white text-3xl text-center my-5 font-bold uppercase font-barlow tracking-widest'>
													Detalles
												</h3>
											</div>

											<div className='relative flex-auto bg-black'>
												<div class='grid grid-cols-1 gap-5 px-6 py-5 '>
													<div className='shadow-sm2 px-5 py-2 rounded-3xl color1'>
														<p class='mb-5 font-bold text-white uppercase text-center text-lg font-barlow tracking-widest'>
															Datos generales
														</p>

														<div class='grid grid-cols-2 gap-4 bg-black'>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Nombre del usuario:{" "}
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow'>
																	{this.state.administrativo.nombre +
																		" " +
																		this.state.administrativo.app_paterno +
																		" " +
																		this.state.administrativo.app_materno}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Tipo de Persona:
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.tipo_persona_descripcion}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Curp:
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.curp}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>Rfc:</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.rfc}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Sexo:
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.sexo}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Fecha de nacimiento:
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.fecha_nacimiento}
																</p>
															</div>
															{
																this.state.administrativo.tipo_persona == 2 && (
																	<div>
																		<p class='mb-2 font-bold text-gray-300 font-barlow'>
																			Razon social:
																		</p>
																		<p class='mb-2 font-semibold text-secondary font-barlow '>
																			{this.state.administrativo.razon_social}
																		</p>
																	</div>
																)
															}
														</div>
													</div>

													<div className="shadow-sm2 px-5 py-2 rounded-3xl bg-black">
														<p class='mb-2 font-bold text-gray-300 '>
															Domicilio:
														</p>
														<br />
														<div class='grid grid-cols-3 gap-4'>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Estado{" "}
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.entidad_federativa}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Municipio{" "}
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.nombre_municipio}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Localidad{" "}
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.nombre_asentamiento}
																</p>
															</div>
														</div>
													</div>
													<div className="shadow-sm2 px-5 py-2 rounded-3xl bg-black">
														<p class='mb-2 font-bold text-gray-300 '>
															Domicilio Fiscal:
														</p>
														<br />
														<div class='grid grid-cols-3 gap-4'>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Estado{" "}
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.entidad_federativa_fiscal}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Municipio{" "}
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.nombre_municipio_fiscal}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Localidad{" "}
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.nombre_asentamiento_fiscal}
																</p>
															</div>
														</div>
													</div>

													<div className='shadow-sm2 px-5 py-2 rounded-3xl bg-black'>
														<p class='mb-2 font-bold text-gray-300 '>
															Contactos:
														</p>
														<br />

														<div class='grid grid-cols-3 gap-4'>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Correo electrónico
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.correo_electronico}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Número de teléfono móvil
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.telefono1}
																</p>
															</div>
															<div>
																<p class='mb-2 font-bold text-gray-300 font-barlow'>
																	Número de teléfono fijo
																</p>
																<p class='mb-2 font-semibold text-secondary font-barlow '>
																	{this.state.administrativo.telefono2}
																</p>
															</div>
														</div>
													</div>
													
												</div>
											</div>
											{/*footer*/}

										</div>
									</div>
									<div style={{ right: "12px" }} onClick={() => this.setState({ modalDetalles: false })} className='cursor-pointer opacity-25 fixed inset-0 bg-black'></div>
								</div>

							</div>
						) : (
							""
						)}



						{this.state.modalActualizar ? (
							<>
								<form onSubmit={this.sendData}>
									<div div class="overflow-y-auto h-32">
										<div className='justify-center items-center fixed overflow-auto inset-0 z-50 outline-none focus:outline-none animate__animated animate__fadeIn'>
											<div className='relative w-auto my-6 mx-auto max-w-3xl z-20'
												tabIndex={1}
												ref={this.refModalAct}
												onKeyDown={event => {
													if (event.code === "Escape") {
														console.log(event.code)
														this.setState({ modalActualizar: false })
													}
												}}>
												{/*content*/}
												<div className='rounded-3xl bg-black relative flex flex-col w-full bg-base outline-none focus:outline-none shadow-sm2'>
													{/*header*/}
													<div className='rounded-t-3xl bg-black blur-sm'>
														<h3 className='text-white text-3xl text-center my-5 font-bold uppercase tracking-widest'>
															Actualizar Datos
														</h3>
													</div>
													{/*body*/}

													<div className='relative flex-auto'>
														<div class='grid grid-cols-1 gap-5 px-6 py-5 bg-black'>

															<div className="grid grid-cols-3 gap-5 mt-5 bg-black px-5 py-5 rounded-3xl ">
																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Tipo de Persona
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<select
																		onChange={event => {
																			let selectIndex = event.target.selectedIndex
																			console.log(
																				selectIndex,
																				event.target.options[selectIndex].value
																			)
																			const administrativo =
																				this.state.administrativo
																			administrativo.tipo_persona = parseInt(
																				event.target.options[selectIndex].value
																			)
																			this.setState({
																				administrativo,
																			})
																		}}
																		value={this.state.administrativo.tipo_persona}
																		name='tipo_persona'
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '>

																		{this.state.option_tipo_clientes?.map(
																			item => {
																				return (
																					<option
																						key={item.id_tipo_cliente}
																						value={item.id_tipo_cliente}>
																						{item.descripcion}
																					</option>
																				)
																			}
																		)}
																	</select>
																</div>
																<div >
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Nombre(s)
																		<span className='text-red-500 text-xl'>*</span>
																	</label>
																	<input
																		name='nombre'
																		value={this.state.administrativo.nombre}
																		onChange={event => {
																			const administrativo =
																				this.state.administrativo
																			administrativo.nombre = event.target.value
																			this.setState({ administrativo })
																		}}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '
																		required>
																	</input>
																</div>
																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Apellido paterno
																		<span className='text-red-500 text-xl'></span>
																	</label>
																	<input
																		name='app_paterno'
																		value={this.state.administrativo.app_paterno}
																		onChange={event => {
																			const administrativo =
																				this.state.administrativo
																			administrativo.app_paterno =
																				event.target.value
																			this.setState({
																				administrativo,
																			})
																		}}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black'>
																	</input>
																</div>

																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Apellido Materno
																		<span className='text-red-500 text-xl'></span>
																	</label>
																	<input
																		name='app_materno'
																		value={this.state.administrativo.app_materno}
																		onChange={event => {
																			const administrativo =
																				this.state.administrativo
																			administrativo.app_materno =
																				event.target.value
																			this.setState({
																				administrativo,
																			})
																		}}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '>
																	</input>
																</div>
																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Fecha de nacimiento
																		<span className='text-red-500 text-xl'></span>
																	</label>
																	<input
																		type='date'
																		name='fecha_nacimiento'
																		value={
																			this.state.administrativo.fecha_nacimiento
																		}
																		onChange={event => {
																			const administrativo =
																				this.state.administrativo
																			administrativo.fecha_nacimiento =
																				event.target.value
																			this.setState({ administrativo })
																		}}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '>
																	</input>
																</div>

																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Curp <span className='text-red-500 text-xl'></span>
																	</label>
																	<input
																		name='curp'
																		maxLength='18'
																		value={this.state.administrativo.curp}
																		onChange={event => {
																			const administrativo =
																				this.state.administrativo
																			administrativo.curp = event.target.value
																			this.setState({ administrativo })
																		}}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '>
																	</input>
																</div>

																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Rfc <span className='text-red-500 text-xl'></span><br />
																	</label>
																	<input
																		name='rfc'
																		maxLength='13'
																		value={this.state.administrativo.rfc}
																		onChange={event => {
																			const administrativo =
																				this.state.administrativo
																			administrativo.rfc = event.target.value
																			this.setState({ administrativo })
																		}}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black'>
																	</input>
																</div>

																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Razon social <span className='text-red-500 text-xl'></span><br />
																	</label>
																	<input
																		name='razon_social'
																		maxLength='13'
																		value={this.state.administrativo.razon_social}
																		onChange={event => {
																			const administrativo =
																				this.state.administrativo
																			administrativo.razon_social = event.target.value
																			this.setState({ administrativo })
																		}}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black'>
																	</input>
																</div>
															</div>

															<div className='grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-8 mt-5 bg-black px-5 py-5 rounded-3xl'>
																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Nacionalidad
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<input
																		name='nacionalidad'
																		value={this.state.administrativo.nacionalidad}
																		onChange={event => {
																			const administrativo =
																				this.state.administrativo
																			administrativo.nacionalidad =
																				event.target.value
																			this.setState({
																				administrativo,
																			})
																		}}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black'>
																	</input>
																</div>

																<div >
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Sexo <span className='text-red-500 text-xl'></span><br />
																	</label>
																	<select
																		onChange={event => {
																			let selectIndex = event.target.selectedIndex
																			console.log(
																				selectIndex,
																				event.target.options[selectIndex].value
																			)
																			this.setState({
																				sexo: event.target.options[selectIndex]
																					.value,
																			})
																		}}
																		value={this.state.administrativo.sexo}
																		name='sexo'
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black'>
																		<option>---</option>
																		<option value='Hombre'>Hombre</option>
																		<option value='Mujer'>Mujer</option>
																	</select>
																</div>
																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Estado del domicilio
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<select
																		name='nac_estado'
																		onChange={this.putDataSelects}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black'>
																		{this.state.option_nac_estado.map(
																			element => (
																				<option
																					value={element.id_Estado}
																					key={element.id_Estado}>
																					{element.entidad_Federativa}
																				</option>
																			)
																		)}
																	</select><br />
																</div>

																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Municipio del domicilio
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<select
																		onChange={this.putDataSelects}
																		name='nac_municipio'
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '>
																		<option value={0}>NO ESPECIFICADO</option>
																		{this.state.option_nac_municipio.map(
																			element => (
																				<option
																					value={element.c_mnpio}
																					key={element.c_mnpio}>
																					{element.nombre_Municipio}
																				</option>
																			)
																		)}
																	</select><br />
																</div>

																<div>

																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Localidad del domicilio
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<select
																		onChange={this.putDataSelects}
																		name='nac_localidad'
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '>
																		<option value={0}>NO ESPECIFICADO</option>
																		{this.state.option_nac_localidad.map(
																			element => (
																				<option
																					value={element.id_Localidad}
																					key={element.id_Localidad}>
																					{element.nombre}
																				</option>
																			)
																		)}
																	</select>
																</div>

																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Estado fiscal
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<select
																		name='estado_fiscal'
																		onChange={this.putDataSelects}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black'>
																		{this.state.option_fiscal_estado.map(
																			element => (
																				<option
																					value={element.id_Estado}
																					key={element.id_Estado}>
																					{element.entidad_Federativa}
																				</option>
																			)
																		)}
																	</select><br />
																</div>

																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Municipio fiscal
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<select
																		onChange={this.putDataSelects}
																		name='mun_fiscal'
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '>
																		<option value={0}>NO ESPECIFICADO</option>
																		{this.state.option_fiscal_municipio.map(
																			element => (
																				<option
																					value={element.c_mnpio}
																					key={element.c_mnpio}>
																					{element.nombre_Municipio}
																				</option>
																			)
																		)}
																	</select><br />
																</div>

																<div>

																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Localidad fiscal
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<select
																		onChange={this.putDataSelects}
																		name='localidad_fiscal'
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '>
																		<option value={0}>NO ESPECIFICADO</option>
																		{this.state.option_fiscal_localidad.map(
																			element => (
																				<option
																					value={element.id_Localidad}
																					key={element.id_Localidad}>
																					{element.nombre}
																				</option>
																			)
																		)}
																	</select>
																</div>

																<div >
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Correo electrónico
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<input
																		name='correo_electronico'
																		type='email'
																		value={
																			this.state.administrativo.correo_electronico
																		}
																		onChange={event => {
																			const administrativo =
																				this.state.administrativo
																			administrativo.correo_electronico =
																				event.target.value
																			this.setState({ administrativo })
																		}}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '>
																	</input><br />
																</div>

																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Número telefónico 1
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<input
																		name='telefono1'
																		type='tel'
																		value={this.state.administrativo.telefono1}
																		onChange={event => {
																			const administrativo =
																				this.state.administrativo
																			administrativo.telefono1 =
																				event.target.value
																			this.setState({
																				administrativo,
																			})
																		}}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '
																		maxLength={10}>
																	</input><br />
																</div>

																<div>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Número telefónico 2
																		<span className='text-red-500 text-xl'></span><br />
																	</label>
																	<input
																		name='telefono2'
																		type='tel'
																		value={this.state.administrativo.telefono2}
																		onChange={event => {
																			const administrativo =
																				this.state.administrativo
																			administrativo.telefono2 =
																				event.target.value
																			this.setState({
																				administrativo,
																			})
																		}}
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '
																		maxLength={10}>
																	</input>
																</div>
															</div>



															<div className='grid grid-cols-2 md:grid-cols-3 gap-5 md:gap-8 mt-5 bg-black px-5 py-5 rounded-3xl hidden'>
																
																<div className='grid grid-cols-1 '>
																	<label className='md:text-sm text-xs text-gray-500 text-light font-semibold'>
																		Puesto<span className='text-red-500 text-xl'></span>
																	</label>
																	<select
																		onChange={event => {
																			let selectIndex = event.target.selectedIndex
																			console.log(
																				selectIndex,
																				event.target.options[selectIndex].value
																			)
																			const administrativo =
																				this.state.administrativo
																			administrativo.puesto_id = parseInt(
																				event.target.options[selectIndex].value
																			)
																			this.setState({
																				administrativo,
																			})
																		}}
																		value={this.state.administrativo.puesto_id}
																		name='puesto_id'
																		className='mt-1 rounded-lg p-2 border outline-none focus:ring-2 focus:ring-color1 transition duration-500 ease-in-out w-full text-primary_2 bg-black '>

																		{this.state.option_puesto_administrativo.map(
																			item => {
																				return (
																					<option
																						key={item.id_puesto}
																						value={item.id_puesto}>
																						{item.descripcion}
																					</option>
																				)
																			}
																		)}
																	</select>
																</div>
															</div>

														</div>
													</div>
													{/*footer*/}
													<div className='bg-black flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b'>
														<button
															className='text-red-500 background-transparent font-bold uppercase px-6 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
															type='button'
															onClick={() => this.setState({ modalActualizar: false })}>
															Cerrar
														</button>

														<button
															className='bg-emerald-500 text-white font-bold uppercase text-sm px-3 py-2  rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 bg-normal'
															type='submit'>
															Actualizar
														</button>
													</div>
												</div>
											</div>
											<div style={{ right: "12px" }} onClick={() => this.setState({ modalActualizar: false })} className='cursor-pointer opacity-25 fixed inset-0 bg-black'></div>
										</div>

									</div>
								</form>
							</>
						) : null}
					</section>

				</div>
			</>

		)
	}
}

export default withRouter(GestionDeProveedores);