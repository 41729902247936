import axios from "axios"
import {APIURL} from "./Host"

class VinculacionService{
    listaVinculacion(){
       return axios.get(APIURL+"/init_list_Vin");
    }

    ConsultabyMSISDN(DN)
    {
        var axios = require('axios');

        var config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'http://playcell.dev.bssmovil.com/profile/'+DN+'/msisdn',
            headers: 
            { 
                'Content-Type': 'application/json', 
                'Accept': 'application/json', 
                'Authorization': 'Bearer 1704|jCgyBQTUAx1bguaDT24eeaZDPDvpNgjEl6TZiqHo'
            }
        };

        return axios(config).then(function (response) {
        console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
            console.log(error);
        });
        
    }

    VinculacionByID(id_vinculacion){
        return axios.post(APIURL+`/Detail_Vin/${id_vinculacion}`)

    }

    ListarVinculacionesByInver(id_inver){
        return axios.post(`${APIURL}/vinculaciones/byInver/${id_inver}`);
    }

    InsertarVinculacion(vinculacion){
        const data = {
            "inver_id": vinculacion.inver_id,
            "cliente_id": vinculacion.cliente_id,
            "register_by": vinculacion.register_by,
            "status": vinculacion.status,
        }
        return axios.post(`${APIURL}/vinculaciones/insert`, data);
    }

    ActualizarVinculacion(vinculacion){
        const data = {
            "inver_id": vinculacion.inver_id,
            "cliente_id": vinculacion.cliente_id,
            "register_by": vinculacion.register_by,
            "lu_by": vinculacion.lu_by,
            "status": vinculacion.status,
            "id_vinculacion": vinculacion.id_vinculacion,
        }
        return axios.post(`${APIURL}/vinculaciones/update`, data);
    }

    ActualizarStatusVinculacion(vinculacion){
        const data = {
            "status": vinculacion.status,
            "id_vinculacion": vinculacion.id_vinculacion
        }
        return axios.post(`${APIURL}/vinculaciones/update/status`, data);
    }
}
export default new VinculacionService()