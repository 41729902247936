import React from 'react'
import AdministrativoService from 'services/AdministrativoService';
import Chart from "react-apexcharts";

class ModalShoot extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        telefono: '',
        isValid: false,
        isLoading: false,
        mostrar:false,
        mensajerror:'',
        fechaInicio:'',
        fechaExpira:'',
        data:{},
        listaOfertaCompleto:[],
        listaOfertasDatos : [],
        listaOfertasVoz : [],
        listaOfertasSMS : [],
        porcentajeOfertasDatos : [],
        porcentajeOfertasVoz : [],
        porcentajeOfertasSMS : [],
        porcentajesTotales : [],
        colores:[["#2f61ad","#3a89d7"],["#c03c3c","#d84c4c"],["#3b7440","#549d57"]]
      }
    }

    
  
    cerrarModal = () =>{
      this.props.cerrarModal();
    }

    validarTelefono(telefono) {
        const imeiRegex = /^\d{10}$/; // Expresión regular para verificar que el numero tiene 10 digitos
        const esNumerico = /^\d+$/; // Expresión regular para verificar que el numero solo contiene números
    
        if (!imeiRegex.test(telefono) || !esNumerico.test(telefono)) {
          return false;
        }
        return true;
    }
      
    handleChange(event) {
        const telefono = event.target.value;
        const isValid = this.validarTelefono(telefono);
        if(telefono.length===0){
            this.setState({mensajerror: ""}); 
        }
        this.setState({
          telefono: telefono,
          isValid: isValid
        });
    }

    convertirFecha(stringFecha){
        const fechaString = stringFecha;

        // Extraer los componentes de la fecha
        const year = fechaString.substring(0, 4);
        const month = fechaString.substring(4, 6);
        const day = fechaString.substring(6, 8);

        // Crear un nuevo objeto de fecha con los componentes extraídos
        const fecha = new Date(`${year}-${month}-${day}`);

        // Obtener los componentes formateados de la fecha
        const dia = fecha.getDate().toString().padStart(2, "0");
        const mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
        const anio = fecha.getFullYear().toString();

        // Imprimir la fecha en formato "dd/mm/yyyy"
        console.log(`${dia}/${mes}/${anio}`);
        return `${dia}/${mes}/${anio}`;

    }

    consultarMsisdn(){
        this.setState({ isLoading: true });
        this.setState({ mostrar: false });
        this.setState({ mensajerror: "" });
        let token="";
		AdministrativoService.loginPlaycel().then(response =>{
	        token=response.data.token.split("|")[1];
            AdministrativoService.turboShooting(this.state.telefono,token).then(response =>{
                this.setState({ data: response.data });
                this.setState({listaOfertasDatos : [response.data.purchasedOfferings.offers.filter(oferta=>oferta.serviceType=='Datos')]});
                this.setState({listaOfertasVoz : [response.data.purchasedOfferings.offers.filter(oferta=>oferta.serviceType=='Voz')]});
                this.setState({listaOfertasSMS : [response.data.purchasedOfferings.offers.filter(oferta=>oferta.serviceType=='SMS')]});
                //variables donde se guardaran los porcentajes para cada serie
                this.setState({porcentajeOfertasDatos : this.state.listaOfertasDatos[0].map(obj => ((obj.availableAmt / obj.initialAmt) * 100).toFixed(2))});
                this.setState({porcentajeOfertasVoz : this.state.listaOfertasVoz[0].map(obj => ((obj.availableAmt / obj.initialAmt) * 100).toFixed(2))});
                this.setState({porcentajeOfertasSMS : this.state.listaOfertasSMS[0].map(obj => ((obj.availableAmt / obj.initialAmt) * 100).toFixed(2))});
                this.setState({porcentajesTotales:[this.state.porcentajeOfertasDatos,this.state.porcentajeOfertasVoz,this.state.porcentajeOfertasSMS]});
                console.log("esto es mi dataaaaaaaaaaaaaaaaa ",this.state.data);
              //console.log(this.state.porcentajeOfertasDatos,"% ",this.state.porcentajeOfertasVoz," s ",this.state.porcentajeOfertasSMS);
                
                //console.log("mia rreglo de Vozzzzzzzzzzzzzzzzzzzz", this.state.listaOfertasVoz);
                //console.log("mia rreglo de smssssssssssssssssssssss", this.state.listaOfertasSMS);
                let datos = [
                  response.data.purchasedOfferings.offers.filter(oferta => oferta.serviceType === 'Datos'),
                  response.data.purchasedOfferings.offers.filter(oferta => oferta.serviceType === 'Voz'),
                  response.data.purchasedOfferings.offers.filter(oferta => oferta.serviceType === 'SMS')
                ];
                
                datos = datos.filter(arr => arr.length > 0);
                
                //console.log("el pimer arreglo hecho  ", this.state.listaOfertasDatos);
                this.setState({listaOfertaCompleto :datos});
                console.log("mi arreglo datos ", this.state.listaOfertaCompleto);
                //console.log("mi nuevo arreglo creado ", this.state.listaOfertaCompleto);
                //let porcentajes = response.data.purchasedOfferings.offers.filter(oferta=>oferta.serviceType=='Datos').map(obj => console.log(obj));
                this.setState({ mostrar: true });
                this.setState({ fechaInicio: this.convertirFecha(this.state.data.redirect.startEffectiveDate) });
                this.setState({ fechaExpira: this.convertirFecha(this.state.data.redirect.expireEffectiveDate) });
                console.log(this.state.data);
              }).catch(err =>{
                this.setState({ mostrar: false });
                this.setState({mensajerror:"Msisdn no disponible"});
              }).finally(() => {
                // Deshabilitar isLoading
                this.setState({ isLoading: false });
                
              });
		  })
    }

    compararFechas(fechaAcomparar){
        const fechaString = fechaAcomparar;
        const fechaActual = new Date();
        // Convertir la fechaString en un objeto Date
        const partesFecha = fechaString.split("/");
        const dia = parseInt(partesFecha[0], 10);
        const mes = parseInt(partesFecha[1], 10) - 1;
        const anio = parseInt(partesFecha[2], 10);
        const fechaComparar = new Date(anio, mes, dia);

        // Comparar las fechas
        if (fechaComparar < fechaActual) {
        return true;
        } else {
        return false;
        }
    }

    tipoServicio(tipoServicio){
      if(tipoServicio=='Datos'){
        return "Mb"
      }
      if(tipoServicio=='Voz'){
        return "Min"
      }
      if(tipoServicio=='SMS'){
        return "Msgs"
      }
    }

    miPlot(datos,index){
      let total=0;
      let tipo=this.tipoServicio(datos[0].serviceType);
      total=datos.reduce((total, data) => total + parseFloat(data.initialAmt), 0);
      console.log(datos," mis datos en mi plot");
      const resultado = datos.map(obj => `${parseFloat(obj.availableAmt).toFixed(2)} ${this.tipoServicio(obj.serviceType)}`);
      console.log(total," -------------resultado")
      return {
        colors: this.state.colores[index],
        labels: resultado,
        plotOptions: {
          radialBar: {
            dataLabels: {
              
              value: {
                show: true,
                fontSize: '14px',
                fontFamily: undefined,
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
              },
              total: {
                show: true,
                label: 'TOTAL',
                color: '#373d3f',
                formatter: function () {
                  return parseFloat(total).toFixed(2)+' '+tipo
                }
              }
            }
          }
        },
      }
    }
  
    render() {
         return (
           <>
             <div className='fixed inset-0 z-50 flex flex-wrap justify-center items-center'>
               <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}></div>
      
               <form onSubmit={event => this.consultarMsisdn()} className='w-1/3 z-100 relative flex flex-col justify-center items-center bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white'>
               <h2 className="text-2xl font-bold mb-5 text-center">Consultar MSISDN</h2>
                 <div className='w-full flex justify-center'>
                 <div className='w-1/2 h-20 flex flex-col'>
                       <label className='mr-1 text-lg font-bold'>Telefono: </label>
                       {/* TODO CAMBIAR EL VALUE */}
                       <input type="text" id='inputInventarioModal' className={`rounded-lg p-2 text-black text-xl `} value={this.state.telefono} onChange={event => this.handleChange(event)}/>
                       {this.state.telefono.length>0 && !this.state.isValid && (
                         <span className="text-red-500" style={{ fontSize: '1.2rem' }}>Telefono no válido</span>
                         )}
                 </div> 
                 </div>
      
                 <div className='w-full flex justify-center mt-5'>
                   <button className={`${this.state.isValid ?  'focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' : 'w-auto bg-gray-500 rounded-lg font-medium text-white px-4 py-2 cursor-not-allowed'}`} type='submit' disabled={!this.state.isValid}>
                   {this.state.isLoading ? (
                     'CARGANDO'
                     ) : (
                     'CONSULTAR'
                     )}
                   </button>
                 </div>
                 {/* Sección de etiquetas */}
                 {this.state.mostrar ? (<div className='fixed inset-0 z-50 flex flex-wrap justify-center items-center h-full w-full'>
                <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
                
            <div className="bg-white z-50 max-w-4xl max-h-full overflow-y-auto rounded-lg">
                    <section className=''>
                      <div className='text-black mx-auto max-w-md border border-solid border-gray-300 mt-4'>
                            <div className='flex justify-center py-2'>
                                    <h1 className='text-xl sm:text-2xl font-black'>Información General</h1>
                                  </div>
                                  <div className='flex'>
                                    <h1 className='text-lg ml-2 flex-1'>MVNO: <strong>{this.state.data.mvno.nombre}</strong></h1>
                                    <h1 className='text-xl ml-2 flex-1'>FECHA ACTIVACIÓN: <strong>{(this.state.data.activation_date!=null)?this.state.data.activation_date:'Invalido'}</strong></h1>
                                  </div>
                                  <div className='flex'>
                                    <h1 className='text-xl ml-2 flex-1'>MSISDN: <strong>{(this.state.data.subscriber.msisdn!="")?this.state.data.subscriber.msisdn:'no encontrado'}</strong></h1>
                                  </div>
                                  <div className='flex'>
                                    <h1 className='text-xl ml-2 flex-1'>BE_ID: <strong>{(this.state.data.mvno.beId!="")?this.state.data.mvno.beId:'no encontrado'}</strong></h1>
                                    <h1 className='text-xl ml-2 flex-1'>FUE PORTABILIDAD: <strong>{(this.state.data.has_portability)?'Si':'No'}</strong></h1>
                                  </div>
                                  <div className='flex'>
                                    <h1 className='text-xl ml-2 flex-1'>IMSI: <strong>{(this.state.data.subscriber.imsi!="")?this.state.data.subscriber.imsi:'no encontrado'}</strong></h1>
                                    <h1 className='text-xl ml-2 flex-1'>ESTADO: <strong>{(this.state.data.subscriber.status.status!="")?this.state.data.subscriber.status.status:'no encontrado'}</strong></h1>
                                  </div>
                                  <div className='flex'>
                                    <h1 className='text-xl ml-2 flex-1'>IMEI: <strong>{(this.state.data.subscriber.imei!="")?this.state.data.subscriber.imei:'no encontrado'}</strong></h1>
                                    <h1 className='text-xl ml-2 flex-1'>RAZÓN: <strong>{(this.state.data.subscriber.status.reason!="")?this.state.data.subscriber.status.reason:'no encontrado'}</strong></h1>
                                  </div>
                                  <div className='flex'>
                                    <h1 className='text-xl ml-2 flex-1'>ICC: <strong>{(this.state.data.subscriber.iccid!="")?this.state.data.subscriber.iccid:'no encontrado'}</strong></h1>
                                    <h1 className='text-xl ml-2 flex-1'>PRODUCTO: <strong>{(this.state.data.subscriber.product!="")?this.state.data.subscriber.product:'no encontrado'}</strong></h1>
                                  </div>
                                  <div className='flex'>
                                    <h1 className='text-xl ml-2 flex-1'>COORDENADAS (HBB,ALTA O CAMBIO): <strong>{(this.state.data.subscriber.coordinates!=",")?this.state.data.subscriber.coordinates:'N/A'}</strong></h1>
                                  </div>
                      </div>
                      <div className='px-5 py-5'>
                      <div className='flex flex-wrap justify-center items-center overflow-y-auto'>
  {this.state.listaOfertaCompleto.length > 0 ? (
    this.state.listaOfertaCompleto.map((data, index) => (
      <div key={index} className='shadow-2xl rounded-md ml-4 border'>
        <Chart
          options={this.miPlot(data, index)}
          series={this.state.porcentajesTotales[index]}
          type="radialBar"
          width="270"
        />
        {data.map((bolsa, index) => (
          <div key={index} className='text-black'>
            <div className='flex justify-center py-2'>
              <h1 className='text-xl sm:text-2xl font-black counter'>Bolsa {index+1}</h1>
            </div>
            <div className='flex'>
              <h1 className='text-lg ml-2 flex-1'>Adquirido</h1>
              <h1 className='text-xl ml-2 flex-1'>{parseFloat(bolsa.initialAmt).toFixed(2)} {this.tipoServicio(bolsa.serviceType)}</h1>
            </div>
            <div className='flex'>
              <h1 className='text-xl ml-2 flex-1'>Consumido</h1>
              <h1 className='text-xl ml-2 flex-1'>{parseFloat((bolsa.initialAmt - bolsa.availableAmt)).toFixed(2)} {this.tipoServicio(bolsa.serviceType)}</h1>
            </div>
            <div className='flex'>
              <h1 className='text-xl ml-2 flex-1'>Disponible</h1>
              <h1 className='text-xl ml-2 flex-1'>{parseFloat(bolsa.availableAmt).toFixed(2)} {this.tipoServicio(bolsa.serviceType)}</h1>
            </div>
          </div>
        ))}
      </div>
    ))
  ) : (
    <div className="text-center">
      <i className="fas fa-exclamation-circle text-red-500 text-4xl"></i>
      <div className="text-red-500 mt-2">El numero {this.state.telefono} no tiene una oferta en este momento porfavor recargue</div>
    </div>
  )}
</div>

                      </div>
                    </section>
    
            </div>
            </div>):(<div className="w-full flex justify-center">
               <h1 className='text-center' style={{ fontSize: '1.8rem' }}>{this.state.mensajerror}</h1>
             </div>)}       
               </form>
             </div>
           </>
         );
        
      }
      
    
  }
  
  export default ModalShoot;