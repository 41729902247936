import React from 'react'
import InventarioService from '../services/InventarioService';
import ProveedoresService from '../services/ProveedoresService';
import InventarioDetalles from './InventarioDetalles';
import GeneralService from "services/GeneralService";
import UsuariosService from 'services/UsuariosService';
import sweetalert from "sweetalert2"
import CategoriasService from 'services/CategoriasService';

class InventarioPrincipal extends React.Component {

  constructor(){
    super();
    this.state = {
      inventarioInicial: [],
      inventarioModificado: [],
      proveedores: [],
      categorias: [],
      modalVisible: false,
      itemSelect: 0,
      infoBuscar: "",
      id_usuario : sessionStorage.getItem('id'),
      //suc_id: sessionStorage.getItem("suc_id"),
      suc_id:sessionStorage.getItem('suc_id'),
      
    }
  }

  cerrarModal = () =>{
    this.setState({modalVisible: false})
  }

  componentDidMount(){
    this.getInventario();
    this.getProveedores();
  }

  getInventario = () =>{
   this.state.id_usuario= GeneralService.permutaIdUsuario(this.state.id_usuario);
    console.log("ID que se va **********:"+this.state.id_usuario);
    InventarioService.ListarInventario({b:this.state.infoBuscar,id:this.state.id_usuario, suc_id: this.state.suc_id}).then(response =>{
      this.setState({inventarioInicial: response.data, inventarioModificado: response.data})
      console.log(response.data);
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log('Error en listarInventario ', err)
    })
  }

  getProveedores = () =>{
    ProveedoresService.listProveedores(1).then(response =>{
      console.log({proveedores: response.data})
      this.setState({proveedores: response.data});
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log("Error en getProveedores ", err);
    })
  }

  getProveedorNombre = (idProveedor) =>{
    let proveedor = this.state.proveedores.find(proveedor =>{
      return proveedor.id_proveedor == idProveedor;
    })

    if(proveedor) return proveedor.nombre;
    else return '';
  }

  onChangeInput = (event, index, input) =>{
    let inventarioModificado = this.state.inventarioModificado.map((item, i) =>{
      if(index === i){
        return {...item, [ input ]: event.target.value}
      }else{
        return item;
      }
    })

    this.setState({inventarioModificado: inventarioModificado});
  }

  resetInventario = () =>{
    this.setState({inventarioModificado: this.state.inventarioInicial});
  }

  onSubmit = (event) =>{
    event.preventDefault();
    console.log("Entra a onSubmit")
  }

  aplicarCambios = (event, index) =>{
    const usuario = sessionStorage.getItem("id");
    if(!(event.code === "Enter")) return;
    if(this.state.inventarioInicial === this.state.inventarioModificado) return;
    
    const data = {...this.state.inventarioModificado[index], lu_by: usuario};
    InventarioService.ActualizarInventario(data).then(response =>{
      console.log("Actualizado con exito, ", response);
      this.getInventario();
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al actualizar los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
      console.log("Error en aplicarCambios, ", err);
    });
  }

  eliminarItem = (id_inventario, descripcion) =>{
    sweetalert
      .fire({
        text: `Seguro quiere eliminar el articulo: ${descripcion}`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const data = {
            status: 0,
            id_inventario: id_inventario
          }
          InventarioService.ActualizarStatusInventario(data).then(response =>{
            this.getInventario();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al eliminar el item",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log(err);
          })
        }
      });
  }

  cambiarProveedor = (event, index) =>{
    let inventarioModificado = this.state.inventarioModificado.map((item, i) =>{
      if(index === i){
        return {...item, proveedor_id: event.target.value}
      }else{
        return item;
      }
    })

    this.setState({inventarioModificado: inventarioModificado});
    const usuario = sessionStorage.getItem("id");
    if(this.state.inventarioInicial === inventarioModificado) return;
    sweetalert
      .fire({
        text:`Seguro quiere cambiar el proveedor`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const data = {...inventarioModificado[index], lu_by: usuario};
          InventarioService.ActualizarInventario(data).then(response =>{
            console.log("Actualizado con exito, ", response);
            this.getInventario();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al actualizar los datos",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log("Error en aplicarCambios, ", err);
          });
        }
      });
  }

  cambiarCategoria = (event, index) =>{
    let inventarioModificado = this.state.inventarioModificado.map((item, i) =>{
      if(index === i){
        return {...item, categoria_id: event.target.value}
      }else{
        return item;
      }
    })

    this.setState({inventarioModificado: inventarioModificado});
    const usuario = sessionStorage.getItem("id");
    if(this.state.inventarioInicial === inventarioModificado) return;
    sweetalert
      .fire({
        text:`Seguro quiere cambiar la categoria?`,
        showCancelButton:true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        cancelButtonColor: "red",
        allowOutsideClick: true,
        allowEscapeKey: true
      })
      .then(resultado => {
        if (resultado.isConfirmed){
          const data = {...inventarioModificado[index], lu_by: usuario};
          InventarioService.ActualizarInventario(data).then(response =>{
            console.log("Actualizado con exito, ", response);
            this.getInventario();
          }).catch(err =>{
            sweetalert.fire({
              text: "Error al actualizar los datos",
              allowOutsideClick: false,
              allowEscapeKey: false
            })
            console.log("Error en aplicarCambios, ", err);
          });
        }
      });
  }

  abrirDetallesItem(idInventario){
    this.setState({modalVisible: true, itemSelect: idInventario});
  }


  render(){
    return (
      <>
        <div className='bg-secondary_2 bg-opacity-30 text-white p-10'>
          <h1 className='text-2xl md:text-3xl uppercase text-white font-bold mb-1 text-center font-barlow tracking-widest mt-4'>
              Inventario
            </h1>

					<div className="flex justify-start mx-10 mt-4">
						<nav class="flex" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<a href="/" class="inline-flex items-center text-sm text-white hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
										Home
									</a>
								</li>
								
								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">Inventario</span>
									</div>
								</li>
							</ol>
						</nav>
					</div>
        
        <div className='col-span-2'>
          <div className="flex justify-start ">
            <div class="mt-8">
              <div class="input-group relative flex flex-wrap items-stretch w-full mb-4 ">
                <div className="rounded-lg border border-black-jk bg-white transition duration-500 ease-in-out flex "></div>
                  
                  
                <input type="search"
                  class="form-control relative flex-auto px-3 py-1.5 text-base font-normal text-black bg-primary_2 bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-black focus:bg-white focus:border-secondary focus:outline-none"
                  placeholder="Buscar Ítem"
                  aria-label="Search"
                  id="txtbuscar"
                  name="txtbuscar"
                  value={this.state.infoBuscar}
                  onChange={event => this.setState({infoBuscar : event.target.value})}
                  onKeyPress={e => { if (e.key==="Enter") { this.getInventario(); } }}
                aria-describedby="button-addon2" />

                
                <svg className="h-6 w-6 text-white ml-2 mr-2 mt-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <circle cx="10" cy="10" r="7" />  <line x1="21" y1="21" x2="15" y2="15" /></svg>
                <button type="button" className="text-center border-bl border focus:ring-2 focus:ring-color1 w-24 bg-color1 text-white justify-center items-center rounded-lg" onClick={(e)=>{ this.setState({infoBuscar:""}, ()=>{ this.getInventario(); }); }} title="Resetear busqueda">Resetear</button>


              </div>
            </div>
            <div className='mt-6 mr-10'>
              <button className='h-12 p-6 py-3 bg-color1 hover:bg-blue focus:outline-none rounded transition transform duration-500 hover:-transition-y-1 hover:scale-110 ml-4' onClick={ () => this.abrirDetallesItem(0) }><i class='fas fa-plus-circle'></i> Agregar item</button>
            </div>
          </div>
          {
            this.state.inventarioModificado.map((item, index) =>{
              return (
                <div key={index} className='flex justify-center flex-row w-full mt-5 rounded-2xl md:w-full h-auto px-5 py-5 bg-secondary_2'>
                  <input 
                      className={`w-full text-center mt-1 p-2 bg-transparent`} 
                      value={item.modelo}
                      onChange={ event => this.onChangeInput(event, index, "modelo")}
                      onBlur={() => this.resetInventario()}
                      onKeyUp={(event) => this.aplicarCambios(event, index)}/>
                  <p className={`w-full text-center mt-1 p-2 bg-transparent`}>
                    {item.categoria_nombre }
                  </p>
                  <input 
                      className={`w-full text-center mt-1 p-2 bg-transparent `} 
                      value={item.identificador }
                      onChange={ event => this.onChangeInput(event, index, "identificador")}
                      onBlur={() => this.resetInventario()}
                      onKeyUp={(event) => this.aplicarCambios(event, index)}/>
                  <input 
                      className={`w-full text-center mt-1 p-2 bg-transparent `} 
                      value={item.stock_actual}
                      onChange={ event => this.onChangeInput(event, index, "stock_actual")}
                      onBlur={() => this.resetInventario()}
                      onKeyUp={(event) => this.aplicarCambios(event, index)}
                      type='number'/>
                  <input 
                      className={`w-full text-center mt-1 p-2 bg-transparent `} 
                      value={item.min_stock}
                      onChange={ event => this.onChangeInput(event, index, "min_stock")}
                      onBlur={() => this.resetInventario()}
                      onKeyUp={(event) => this.aplicarCambios(event, index)}
                      type='number'/>
                  <select
                      className={`w-full text-center mt-1 p-2 bg-transparent `}
                      value={item.proveedor_id}
                      onChange={(event) => this.cambiarProveedor(event, index)}
                      onBlur={() => this.resetInventario()}
                      >
                        {
                          this.state.proveedores.map(proveedor =>{
                            return <option className={`text-black`} key={proveedor.id_proveedor} value={proveedor.id_proveedor} >{`${proveedor.nombre} ${proveedor.app_paterno}`}</option>
                          })
                        }

                    </select>
                    {/* <input 
                        className={`  text-center mt-1 p-2 bg-transparent `} 
                        value={this.getProveedorNombre(item.proveedor_id)}
                        onChange={ event => this.onChangeInput(event, index, "proveedor_id")}
                        onBlur={() => this.resetInventario()}
                        onKeyUp={(event) => this.aplicarCambios(event, index)}/> */}
                    <input 
                        className={`w-full text-center mt-1 p-2 bg-transparent `} 
                        value={item.precio_unitario}
                        onChange={ event => this.onChangeInput(event, index, "precio_unitario")}
                        onBlur={() => this.resetInventario()}
                        onKeyUp={(event) => this.aplicarCambios(event, index)}
                        type='number'/>
                    <input 
                        className={`w-full text-center mt-1 p-2 bg-transparent `} 
                        value={item.precio_venta}
                        onChange={ event => this.onChangeInput(event, index, "precio_venta")}
                        onBlur={() => this.resetInventario()}
                        onKeyUp={(event) => this.aplicarCambios(event, index)}
                        type='number'/>
                    <input 
                        className={`w-full text-center mt-1 p-2 bg-transparent `} 
                        value={item.precio_mayoreo}
                        onChange={ event => this.onChangeInput(event, index, "precio_mayoreo")}
                        onBlur={() => this.resetInventario()}
                        onKeyUp={(event) => this.aplicarCambios(event, index)}
                        type='number'/>
                    <div className={`w-full flex justify-evenly items-center p-2 mt-1 bg-transparent `}>
                    <button title='Editar item' onClick={ () => this.abrirDetallesItem(item.id_inventario) }><svg
                              class='h-6 w-6 text-white'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              stroke='currentColor'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'>
                              {" "}
                              <path d='M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7' />{" "}
                              <path d='M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z' />
                            </svg>
                      </button>
                      <button title='Eliminar item' className='bg-red-500 rounded p-1 px-2 mr-2' onClick={ () => this.eliminarItem(item.id_inventario, item.descripcion)} >Eliminar</button>
                      
                    </div>
    
                  </div>
                )
              })
          }
          {
            this.state.modalVisible && (
              <InventarioDetalles cerrarModal={this.cerrarModal} itemSelect={this.state.itemSelect} getInventario={this.getInventario}/>
            )
          }
          </div>
        </div>
      </>
    );  
  }
  
}

export default InventarioPrincipal;
