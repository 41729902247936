class GeneralService {

    validarLongitud(valor,tamanio) {
        const imeiRegex = new RegExp(`^\\d{${tamanio}}$`);//Expresión regular para validar el tamaño de la cadena(valor)

        if (!imeiRegex.test(valor)) {
          return false;
        }
        return true;
    }
    //funcion para validar que la cadena recibida sea solo numerica
    validarNumeros(valor) {
        const esNumerico = /^\d+$/; // Expresión regular para verificar que el valor solo contiene números
    
        if (!esNumerico.test(valor)) {
          return false;
        }
        return true;
    }

    //funcion para convertir en bytes a megas
    bytesToMegabytes(bytes) {
      const megabytes = bytes / 1000000; //mayor precision al dividir con: 1048576
      return megabytes;
    }

    permutaIdUsuario(id_usuario)
    {
      this.rol=sessionStorage.getItem("roles");
      console.log("Entrando al Gral Service Roles :"+ this.rol);

      if(this.rol==='["SUPER_ADMIN"]')
      {
          console.log("Antes de la permuta ID= "+ id_usuario);
        id_usuario="1";
        console.log("despues de la permuta ID= "+ id_usuario);
      }
      else{
        id_usuario= ""+id_usuario+"";
      }
      return(id_usuario);
    }
}

export default new GeneralService()