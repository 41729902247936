import { Component} from "react"
import { withRouter } from "react-router";
import UsuariosService from "services/UsuariosService"
import AdministrativoService from "services/AdministrativoService"
import sweetalert from "sweetalert2"
import ImgCargando from "../images/icono_cargando.svg"

import AguilaCaida from "../images/error.png"
import ClientesService from "services/ClientesService";
import FormClienteModal from "./FormClienteModal";
import PortabilidadesService from "services/PortabilidadesService";
import CambioMSISDNService from "services/CambioMSISDNService";

class PortabiliadModal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			usuario: {},
			bloquearBotones: false,
			imei_by: "msisdn",
			imeiOrICC: "",
			checkImeiResponse: {},
			nirs: [],
			nir: "",
		}
	
	}

	componentDidMount(){
		CambioMSISDNService.nirsAvailable().then(response =>{
			this.setState({nirs: response.data});
		}).catch(err =>{
			this.setState({nirs: []});
		})
	}


	realizarCambioMSISDN = (event) =>{
		event.preventDefault();
		this.setState({bloquearBotones: true});
		const nir = this.state?.nir?.split("-")[0];
		console.log({nir})
		CambioMSISDNService.cambioMSSIDN(this.state.checkImeiResponse?.subscriber?.msisdn, nir).then(async response =>{
			const respuesta = response.data;
			const effective_date_formated = respuesta?.effective_date.substring(0,10) + " " + respuesta?.effective_date.substring(11, 19);
			const data = {
				folio: respuesta?.folio,
				msisdn_anterior: respuesta?.old_msisdn,
				msisdn_nuevo: respuesta?.new_msisdn,
				effective_date: effective_date_formated,
				register_by: sessionStorage.getItem("id"),
				status: respuesta?.status == "finished" ? 1 : 0,
			}
			await CambioMSISDNService.InsertarCambioMSISDN(data).then(response1 =>{
				sweetalert.fire({
					icon: 'success',
					title: 'Exito',
					text: "Cambio realizado y registrado exitosamente",
				});
			}).catch(err =>{
				console.log("Error en realizarCambioMSISDN");
				sweetalert.fire({
					icon: 'Error',
					title: 'Error',
					text: "Cambio realizado exitosamente pero no registrado",
				});
			})
			this.setState({bloquearBotones: true});
			this.cerrarModal();
			this.props.getCambiosMSISDN();
		}).catch(err =>{
			console.log("Error en realizarCambioMSISDN", err);
			this.setState({bloquearBotones: true});
			this.cerrarModal();
			sweetalert.fire({
				icon: 'error',
				title: 'Error',
				text: "Error no se pudo realizar el cambio",
			});

		})
	}	

	cerrarModal = () =>{
		this.props.cerrarModal();
	}

	checkImei = (event) =>{
		event.preventDefault();
		this.setState({bloquearBotones: true});
		CambioMSISDNService.checkImei(this.state.imeiOrICC, this.state.imei_by).then(response =>{
			this.setState({checkImeiResponse: response.data, bloquearBotones: false});
		}).catch(err =>{
			this.setState({checkImeiResponse: {}, bloquearBotones: false});
			sweetalert.fire({
				icon: 'error',
				title: 'Error',
				text: "No se pudo encontrar el msisdn",
			});
		})
	}

	render() {
		return (
			<div className='fixed inset-6 z-50 flex flex-wrap justify-center items-center h-full w-full'>
            	<div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer w-full' onClick={() => this.cerrarModal()}> </div>
				<section className='z-50 flex flex-col justify-center items-center w-4/5'>

					<div className='bg-secondary_2 rounded-3xl shadow-sm2 p-5 bg-base text-white w-full'>
						<div className='text-center font-bold text-white text-3xl uppercase tracking-widest title-section'>
							"Cambio MSISDN"
						</div>
						{
							this.state.checkImeiResponse?.subscriber === undefined ? (
								<form className="" onSubmit={this.checkImei}>
									<span className='w-full'>
										<label htmlFor='nombre' className='text-12pt text-white'>
											Buscar por
										</label>
										<div className="flex gap-3">
											<div onClick={(e) => this.setState({imei_by: "msisdn"})} >
												<input type="radio" name="imei_by" checked={this.state.imei_by==="msisdn"} onChange={() => this.setState({imei_by: "msisdn"}) }/>
												<label for="html">MSISDN</label>
											</div>
											<div onClick={(e) => this.setState({imei_by: "iccid"})} >
												<input type="radio" name="imei_by" checked={this.state.imei_by==="iccid"} onChange={() => this.setState({imei_by: "iccid"})} />
												<label for="css">ICC</label>
											</div>
										</div>
										{/* {this.state.errors.nombre && this.state.errors.nombre !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nombre}</p> : <p></p> } */}
									</span>
									<span className='w-full'>
										<label htmlFor='nombre' className='text-12pt text-white'>
											Valor:
										</label>
										<input
											name='imeiOrICC'
											value={this.state.imeiOrICC}
											onChange={(event) => this.setState({imeiOrICC: event.target.value})}
											className='w-full text-12pt border-radius-7px text-black'
											required
											/>
											{/* {this.state.errors.nombre && this.state.errors.nombre !="" ? <p style={this.styleErrorCampos}>{this.state.errors.nombre}</p> : <p></p> } */}
									</span>
									{!this.state.bloquearBotones && (
										<div className='flex items-center justify-center  md:gap-5 gap-5 pt-5 pb-5'>
											<button type="submit" className="focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer">
												Continuar
											</button>
											<button type="button" onClick={() => this.cerrarModal()} className="focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer">
												Cancelar
											</button>
										</div>
									)}
									{this.state.bloquearBotones && (
										<div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5 font-bold mb-10 text-2xl'>
											<img className="w-10 h-10" src={ImgCargando} alt=">"/> Procesando ...
										</div>
									)}
								</form>
							): (
								<form className="" onSubmit={this.realizarCambioMSISDN}>
									<div>
										<p>Resumen</p>
										<div className='h-11/12 w-full pt-2 pb-2 border-2 border-black'>
											<div className='flex flex-row border-b-2 border-black'>
												<h4 className='w-1/6 text-center mt-1 p-2 font-bold text-lg'>MVNO</h4>
												<h4 className='w-1/6 text-center mt-1 p-2 font-bold text-lg'>MSISDN</h4>
												<h4 className='w-1/6 text-center mt-1 p-2 font-bold text-lg'>IMSI</h4>
												<h4 className='w-1/6 text-center mt-1 p-2 font-bold text-lg'>ICCID</h4>
												<h4 className='w-1/6 text-center mt-1 p-2 font-bold text-lg'>IMEI</h4>
												<h4 className='w-1/6 text-center mt-1 p-2 font-bold text-lg'>BE_ID</h4>
											</div>
											<div className='flex flex-row'>
												<h4 className="w-1/6 text-center mt-1 p-2 text-lg ">{this.state.checkImeiResponse?.mvno?.nombre}</h4>
												<h4 className="w-1/6 text-center mt-1 p-2 text-lg ">{this.state.checkImeiResponse?.subscriber?.msisdn}</h4>
												<h4 className="w-1/6 text-center mt-1 p-2 text-lg ">{this.state.checkImeiResponse?.subscriber?.imsi}</h4>
												<h4 className="w-1/6 text-center mt-1 p-2 text-lg ">{this.state.checkImeiResponse?.subscriber?.iccid}</h4>
												<h4 className="w-1/6 text-center mt-1 p-2 text-lg ">{this.state.checkImeiResponse?.subscriber?.imei}</h4>
												<h4 className="w-1/6 text-center mt-1 p-2 text-lg ">{this.state.checkImeiResponse?.mvno?.beId}</h4>
											</div>
										</div>

									</div>
									<span className='w-full'>
										<label htmlFor='nombre' className='text-12pt text-white'>
											NIR:
										</label>
										<select
											onChange={e => {
												this.setState({nir: e.target.value});
											}}
											value={this.state.nir}
											required
											name='nir'
											className='w-full cursor-pointer text-12pt border-radius-7px bg-white text-black'  defaultValue={0}>
												<option disabled selected value=""></option>
											{
												this.state.nirs.map((nir, index) => (
													<option
														value={`${nir.nir}-${nir.location}`}
														key={`${nir.nir} - ${nir.location} ${index}`}>
														{`${nir.nir} - ${nir.location}`}
													</option>
												))
											}
										</select>
									</span>
									{!this.state.bloquearBotones && (
										<div className='flex items-center justify-center  md:gap-5 gap-5 pt-5 pb-5'>
											<div onClick={() => this.setState({checkImeiResponse: {}, bloquearBotones: false})} className="focus:outline-none focus:ring-2 w-auto bg-gray-400 hover:bg-gray-700 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer">
												regresar
											</div>
											<button type="submit" className="focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer">
												Guardar
											</button>
											<button type="button" onClick={() => this.cerrarModal()} className="focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer">
												Cancelar
											</button>
										</div>
									)}
									{this.state.bloquearBotones && (
										<div className='flex items-center justify-center  md:gap-8 gap-4 pt-5 pb-5 font-bold mb-10 text-2xl'>
											<img className="w-10 h-10" src={ImgCargando} alt=">"/> Procesando ...
										</div>
									)}
								</form>
							)

						}
					</div>
				</section>
			</div>
		)
	}
}

export default withRouter(PortabiliadModal);