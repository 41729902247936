import axios from 'axios';
import { APIURL } from './Host';


class ClientesService{


    ListarClientes(){
        return axios.post(`${APIURL}/clientes`);
    }

    ClienteById(idCliente){
        return axios.post(`${APIURL}/cliente/${idCliente}`);
    }

    InsertarCliente(cliente){
        const data = {
            "usuario_id": cliente.usuario_id,
            "distribuidor": cliente.distribuidor,
            "email": cliente.email,
            "tipo_cliente": cliente.tipo_cliente,
            "status": cliente.status,
        }
        return axios.post(`${APIURL}/cliente/insert`, data);
    }

    ActualizarCliente(cliente){
        const data = {
            "usuario_id": cliente.usuario_id,
            "distribuidor": cliente.distribuidor,
            "email": cliente.email,
            "tipo_cliente": cliente.tipo_cliente,
            "lu_by": cliente.lu_by,
            "status": cliente.status,
            "id_cliente": cliente.id_cliente,
        }
        return axios.post(`${APIURL}/cliente/update`, data);
    }

    ActualizarStatusCliente(cliente){
        const data = {
            "status": cliente.status,
            "id_cliente": cliente.id_cliente
        }
        return axios.post(`${APIURL}/cliente/update/status`, data);
    }

	listClientes(id_admin) {
		console.log("ID recibido Service****"+id_admin);
		return axios.post(APIURL + "/clientes/list/"+id_admin);
	}

	updateCliente(data) {
		return axios.put(APIURL + "/clientes/update", data)
	}

	updateStatusClienteUsuario(data) {
		return axios.put(APIURL + "/clientes/update/status", data)
	}

	clienteById(id_admin){
		return axios.get(APIURL+"/clientesById/"+id_admin);
	}

    ListarTipoClientes(){
        return axios.post(`${APIURL}/tipo_clientes`);
    }

}

export default new ClientesService();