import React from 'react'
import InventarioService from '../services/InventarioService';
import sweetalert from "sweetalert2"
import ResguardosService from '../services/ResguardosService';
import SucursalesService from '../services/SucursalesService';
import InventarioModal from './InventarioModal';
import UsuariosModal from './usuariosModal';

class ResguardoDetalles extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      sucursales: [],
      usuarios: [],
      estatus: [
        {id_estatus: "2", nombre: "Recibido"}, 
        {id_estatus: "3", nombre: "Rechazado"}
      ],
      firmas: [
        {id_firma: "0", nombre: "No Firmado"}, 
        {id_firma: "1", nombre: "firmado"}
      ],
      modalInventario: false,
      usuario_aSelect: true,
      resguardo: {
        firma: 0,
        inventario_id: 0,
        inventario: {identificador: ''},
        usuario_a: {nombre: ''},
        usuario_r: {nombre: ''},
        status: 0,
        suc_id_destino: 0,
        suc_id_origen: 0,
        usuario_a_id: 0,
        usuario_r_id: 0,
      }
    }
  }

  componentDidMount(){
    this.getSucursales();
    if(this.props.resguardoSelect != 0) this.getResguardo(this.props.resguardoSelect); 
  }

  getResguardo = (idResguardo) =>{
    ResguardosService.ResguardoById(idResguardo).then(response =>{
        this.setState({resguardo: response.data})
        console.log(response.data)
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
        console.log("Error en getResguardo ", err);
    })
  }

  getSucursalNombre = (idSucursal) =>{
    let sucursal = this.state.sucursales.find(sucursal =>{
      return sucursal.id_sucursal === idSucursal;
    })

    if(sucursal) return sucursal.nombre;
    else return '';
  }

  getSucursales = () =>{
    SucursalesService.ListarSucursales().then(response =>{
        this.setState({sucursales: response.data});
        console.log(response.data)
    }).catch(err =>{
      sweetalert.fire({
        text: "Error al obtener los datos",
        allowOutsideClick: false,
        allowEscapeKey: false
      })
        console.log("Error en getSucursales ", err);
    })
  }

  actualizarResguardo = (event) =>{
    const usuario = sessionStorage.getItem("id");
    event.preventDefault();
    if(this.props.resguardoSelect == 0){
        const data = {
            ...this.state.resguardo, 
            register_by: usuario,
        };
        console.log(data)
        ResguardosService.InsertarResguardo(data).then(response =>{
          console.log("Insertado con exito, ", response);
          this.props.getResguardos();
          this.props.cerrarModal();
        }).catch(err =>{
          sweetalert.fire({
            text: "Error al actualizar los datos",
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          console.log("Error en actualizarResguardo, ", err);
        });
    }else{
        const data = {...this.state.resguardo, lu_by: usuario};
        console.log(data)
        ResguardosService.ActualizarResguardo(data).then(response =>{
          console.log("Actualizado con exito, ", response);
          this.props.getResguardos();
          this.props.cerrarModal();
        }).catch(err =>{
          sweetalert.fire({
            text: "Error al actualizar los datos",
            allowOutsideClick: false,
            allowEscapeKey: false
          })
          console.log("Error en actualizarResguardo, ", err);
        });
    }
    
    
  }

  onChangeInput = (event, input ) =>{
    this.setState({resguardo:{...this.state.resguardo, [input]: event.target.value}})
  }

  cerrarModal = () =>{
    this.props.cerrarModal();
  }

  cerrarModalInventario = () =>{
    this.setState({modalInventario: false})
  }
  cerrarModalUsuarios = () =>{
    this.setState({modalUsuarios: false})
  }

  abrirInventarioModal() {
    this.setState({modalInventario:true})
  }
  abrirUsuarioModal(usuario_aSelect) {
    this.setState({modalUsuarios:true, usuario_aSelect })
  }

  cambiarItemInventario = (inventario) =>{
    this.setState({resguardo: {...this.state.resguardo, inventario_id: inventario.id_inventario, inventario}})
  }
  cambiarUsuario = (usuario) =>{
    if(this.state.usuario_aSelect){
      this.setState({resguardo: {...this.state.resguardo, usuario_a_id: usuario.id_usuario, usuario_a: usuario}})
    }else{
      this.setState({resguardo: {...this.state.resguardo, usuario_r_id: usuario.id_usuario, usuario_r: usuario}})
    }
  }
  


  render(){
    return (
        <div className='fixed inset-0 z-50 flex flex-wrap justify-center'>
            <div className='fixed inset-0 bg-black opacity-50 backdrop-blur-md cursor-pointer' onClick={() => this.cerrarModal()}> </div>
            <form className='h-auto max-h-9/12 w-10/12 z-100 relative top-14 flex flex-wrap justify-evenly bg-secondary_2 rounded-3xl shadow-sm2 p-5 text-white overflow-auto'
                onSubmit={(event) => this.actualizarResguardo(event)}
            >

                <p className='text-xl text-center font-bold w-full'>{`${this.props.resguardoSelect == 0 ? "Nuevo resguardo" : "Detalles del resguardo"}`}</p>
                
                <div className='w-full mx-16 mt-2'>
                    <label>Item</label>
                    <input
                      onClick={() => this.abrirInventarioModal()}
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.resguardo.inventario.identificador}
                      required
                    />
                </div>
                <div className='w-5/12 mt-2'>
                    <label>Usuario que registra</label>
                    <input
                      onClick={() => this.abrirUsuarioModal(false)}
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.resguardo.usuario_r?.nombre}
                      required
                    />
                </div>
                
                <div className='w-5/12 mt-2'>
                    <label>Usuario al que se asigna</label>
                    <input
                      onClick={() => this.abrirUsuarioModal(true)}
                      className={`w-full p-1 text-12pt border-radius-7px text-black`} 
                      value={this.state.resguardo.usuario_a?.nombre}
                      required
                    />
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Sucursal de origen</label>
                    <select
                        className={`w-full p-1 text-12pt border-radius-7px text-black`}
                        value={this.state.resguardo.suc_id_origen}
                        onChange={ event => this.onChangeInput(event, 'suc_id_origen') }
                        required
                        >
                           <option >{"---"}</option>
                            {
                            this.state.sucursales.map(sucursal =>{
                                return <option key={sucursal.id_sucursal} value={sucursal.id_sucursal} >{this.getSucursalNombre(sucursal.id_sucursal)}</option>
                            })
                            
                            }

                    </select>
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Sucursal de destino</label>
                    <select
                        className={`w-full p-1 text-12pt border-radius-7px text-black`}
                        value={this.state.resguardo.suc_id_destino}
                        onChange={ event => this.onChangeInput(event, 'suc_id_destino') }
                        required
                        >
                          <option>{"---"}</option>
                            {
                            this.state.sucursales.map(sucursal =>{
                                return <option key={sucursal.id_sucursal} value={sucursal.id_sucursal} >{this.getSucursalNombre(sucursal.id_sucursal)}</option>
                            })
                            
                            }

                    </select>
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Estatus</label>
                    <select
                        className={`w-full p-1 text-12pt border-radius-7px text-black`}
                        value={this.state.resguardo.status}
                        onChange={ event => this.onChangeInput(event, 'status') }
                        required
                        >
                          <option>{"---"}</option>
                            {
                            this.state.estatus.map(estatus =>{
                              return <option key={estatus.id_estatus} value={estatus.id_estatus} >{estatus.nombre}</option>
                            })
                            
                            }

                    </select>
                </div>

                <div className='w-5/12 mt-2'>
                    <label>Firmado</label>
                    <select
                        className={`w-full p-1 text-12pt border-radius-7px text-black`}
                        value={this.state.resguardo.firma}
                        onChange={ event => this.onChangeInput(event, 'firma') }
                        required
                        >
                          <option>{"---"}</option>
                            {
                            this.state.firmas.map(firma =>{
                              return <option key={firma.id_firma} value={firma.id_firma} >{firma.nombre}</option>
                            })
                            
                            }

                    </select>
                </div>

                

                <div className='w-full flex gap-2 justify-center items-center mt-5'>
                <button className='focus:outline-none focus:ring-2 w-auto bg-green-800 hover:bg-green-500 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' type='submit'>Guardar</button>
                <button className='focus:outline-none focus:ring-2 w-auto bg-red-700 hover:bg-red-400 rounded-lg font-medium text-white px-4 py-2 transition duration-500 transform hover:scale-110 cursor-pointer' onClick={() => this.cerrarModal()}>Cancelar</button>
                </div>

                
            </form>
            <div className='opacity-100 h-fit w-full rounded-md z-100 relative top-20 p-8'></div>
            {
            this.state.modalInventario && (
              <InventarioModal cerrarModal={this.cerrarModalInventario} cambiarItemInventario={this.cambiarItemInventario} />
            )
            }
            {
            this.state.modalUsuarios && (
              <UsuariosModal cerrarModal={this.cerrarModalUsuarios} cambiarUsuario={this.cambiarUsuario} />
            )
            }
        </div>
    )
  }
  
}

export default ResguardoDetalles;
